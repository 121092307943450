import React from 'react';
import allActions from '../actions/allActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../reducers/combineReducer';
import { Toggle } from '../components/utils/Toggle';
import {
  VscAccount,
  VscSignOut,
  VscChevronDown,
  VscChevronUp,
  VscChevronLeft,
  VscChevronRight,
} from 'react-icons/vsc';
import { RiFilePaperLine, RiFileAddLine } from 'react-icons/ri';
import { BiCalendarPlus, BiCalendar } from 'react-icons/bi';
import { SiWebmoney } from 'react-icons/si';
import { FaMoneyBillWave } from 'react-icons/fa';
import * as img from '../img/images';
import { messages } from './navigationTranslations';
import * as uState from '../reducers/userReducer';
import { NavItem } from './NavItem';
import { NavItemWithButton } from './NavItemWithButton';
import { NavHeader } from './NavHeader';

export const Navbar: React.FC = () => {
  const iconNavClass = 'text-white hover:text-blue-300 m-1';
  const bottomOfNavigation = 'flex flex-col w-full mb-5';
  const eventToggle = 'EVENTS_TOGGLE';
  const leadToggle = 'LEAD_TOGGLE';
  const certToggle = 'CERT_TOGGLE';
  const invoiceToggle = 'INVOICE_TOGGLE';
  const navToggle = 'NAVBAR_TOGGLE';
  const toggleState = useSelector((state: AppState) => state.toggleState);
  const services = useSelector(uState.selectServices);
  const iconClassName =
    'p-2 m-auto color-brand-white bg-brand-blue rounded-full hover:bg-yellow-400 cursor-pointer shadow-md';
  const navWidth = toggleState[navToggle] ? 'w-1/6' : '';
  const dispatch = useDispatch();

  const toggleElement = (id: string) => {
    if (toggleState[id]) {
      dispatch(allActions.toggleActions.hideToggle(id));
    } else {
      dispatch(allActions.toggleActions.showToggle(id));
    }
  };

  const logOut = () => {
    sessionStorage.clear();
    dispatch(allActions.loginActions.logout());
  };

  return (
    <div className={`${navWidth} flex flex-row`}>
      <nav
        className={`p-2 transition transform bg-brand-dark_blue pr-6 pt-2 pl-3 h-screen flex flex-col shadow-r justify-between items-center`}
        style={{
          transition: 'all 120ms ease-out',
          width: toggleState[navToggle] ? '230px' : '60px',
        }}
      >
        <Toggle id={navToggle}>
          <div className="flex flex-col">
            <div className="w-24 self-center pt-8">
              <img
                className="shadow-asl"
                src={`${process.env.PUBLIC_URL}/${img.LOGOSQUARE}`}
                alt="logo"
              />
            </div>
            <NavItem
              link="/"
              message={messages.main}
              styleStr={
                'text-lg lg:mt-0 text-white hover:text-blue-300 mr-4 mt-4'
              }
              divStyle={'w-full pt-8'}
            />
            {services['events'] && (
              <>
                <NavHeader
                  onClick={() => toggleElement(eventToggle)}
                  message={messages.events}
                >
                  {toggleState[eventToggle] ? (
                    <VscChevronDown size={24} className={iconNavClass} />
                  ) : (
                    <VscChevronUp size={24} className={iconNavClass} />
                  )}
                </NavHeader>
                <Toggle id={eventToggle}>
                  <NavItem link="createevent" message={messages.createevent}>
                    <BiCalendarPlus size={20} className={iconNavClass} />
                  </NavItem>
                  <NavItem link="/events" message={messages.allevents}>
                    <BiCalendar size={20} className={iconNavClass} />
                  </NavItem>
                </Toggle>
              </>
            )}
            {services['leadCollection'] && (
              <>
                <NavHeader
                  onClick={() => toggleElement(leadToggle)}
                  message={messages.leadCollection}
                >
                  {toggleState[leadToggle] ? (
                    <VscChevronDown size={24} className={iconNavClass} />
                  ) : (
                    <VscChevronUp size={24} className={iconNavClass} />
                  )}
                </NavHeader>
                <Toggle id={leadToggle}>
                  <NavItem link="/feedback" message={messages.feedback} />
                  <NavItem link="/marketing" message={messages.marketing} />
                </Toggle>
              </>
            )}
            {services['certificates'] && (
              <>
                <NavHeader
                  onClick={() => toggleElement(certToggle)}
                  message={messages.certs}
                >
                  {toggleState[certToggle] ? (
                    <VscChevronDown size={24} className={iconNavClass} />
                  ) : (
                    <VscChevronUp size={24} className={iconNavClass} />
                  )}
                </NavHeader>
                <Toggle id={certToggle}>
                  <NavItem link="/createcert" message={messages.createcert}>
                    <RiFileAddLine size={20} className={iconNavClass} />
                  </NavItem>
                  <NavItem link="/certs" message={messages.allcerts}>
                    <RiFilePaperLine size={20} className={iconNavClass} />
                  </NavItem>
                </Toggle>
              </>
            )}
            <NavHeader
              onClick={() => toggleElement(invoiceToggle)}
              message={messages.invoicing}
            >
              {toggleState[invoiceToggle] ? (
                <VscChevronDown size={24} className={iconNavClass} />
              ) : (
                <VscChevronUp size={24} className={iconNavClass} />
              )}
            </NavHeader>
            <Toggle id={invoiceToggle}>
              <NavItem link="/createinvoice" message={messages.createinvoice}>
                <FaMoneyBillWave size={20} className={iconNavClass} />
              </NavItem>
              <NavItem link="/invoices" message={messages.allinvoices}>
                <SiWebmoney size={20} className={iconNavClass} />
              </NavItem>
            </Toggle>
          </div>
          <div className={bottomOfNavigation}>
            <NavItem link="/account" message={messages.account}>
              <VscAccount size={20} className={iconNavClass} />
            </NavItem>
            <NavItemWithButton onClick={logOut} message={messages.logout}>
              <VscSignOut size={20} className={iconNavClass} />
            </NavItemWithButton>
          </div>
        </Toggle>
        <div className="absolute -right-4 z-10 top-16">
          <button
            className={iconClassName}
            onClick={() => toggleElement(navToggle)}
          >
            {toggleState[navToggle] ? (
              <VscChevronLeft size={20} color={'white'} />
            ) : (
              <VscChevronRight size={20} color={'white'} />
            )}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
