import React from 'react';
import FormDateDate from '../../components/form/FormDateDate';
import FormInput from '../../components/form/FormInput';
import { InputDetails } from '../invoiceMappings';

type Props = {
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  handleDateChange: (date: Date, id: string) => void;
  inputs: { [key: string]: InputDetails };
  control: any;
  resetDate: (id: string) => void;
  values: { [key: string]: string | string[] };
  register: any;
};

export const InvoiceSingleSendInputs: React.FC<Props> = ({
  handleInputChange,
  handleDateChange,
  inputs,
  control,
  resetDate,
  values,
  register,
}) => {
  const keys = Object.keys(inputs);
  return (
    <>
      {keys.map((key, i) => {
        const input = inputs[key];
        const value = values[key];
        const last = i + 1 === keys.length;
        return inputs[key].type === 'Date' ? (
          <FormDateDate
            key={`${key}_key`}
            inputStyle={
              'flex h-10 bg-white border border-gray-300 mx-5 rounded-lg relative'
            }
            divStyle={`flex flex-col w-1/2 ${last ? 'pb-4' : ''}`}
            id={key}
            handleInputChange={handleDateChange}
            value={typeof value === 'string' ? new Date(value) : new Date()}
            control={control}
            reset={resetDate}
            question={input.name}
            register={
              input.required
                ? {
                    validate: () => {
                      return value !== null || `any`;
                    },
                  }
                : null
            }
          />
        ) : (
          <FormInput
            key={`${key}_key`}
            divStyle={`flex flex-col w-1/2 ${last ? 'pb-4' : ''}`}
            type="text"
            value={typeof value === 'string' ? value : ''}
            id={key}
            handleInputChange={(e) => handleInputChange(e, key)}
            question={input.name}
            register={input.required ? register({ required: `any` }) : null}
          />
        );
      })}
    </>
  );
};
