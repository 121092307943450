export const csvReaderStyles = {
  dropArea: {
    borderColor: '#009ece',
    borderRadius: 20,
  },
  dropAreaActive: {
    borderColor: '#009ece',
  },
  dropFile: {
    width: 80,
    height: 80,
    background: '#ccc',
  },
  fileSizeInfo: {
    color: '#00255f',
    backgroundColor: '#eee',
    borderRadius: 3,
    lineHeight: 1,
    marginBottom: '0.5em',
    padding: '0 0',
  },
  fileNameInfo: {
    color: '#00255f',
    backgroundColor: '#eee',
    borderRadius: 3,
    fontSize: 14,
    lineHeight: 1,
    padding: '0 0.4em',
  },
  progressBar: {
    backgroundColor: '#009ece',
  },
};
