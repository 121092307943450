import React from "react";
import { useDispatch } from "react-redux";
import allActions from "../actions/allActions";
import { Footer } from "../components/utils/Footer";
import { LoginForm } from "./LoginForm";

export const LoginPage: React.FC = () => {
  const formName = "loginForm";
  const dispatch = useDispatch();

  const logIn = async (data: { username: string; password: string }) => {
    dispatch(allActions.loginActions.startLogin(data));
  };

  return (
    <div className='flex flex-col h-screen justify-between'>
      <div className='container mx-auto flex h-full justify-center items-center mb-auto'>
        <LoginForm formName={formName} formAction={logIn} />
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
