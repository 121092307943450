import React from 'react';
import { InvoiceMultisendInputs } from './InvoiceMultisendInputs';
import { InvoiceSingleSendInputs } from './InvoiceSingleSendInputs';
import { InputDetails } from '../invoiceMappings';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../reducers/combineReducer';
import { selectFormInputValues } from '../../reducers/formReducer';
import allActions from '../../actions/allActions';

type Props = {
  multiSend: boolean;
  mapping: { [key: string]: InputDetails };
};

export const InvoiceMappingForm: React.FC<Props> = ({ multiSend, mapping }) => {
  const { control, register } = useForm();
  const dispatch = useDispatch();

  const values = useSelector((state: AppState) =>
    selectFormInputValues(state, 'newInvoice')
  );

  const handleSelect = (
    e: React.ChangeEvent<HTMLSelectElement>,
    id: string
  ) => {
    dispatch(
      allActions.formActions.updateValue(id, e.target.value, 'newInvoice')
    );
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    dispatch(
      allActions.formActions.updateValue(id, e.target.value, 'newInvoice')
    );
  };

  const resetDate = (id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, null, 'newInvoice'));
  };

  const handleDateChange = (date: Date, id: string) => {
    dispatch(allActions.formActions.updateDateValue(id, date, 'newInvoice'));
  };

  return (
    <div className="flex flex-wrap w-full h-full p-2 pt-8">
      {multiSend ? (
        <InvoiceMultisendInputs
          values={values}
          inputs={mapping}
          handleInputChange={handleSelect}
          handleDateChange={handleDateChange}
          resetDate={resetDate}
          control={control}
          register={register}
        />
      ) : (
        <InvoiceSingleSendInputs
          values={values}
          inputs={mapping}
          handleInputChange={handleInput}
          handleDateChange={handleDateChange}
          resetDate={resetDate}
          control={control}
          register={register}
        />
      )}
    </div>
  );
};
