import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import allActions from '../actions/allActions';

export function useNotify(msg: string | null) {
  const dispatch = useDispatch();
  const [message, setErrorMsg] = useState(msg);

  const notify = (message: string | null) => {
    setErrorMsg(message);
    const timerId = setTimeout(() => {
      setErrorMsg(null);
      dispatch(allActions.errorActions.clearError());
    }, 2500);
    return () => {
      clearTimeout(timerId);
    };
  };

  useEffect(() => {
    if (msg) {
      notify(msg);
    }
  }, [msg]); //eslint-disable-line

  return message;
}
