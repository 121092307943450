import { FormInputInfo } from '../types/types';

const updateValue = (id: string, value: string, form: string) => {
  return {
    type: 'UPDATEVALUE',
    payload: { id, value, form },
  };
};

const updateDateValue = (id: string, value: Date, form: string) => {
  return {
    type: 'UPDATEDATEVALUE',
    payload: { id, value, form },
  };
};

const addToValue = (
  id: string,
  currArray: string[],
  value: string,
  form: string
) => {
  const result =
    currArray.length === 0
      ? [value]
      : currArray.includes(value)
      ? currArray.filter((val) => val !== value)
      : currArray.concat(value);
  return {
    type: 'ADDTOVALUE',
    payload: { id, value: result, form },
  };
};

const startFormFetch = (form: string, language: string) => {
  return {
    type: 'START_FORM_FETCH',
    payload: { form, language },
  };
};

const removeKey = (id: string, form: string) => {
  return {
    type: 'REMOVEKEY',
    payload: { form, id },
  };
};

const setForm = (id: string, form: FormInputInfo[]) => {
  return {
    type: 'SET_FORM',
    payload: { id, form },
  };
};

const fileUploadToForm = (
  formName: string,
  blob: Blob,
  toggleId: string,
  questionId: string
) => {
  return {
    type: 'START_FILE_FORM_UPLOAD',
    payload: { formName, blob, toggleId, questionId },
  };
};

const setCreateEvent = () => {
  return { type: 'SET_CREATE_EVENT_DATA', payload: undefined };
};

const formActions = {
  updateValue,
  setCreateEvent,
  addToValue,
  updateDateValue,
  startFormFetch,
  removeKey,
  setForm,
  fileUploadToForm,
};

export default formActions;
