import React, {useEffect, useRef, useState} from 'react';
import {FormInput} from './FormInput';
import {FormSelect} from './FormSelect';
import {FormTextArea} from './FormTextArea';
import {FormInputInfo, ButtonInfo, RequiredType} from '../../types/types';
import allActions from '../../actions/allActions';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {FormRadio} from './FormRadio';
import FormCheckBox from './FormCheckBox';
import {FcNext, FcPrevious} from 'react-icons/fc';
import {DeepMap, FieldError, useForm} from 'react-hook-form';
import ConsoleHelper from '../../utils/logger';
import {selectFormItemsAndValues} from '../../reducers/formReducer';
import {AppState} from '../../reducers/combineReducer';

interface FormProps {
  formAction?: (e: any) => void;
  title?: string;
  formStyle: string;
  buttonInfo: ButtonInfo;
  message?: string;
  formName: string;
}

export const FormContainer: React.FC<FormProps> = ({
  title,
  formStyle,
  buttonInfo,
  formAction,
  message,
  formName,
}) => {
  const {register, handleSubmit, trigger, errors, unregister} = useForm();
  const dispatch = useDispatch();
  const {items, values, repeatableLength, pages} = useSelector((state: AppState) =>
    selectFormItemsAndValues(state, formName)
  );
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [registered, setRegistered] = useState([]);
  const pageList = items.filter((item) => item.page === currentPage);
  let prevGroup = pageList.length > 0 ? pageList[0].group : 0;
  const buttonColors = !errors
    ? buttonInfo.style
    : 'bg-gray-500 text-brand-white cursor-default';
  const formRef = useRef(null);
  const Summary = getProductSummary(items, values);
  const MessageDiv =
    message !== '' ? (
      <div className="text-red-400 h-2 pb-4">{message}</div>
    ) : (
        <div className="invisible h-2 pb-2">{message}</div>
      );
  const TitleDiv = title ? (
    <div className="text-center m-4 uppercase font-semibold text-lg">
      <b className="text-brand-dark_blue">{title}</b>
    </div>
  ) : null;
  const lastPage = pages[pages.length - 1];
  const submitHidden =
    currentPage === lastPage
      ? 'visible'
      : 'invisible focus:outline-none outline-none';
  const continueHidden =
    currentPage === lastPage
      ? 'invisible focus:outline-none outline-none'
      : 'visible';
  const previousHidden =
    currentPage === pages[0]
      ? 'invisible focus:outline-none outline-none'
      : 'visible';
  const iconStyles = 'cursor-pointer';
  const SummaryDiv =
    Summary !== null ? (
      currentPage === lastPage ? (
        <div className="text-center m-4 uppercase font-semibold text-lg">
          <span className="text-brand-blue m-2">
            <FormattedMessage id="form.summary" />:
          </span>{' '}
          {Summary}
        </div>
      ) : null
    ) : null;

  //TODO: 1. Pitää olla myös erilainen ehto toistoryhmille. Lisää ja vähennä nappi halutaan myös ns. ensimmäisten toistoryhmien alle.
  //Jos painaa Add, niin täytyy muuttaa myös Key valuen mukaiseksi!
  //TODO: 2. Jos viimeinen toistoryhmän kysymys on piilotettu, niin 'lisää' j poista nappula ovat piilossa.

  function getProductSummary(
    questions: FormInputInfo[],
    formState: {[key: string]: string | string[]}
  ) {
    const productItem = questions.find((item) => item.product);
    if (productItem === undefined) {
      return null;
    }
    if (productItem.product.multiplier === 'Self') {
      const summaryStr = questions
        .filter((item) => item.product)
        .reduce((acc, curr) => {
          const productVal = formState[curr.id];
          const productStr = typeof productVal === 'string' ? productVal : '';
          const tax = curr.product.tax ? getTax(curr.product.tax) : '1';
          const productStrArr = productStr.replace(',', '.').split(' ');
          return (
            acc + Number(productStrArr[productStrArr.length - 1]) * Number(tax)
          );
        }, 0);
      return (
        <div>
          <span className="text-brand-dark_blue">{summaryStr}€</span>
          <br></br>
          <span className="italic text-brand-dark_blue normal-case">
            Sis. Alv. {productItem.product.tax}%
          </span>
        </div>
      );
    } else {
      const productVal = formState[productItem.id];
      const productStr = typeof productVal === 'string' ? productVal : '';
      const multiplierVal = formState[productItem.product.multiplier];
      const multiplier = typeof multiplierVal == 'string' ? multiplierVal : 1;
      const tax = productItem.product.tax
        ? getTax(productItem.product.tax)
        : '1';
      const productStrArr = productStr.replace(',', '.').split(' ');
      const summaryStr = (
        Number(multiplier) *
        Number(productStrArr[productStrArr.length - 1]) *
        Number(tax)
      ).toString();
      return (
        <div>
          <span className="text-brand-dark_blue">{summaryStr}€</span>
          <br></br>
          <span className="italic text-brand-dark_blue normal-case">
            Sis. Alv. {productItem.product.tax}%
          </span>
        </div>
      );
    }
  }

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    type?: string
  ) => {
    if (type === 'array') {
      const currentValue: string[] | string = values[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === 'string'
            ? [currentValue]
            : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  function getTax(tax: string) {
    if (Number(tax) > 0) {
      return (Number(tax) / 100 + 1).toString().replace(',', '.');
    }
    if (Number(tax) === 0) {
      return '1';
    }
    return tax.replace(',', '.');
  }

  function returnRequiredRule(
    obj: {type: RequiredType; key?: string; value?: string},
    register: any
  ) {
    if (obj.key && obj.value) {
      if (values[obj.key] !== obj.value) {
        return register();
      }
    }
    if (obj.type === 'email') {
      return register({
        required: `any`,
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //eslint-disable-line
          message: `email`,
        },
      });
    } else if (obj.type === 'phone') {
      return register({
        required: `any`,
        pattern: {
          value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, //eslint-disable-line
          message: `phone`,
        },
      });
    } else if (obj.type === 'any') {
      return register({required: `any`});
    } else {
      return register();
    }
  }

  const chooseInputElement = (
    obj: FormInputInfo,
    register: any,
    errors: any,
    handlerFunction: (event: any, id: string, type?: string) => void,
    index: number,
    divider: boolean,
    changeAmount: (key: string, direction: number) => void
  ) => {
    const border = divider ? 'border-t-2 border-fuchsia-600 pt-8' : '';
    const val = values[obj.id];
    const value = typeof val === 'string' ? val : '';
    const valArr = Array.isArray(val) ? val : [];
    const repeatable = obj.repeatable ? true : false;
    const repeatableHeader = obj.repeatable ? obj.repeatable.header : null;
    const repeatableLast = obj.repeatable
      ? obj.repeatable.last
        ? {
          key: obj.repeatable.amount,
          last: true,
          amount: Number(values[obj.repeatable.amount]) as number,
        }
        : {
          key: obj.repeatable.amount,
          last: false,
          amount: Number(values[obj.repeatable.amount]) as number,
        }
      : {key: '', last: false, amount: 1};
    const link = obj.link ? obj.link : false;
    const reg = obj.required
      ? returnRequiredRule(obj.required, register)
      : null;
    if (obj.type === 'dropdown') {
      return (
        <FormSelect
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === 'textarea') {
      return (
        <FormTextArea
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === 'radio' || obj.type === 'product') {
      return (
        <FormRadio
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={value}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === 'checkbox') {
      return (
        <FormCheckBox
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else if (obj.type === 'accept') {
      return (
        <FormCheckBox
          divStyle="flex flex-row-reverse justify-end items-center text-xs xl:w-11/12 xl:m-auto xl:text-base"
          question={obj.question}
          key={obj.id}
          id={obj.id}
          options={obj.options}
          handleInputChange={handlerFunction}
          divider={border}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          checked={valArr}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    } else {
      return (
        <FormInput
          question={obj.question}
          key={obj.id}
          id={obj.id}
          value={value}
          type={obj.type}
          handleInputChange={handlerFunction}
          divider={border}
          repeatable={repeatable}
          register={reg}
          errors={errors}
          link={link}
          repeatableHeader={repeatableHeader}
          repeatableKey={repeatableLast}
          cursiveLabel={obj.description || null}
          changeAmount={changeAmount}
          validate={obj.required ? validate : null}
          index={index}
        />
      );
    }
  };

  const sortByGroup = (arr: FormInputInfo[]) => {
    return arr.sort((a, b) => a.group - b.group);
  };

  const setPage = async (
    e: React.FormEvent,
    page: number,
    direction: number
  ) => {
    e.preventDefault();
    const valid = page + direction < currentPage ? true : await trigger();
    if (valid) {
      setCurrentPage(page + direction);
    }
    ConsoleHelper('non valid');
  };

  const changeAmount = (key: string, direction: number) => {
    const currentValue = values[key];
    const newAmount =
      typeof currentValue === 'string' ? Number(currentValue) + direction : 0;
    dispatch(
      allActions.formActions.updateValue(key, newAmount.toString(), formName)
    );
  };

  function unRegisterFiltered(
    current: FormInputInfo[],
    previous: FormInputInfo[],
    formName: string
  ) {
    if (JSON.stringify(previous) !== JSON.stringify(current)) {
      const previousOnlyIds = previous.map((p) => p.id);
      const currentOnlyIds = current.map((c) => c.id);
      const idsToUnregister = previousOnlyIds.filter(
        (item) => !currentOnlyIds.includes(item)
      );
      for (const id of idsToUnregister) {
        unregister(id);
        dispatch(allActions.formActions.removeKey(id, formName));
      }
    }
    setRegistered(items);
  }

  function validate(id: string) {
    trigger(id);
  }

  function focusOnErrors(errors: DeepMap<Record<string, any>, FieldError>) {
    for (const key of Object.keys(errors)) {
      return errors[key].ref.focus();
    }
  }

  function focusToTop() {
    formRef.current.scrollIntoView();
  }

  useEffect(() => {
    unRegisterFiltered(items, registered, formName);
  }, [repeatableLength]); //eslint-disable-line

  useEffect(() => {
    focusOnErrors(errors);
  }, [errors]);

  useEffect(() => {
    focusToTop();
  }, [currentPage]);

  return (
    <form
      className={`${formStyle}`}
      onSubmit={handleSubmit(formAction)}
      ref={formRef}
    >
      {TitleDiv}

      {sortByGroup(pageList).map((inputObject, index) => {
        const divider = Number(inputObject.group) !== Number(prevGroup);
        prevGroup = inputObject.group;
        return chooseInputElement(
          inputObject,
          register,
          errors,
          handleInputChange,
          index,
          divider,
          changeAmount
        );
      })}

      {MessageDiv}
      {SummaryDiv}

      <div className="flex flex-row justify-between">
        <button
          className={`flex items-center ${previousHidden} m-2 cursor-pointer`}
          onClick={(e) => setPage(e, currentPage, -1)}
        >
          <FcPrevious className={`${iconStyles}`} size={20} color={`#009ece`} />
          <span className="text-brand-blue">
            <FormattedMessage
              id="form.previouspage.button"
              defaultMessage="Previous"
            />
          </span>
        </button>
        <button
          className={`${buttonColors} py-2 px-4 lg:m-5 m-2 w-auto rounded ${submitHidden}`}
          type="submit"
        >
          <FormattedMessage id={buttonInfo.format_id} defaultMessage="Submit" />
        </button>
        <button
          className={`flex items-center ${continueHidden} m-2 cursor-pointer`}
          onClick={(e) => setPage(e, currentPage, 1)}
        >
          <span className="text-brand-blue">
            <FormattedMessage
              id="form.nextpage.button"
              defaultMessage="Continue"
            />
          </span>
          <FcNext className={`${iconStyles}`} size={20} color={`#009ece`} />
        </button>
      </div>
    </form>
  );
};

export default FormContainer;
