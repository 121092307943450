import {IntlMessageID} from "../..";

export const translations: {[key: string]: IntlMessageID} = {
  mailid: 'certdetails.headers.mailid',
  mailstate: 'certdetails.headers.mailstate',
  mailoperationresult: 'certdetails.headers.mailoperationresult',
  mailto: 'certdetails.headers.mailto',
  mailfrom: 'certdetails.headers.mailfrom',
  mailreplyto: 'certdetails.headers.mailreplyto',
  mailbcc: 'certdetails.headers.mailbcc',
  mailtracklinks: 'certdetails.headers.mailtracklinks',
  mailtrackopens: 'certdetails.headers.mailtrackopens',
  mailattachmenttype: 'certdetails.headers.mailattachmenttype',
};
