import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../../types/types';

interface Props {
  onClick: () => void;
  message: FormattedMessageProps;
  danger?: boolean;
}

export const HeaderItem: React.FC<Props> = ({
  onClick,
  message,
  danger,
  children,
}) => {
  const color = danger
    ? 'bg-red-400 hover:bg-red-500 text-brand-white'
    : 'bg-brand-white hover:bg-blue-200 text-brand-blue';

  return (
    <button
      className={`flex items-center justify-center m-4 rounded w-40 h-10 ${color} shadow-lg`}
      onClick={onClick}
    >
      {children}
      <span>
        <FormattedMessage {...message} />
      </span>
    </button>
  );
};
