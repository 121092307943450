import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../translations';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';

interface InvoiceDefinitorValues {
  row: { [key: string]: { [key: string]: string } };
  groupid: string | null;
}

export type InvoiceValues = InvoiceDefinitorValues & { [key: string]: string };

type Props = {
  amountOfItems: number;
  currentItem: number;
  data: InvoiceValues;
  changeItem: (step: number) => void;
  container: string;
};

export const InvoicePreviewCard: React.FC<Props> = ({
  data,
  changeItem,
  amountOfItems,
  currentItem,
  container,
}) => {
  const keyStyle = 'text-xl font-semibold text-brand-dark_blue m-2';
  const valueStyle = 'text-xl text-brand-dark_blue m-2';
  const bottomButtonStyle =
    'flex items-center text-brand-dark_blue hover:text-blue-900';
  const showPrev = currentItem > 0 ? bottomButtonStyle : 'invisible';
  const showNext =
    currentItem < amountOfItems - 1 ? bottomButtonStyle : 'invisible';
  const commonKeys = Object.keys(data).filter(
    (k) => k !== 'row' && k !== 'groupid'
  );
  const header = 'text-3xl text-brand-dark_blue';
  const message = 'text-xl text-brand-dark_blue';
  return (
    <div className={container}>
      <div className="flex flex-col divide-y">
        <div>
          <span className={header}>
            <FormattedMessage {...messages.prevheader} />
          </span>
        </div>
        <div>
          <span className={message}>
            <FormattedMessage
              {...messages.prevdescription}
              values={{ amount: amountOfItems }}
            />
          </span>
        </div>
      </div>
      <div>
        {commonKeys.map((k, i) => (
          <div key={`invoiceitemkey_${i}_${k}`} className="truncate">
            <span className={keyStyle}>{k}</span>
            <span className={valueStyle}>{data[k]}</span>
          </div>
        ))}
        {Object.keys(data.row).map((row) => (
          <div key={`invoiceitemrow_${row}`} className="truncate">
            <div className="pt-4 divide-y">
              <span className="text-2xl text-brand-dark_blue">
                <FormattedMessage {...messages.prevrow} values={{ row: row }} />
              </span>
              <div>
                {Object.keys(data.row[row]).map((kv) => (
                  <div key={`invoiceitemrowvalue_${kv}`}>
                    <span className={keyStyle}>{kv}</span>
                    <span className={valueStyle}>{data.row[row][kv]}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between pt-4">
        <button className={showPrev} onClick={() => changeItem(-1)}>
          <VscChevronLeft />
          <FormattedMessage {...messages.prevprev} />
        </button>
        <span className={bottomButtonStyle}>
          {currentItem + 1} / {amountOfItems}
        </span>
        <button className={showNext} onClick={() => changeItem(1)}>
          <FormattedMessage {...messages.prevnext} />
          <VscChevronRight />
        </button>
      </div>
    </div>
  );
};
