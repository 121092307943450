import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import FormSelect from '../../../../components/form/FormSelect';
import { Button } from '../../../../components/utils/Button';
import { DefinedMessages } from '../../../../types/types';
import { extractId } from '../../../../utils/functions';
import { NewParticipantModal } from '../../views/EventParticipants';

export const SelectSubEventModal: React.FC<NewParticipantModal> = ({
  modalOpen,
  modalAction,
  options,
}) => {
  const [subEvent, selectSubEvent] = useState('');
  const { formatMessage } = useIntl();

  const messages: DefinedMessages = {
    button: {
      id: 'event.participants.table.selectsubevent.button',
      defaultMessage: 'Select',
    },
    cancelbutton: {
      id: 'event.participants.table.cancel.button',
      defaultMessage: 'Cancel',
    },
    selectsubevent: {
      id: 'event.participants.table.selectsubevent',
      defaultMessage: 'Select subevent',
    },
    selectdescription: {
      id: 'event.participants.table.selectsubevent.description',
      defaultMessage: 'Select where the participant will participate',
    },
  };

  const changeSubEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    selectSubEvent(e.target.value);
  };

  const onOk = () => {
    const id = extractId(subEvent);
    const selectedSubEvent = options.find((s) => s.subEventId === id);
    if (id !== '') {
      modalOpen();
      modalAction(selectedSubEvent);
    }
  };

  return (
    <div className="absolute z-10 bg-brand-white w-1/2">
      <FormSelect
        id="selectSubEvent"
        options={[''].concat(options.map((o) => o.subEventName))}
        question={formatMessage({ ...messages.selectsubevent })}
        cursiveLabel={formatMessage({ ...messages.selectdescription })}
        value={subEvent}
        handleInputChange={changeSubEvent}
      />
      <Button
        id="SelectSubEventModalOkButton"
        onOk={onOk}
        msg={{ ...messages.button }}
      />
      <Button
        id="SelectSubEventModalCancelButton"
        onOk={modalOpen}
        msg={{ ...messages.cancelbutton }}
      />
    </div>
  );
};
