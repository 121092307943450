import { DefaultFormResponse, RequestHeader } from '../types/types';
import ConsoleHelper from '../utils/logger';
import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

const getForm = async (obj: {
  form: string;
  language: string | null;
}): Promise<{ data: any; status: number }> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const lang = obj.language === null ? 'fi' : obj.language;
  const url = `${config.baseUrl}/form/get/${obj.form}/${lang}`;
  const requestObj = {
    form: obj.form,
  };
  return await axiosInstance({ url, data: requestObj, headers });
};

const getDefaultForm = async (): Promise<{
  data: DefaultFormResponse;
  status: number;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/form/registration`;
  return await axiosInstance({ url, headers });
};

//TODO: nämä pitää muuttaa formReduceriin!
const saveForm = async (data: any, headers?: any) => {
  const url = `${config.baseUrl}/form/save`;
  try {
    const response = await axiosInstance({
      method: 'POST',
      data,
      url,
      headers,
    });
    return response.status === 200
      ? { data: response.data, success: true }
      : { success: false, status: response.status };
  } catch (error) {
    ConsoleHelper(`error ${error}`);
    return { success: false, message: error };
  }
};

const formService = {
  getForm,
  getDefaultForm,
  saveForm,
};

export default formService;
