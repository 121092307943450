import { DefinedMessages } from '../../../types/types';

export const messages: DefinedMessages = {
  count: {
    id: 'registration.subevent.validation.errorcount',
    defaultMessage: 'Number of errors',
  },
  group: {
    id: 'registration.subevent.validation.group.header',
    defaultMessage: 'Group registration errors',
  },
  info: {
    id: 'registration.subevent.validation.missinginformation.header',
    defaultMessage: 'Missing information',
  },
  reservation: {
    id: 'registration.subevent.validation.missing.reservation.header',
    defaultMessage: 'Missing numeric reservation question',
  },
  database: {
    id: 'registration.subevent.validation.missing.databasefield.header',
    defaultMessage: 'Missing required database-field',
  },
  email: {
    id: 'registration.subevent.validation.missing.email.header',
    defaultMessage: 'Missing required email-fields',
  },
  hide: {
    id: 'registration.subevent.validation.hide.header',
    defaultMessage: 'Hide-questions validation',
  },
  repeatable: {
    id: 'registration.subevent.validation.repeat.header',
    defaultMessage: 'Repeatable-questions validation',
  },
  validationdatabase: {
    id: 'registration.subevent.validation.databasefield.header',
    defaultMessage: 'Databasefield-questions validation',
  },
  mailkey: {
    id: 'registration.subevent.validation.mailkeysfield.header',
    defaultMessage: 'Mailkey-questions validation',
  },
};
