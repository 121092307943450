import { DefinedMessages } from '../../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'registration.subevent.question.modal.header',
    defaultMessage: 'Edit question',
  },
  question: {
    id: 'registration.subevent.question.modal.question',
    defaultMessage: 'Question',
  },
  questionhelp: {
    id: 'registration.subevent.question.modal.question.help.header',
    defaultMessage: 'Question',
  },
  questionhelpparagraph: {
    id: 'registration.subevent.question.modal.question.help.paragraph',
    defaultMessage: 'Question Help',
  },
  id: {
    id: 'registration.subevent.question.modal.id',
    defaultMessage: 'Id',
  },
  idhelp: {
    id: 'registration.subevent.question.modal.id.help.header',
    defaultMessage: 'Id',
  },
  idhelpparagraph: {
    id: 'registration.subevent.question.modal.id.help.paragraph',
    defaultMessage: 'Id Help',
  },
  description: {
    id: 'registration.subevent.question.modal.description',
    defaultMessage: 'Description',
  },
  descriptionhelp: {
    id: 'registration.subevent.question.modal.description.help.header',
    defaultMessage: 'Description',
  },
  descriptionhelpparagraph: {
    id: 'registration.subevent.question.modal.description.help.paragraph',
    defaultMessage: 'Description Help',
  },
  type: {
    id: 'registration.subevent.question.modal.type',
    defaultMessage: 'Type',
  },
  typehelp: {
    id: 'registration.subevent.question.modal.type.help.header',
    defaultMessage: 'Type',
  },
  typehelpparagraph: {
    id: 'registration.subevent.question.modal.type.help.paragraph',
    defaultMessage: 'Type Help',
  },
  group: {
    id: 'registration.subevent.question.modal.group',
    defaultMessage: 'Group',
  },
  grouphelp: {
    id: 'registration.subevent.question.modal.group.help.header',
    defaultMessage: 'Group',
  },
  grouphelpparagraph: {
    id: 'registration.subevent.question.modal.group.help.paragraph',
    defaultMessage: 'Group Help',
  },
  required: {
    id: 'registration.subevent.question.modal.required',
    defaultMessage: 'Required',
  },
  requiredhelp: {
    id: 'registration.subevent.question.modal.required.help.header',
    defaultMessage: 'Required',
  },
  requiredhelpparagraph: {
    id: 'registration.subevent.question.modal.required.help.paragraph',
    defaultMessage: 'Required Help',
  },
  dbkeys: {
    id: 'registration.subevent.question.modal.databasekey',
    defaultMessage: 'Database-key',
  },
  dbkeyshelp: {
    id: 'registration.subevent.question.modal.databasekey.help.header',
    defaultMessage: 'Database-key',
  },
  dbkeyshelpparagraph: {
    id: 'registration.subevent.question.modal.databasekey.help.paragraph',
    defaultMessage: 'Database-key Help',
  },
  mailkeys: {
    id: 'registration.subevent.question.modal.emailkey',
    defaultMessage: 'Email-key',
  },
  mailkeyshelp: {
    id: 'registration.subevent.question.modal.emailkey.help.header',
    defaultMessage: 'Email-key',
  },
  mailkeyshelpparagraph: {
    id: 'registration.subevent.question.modal.emailkey.help.paragraph',
    defaultMessage: 'Email-key Help',
  },
  link: {
    id: 'registration.subevent.question.modal.link',
    defaultMessage: 'Link',
  },
  linkhelp: {
    id: 'registration.subevent.question.modal.link.help.header',
    defaultMessage: 'Link',
  },
  linkhelpparagraph: {
    id: 'registration.subevent.question.modal.link.help.paragraph',
    defaultMessage: 'Link Help',
  },
  person: {
    id: 'registration.subevent.question.modal.person',
    defaultMessage: 'Person',
  },
  personhelp: {
    id: 'registration.subevent.question.modal.person.help.header',
    defaultMessage: 'Person',
  },
  personhelpparagraph: {
    id: 'registration.subevent.question.modal.person.help.paragraph',
    defaultMessage: 'Person Help',
  },
  reservation: {
    id: 'registration.subevent.question.modal.reservation',
    defaultMessage: 'Reservation',
  },
  reservationhelp: {
    id: 'registration.subevent.question.modal.reservation.help.header',
    defaultMessage: 'Reservation',
  },
  reservationhelpparagraph: {
    id: 'registration.subevent.question.modal.reservation.help.paragraph',
    defaultMessage: 'Reservation Help',
  },
  option: {
    id: 'registration.subevent.question.modal.option',
    defaultMessage: 'Option',
  },
  optionhelp: {
    id: 'registration.subevent.question.modal.option.help.header',
    defaultMessage: 'Option',
  },
  optionhelpparagraph: {
    id: 'registration.subevent.question.modal.option.help.paragraph',
    defaultMessage: 'Option Help',
  },
  repeatablehelp: {
    id: 'registration.subevent.question.modal.repeatable.help.header',
    defaultMessage: 'Repeat',
  },
  repeatablehelpparagraph: {
    id: 'registration.subevent.question.modal.repeatable.help.paragraph',
    defaultMessage: 'Repeat Help',
  },
  producthelp: {
    id: 'registration.subevent.question.modal.product.help.header',
    defaultMessage: 'Product',
  },
  producthelpparagraph: {
    id: 'registration.subevent.question.modal.product.help.paragraph',
    defaultMessage: 'Product Help',
  },
  hiddenhelp: {
    id: 'registration.subevent.question.modal.hidden.help.header',
    defaultMessage: 'Hidden',
  },
  hiddenhelpparagraph: {
    id: 'registration.subevent.question.modal.hidden.help.paragraph',
    defaultMessage: 'Hidden Help',
  },
  deletehelp: {
    id: 'registration.subevent.question.modal.delete.help.header',
    defaultMessage: 'Delete',
  },
  deletehelpparagraph: {
    id: 'registration.subevent.question.modal.delete.help.paragraph',
    defaultMessage: 'Delete Help',
  },
  save: {
    id: 'registration.subevent.question.modal.save.button',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'registration.subevent.question.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
  formatproduct: {
    id: 'form.product.format',
    defaultMessage: 'Format product correctly',
  },
};
