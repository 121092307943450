import React from "react";
import { useSelector } from "react-redux";
import * as tState from "../../reducers/toggleReducer";

interface ToggleProps {
  id: string;
}

export const Toggle: React.FC<ToggleProps> = ({ id, children }) => {
  const show = useSelector(tState.getToggleState);
  return show[id] ? <React.Fragment>{children}</React.Fragment> : null;
};
