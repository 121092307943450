import React from "react";
import { Tab, TabProps } from "./Tab";

interface TabsProps {}

export const Tabs: React.FC<TabsProps> = ({ children }) => {
  const childrenTabs = React.Children.map(
    children,
    (child: React.ReactElement<TabProps>) => {
      const { label, activeLabel, link, hidden } = child.props;
      return (
        <Tab
          key={label}
          label={label}
          activeLabel={activeLabel}
          link={link}
          hidden={hidden}
        />
      );
    }
  );

  return (
    <div className='tabs'>
      <ol className='border-b border-gray-400'>{childrenTabs}</ol>
    </div>
  );
};
