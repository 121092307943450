const storeString = (key: string, data: string) => {
  sessionStorage.setItem(key, data);
};

const getString = (key: string) => {
  return sessionStorage.getItem(key);
};

const getHeaders = (table: string): string[] => {
  const str = localStorage.getItem(table)
  return str ? str.split(',') : []
}

const setHeaders = (table: string, headers: string[]) => {
  const headerStr = headers.join(',')
  localStorage.setItem(table, headerStr)
}
const storeService = {
  storeString,
  getString,
  setHeaders,
  getHeaders
};

export default storeService;
