import {DefinedMessages} from '../../types/types';

export const messages: DefinedMessages = {
  createheader: {
    id: 'event.card.create.header',
    defaultMessage: 'New event',
  },
  createtext: {
    id: 'event.card.create.description',
    defaultMessage: 'Here you can create a new event!{lineBreak}Buckle up... ',
  },
  alleventsheader: {
    id: 'event.card.all',
    defaultMessage: 'My events',
  },
  alleventstext: {
    id: 'event.card.all.description',
    defaultMessage: 'Here you can see all your events with detail.',
  },
}
