import {LoopReducer} from 'redux-loop';
import {ActionType, getType} from 'typesafe-actions';
import {loginAsync, logout} from '../login/loginReducer';
import {UserState, User, Services} from '../types/types';
import {AppState} from './combineReducer';

const initialState: UserState = {
  user: {
    customerId: '',
    userEmail: '',
    userFirstName: '',
    userLastName: '',
    userId: '',
    userStatus: '',
    userType: '',
  },
  customer: {
    customerBillingAddressCity: '',
    customerBillingAddressCountry: '',
    customerBillingAddressStreet: '',
    customerBillingOvt: '',
    customerBusinessId: '',
    customerContactPersonEmail: '',
    customerContactPersonName: '',
    customerContactPersonPhoneNumber: '',
    customerId: '',
    customerName: '',
    customerSettings: {
      general: {
        enabledServices: {
          events: false,
          leadCollection: false,
          certificates: false
        },
        someOtherSetting: null,
      },
      eventRegistrar: {
        invoicingStateOptions: [],
        customStateOptions: [],
      },
      eventCreation: {
        linkCustomerPrefix: null,
        pageStyling: {
          backgroundColor: null,
          logoUrl: null,
        },
      },
      postmark: {
        asetus1: 1,
      },
    },
  },
};

type Action = ActionType<typeof loginAsync> | ActionType<typeof logout>;

export const userReducer: LoopReducer<UserState, Action> = (
  state: UserState = initialState,
  action: Action
): UserState => {
  switch (action.type) {
    case getType(loginAsync.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          user: action.payload.data.user,
          customer: action.payload.data.customer,
          lastLogin: action.payload.data.lastLogin
        };
      }
      return state;

    //logout
    case getType(logout):
      return action.payload;

    default:
      return state;
  }
};

export function getUser(state: AppState): string {
  return `${state.userState.user.userFirstName} ${state.userState.user.userLastName}`;
}

export function selectUserData(state: AppState): User {
  return state.userState.user;
}

export const selectServices = (state: AppState): Services => {
  return state.userState.customer.customerSettings.general.enabledServices
}
