import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import * as tState from '../../reducers/toggleReducer';
import { FormattedMessageProps } from '../../types/types';
import { IoIosClose } from 'react-icons/io';

interface HoverProps {
  overlayProps: HoverAttributes;
  styles?: string;
  message: FormattedMessageProps;
  messageHeader: FormattedMessageProps;
}

export type HoverAttributes = {
  overlayId: string;
  overlayToggle?: (id: string) => void;
  position?: string;
};

export const HoverMessage: React.FC<HoverProps> = ({
  children,
  styles,
  message,
  messageHeader,
  overlayProps: { overlayId, position, overlayToggle },
}) => {
  const style = styles
    ? styles
    : 'bg-brand-white border border-gray-300 bg-brand-white shadow-xl flex-col content-center rounded-xl bg-opacity-95 w-64 text-brand-dark_blue z-50 absolute text-center p-4';
  const pos = position ? position : 'left-52';
  const toggleState = useSelector(tState.getToggleState);

  const lineBreak = {
    lineBreak: (
      <>
        <br />
        <br />
      </>
    ),
  };

  return (
    <React.Fragment>
      {children}
      {toggleState[overlayId] && (
        <div className={`${style} ${pos}`}>
          {overlayToggle && (
            <div
              className="cursor-pointer hover:text-red-400"
              onClick={() => overlayToggle(overlayId)}
            >
              <IoIosClose />
            </div>
          )}
          <div className="font-bold">
            <FormattedMessage {...messageHeader} />
          </div>
          <div>
            <FormattedMessage {...message} values={lineBreak} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
