import { Cmd, Loop, loop, LoopReducer } from 'redux-loop';
import { ActionType, createAsyncAction, getType } from 'typesafe-actions';
import fileService, { FileUploadResponse } from '../services/fileService';

export type FileUploadState = {};

const initialState: FileUploadState = {};

export type UploadContext = {
  formName: string;
  value: string;
  questionId: string;
  toggleId: string;
};

export const uploadFileToForm = createAsyncAction(
  'START_FILE_FORM_UPLOAD',
  'FILE_UPLOAD_FORM_COMPLETE',
  'FILE_UPLOAD_FORM_FAIL'
)<
  {
    formName: string;
    blob: Blob;
    toggleId: string;
    questionId: string;
  },
  { data: FileUploadResponse; status: number; context: UploadContext },
  Error
>();

type Action = ActionType<typeof uploadFileToForm>;

export const fileUploadReducer: LoopReducer<FileUploadState, Action> = (
  state: FileUploadState = initialState,
  action: Action
): FileUploadState | Loop<FileUploadState> => {
  switch (action.type) {
    case getType(uploadFileToForm.request):
      return loop(
        state,
        Cmd.run(fileService.fileUpload, {
          successActionCreator: uploadFileToForm.success,
          failActionCreator: uploadFileToForm.failure,
          args: [
            action.payload.blob,
            action.payload.formName,
            action.payload.toggleId,
            action.payload.questionId,
          ],
        })
      );

    default:
      return state;
  }
};
