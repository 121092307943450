import React from 'react';

interface Props {
  register?: any;
}

export const RequiredAsterisk: React.FC<Props> = ({ register }) => {
  if (register === undefined || register === null) {
    return null;
  }
  return <span className="font-normal text-red-500">*</span>;
};
