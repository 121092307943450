import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import allActions from '../actions/allActions';
import { NotFound } from '../components/utils/NotFound';
import { NewParticipant } from '../events/eventDetails/components/NewParticipant';
import CreateRegistrationContainer from '../events/eventRegistration/CreateRegistrationContainer';
import Linkpage from '../events/eventRegistration/views/Linkpage';
import SubEventContainer from '../events/SubEventContainer';
import { EventParamTypes } from '../types/types';

export const SubEventNavigation: React.FC = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { id, subEventId } = useParams<EventParamTypes>();

  const getAll = useCallback(() => {
    dispatch(allActions.eventActions.fetchSubEventDataInOrder(id, subEventId));
  }, [id, subEventId, dispatch]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <Switch>
      <Route exact path={path} render={() => <SubEventContainer />} />
      <Route path={`${path}/link`} render={() => <Linkpage />} />
      <Route
        path={`${path}/newparticipant`}
        render={() => <NewParticipant />}
      />
      <Route
        path={`${path}/registration`}
        render={() => <CreateRegistrationContainer />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default SubEventNavigation;
