import {DefinedMessages} from '../../types/types';

export const messages: DefinedMessages = {
  createheader: {
    id: 'cert.create.send',
    defaultMessage: 'Create cert',
  },
  createtext: {
    id: 'cert.create.send.description',
    defaultMessage:
      'Here you can send a new eDiploma.{lineBreak}You can send eDiploma to one or multiple receivers.{lineBreak}Receivers can be imported directly from ContactMate, Lyyti or from .csv-files.',
  },
  allcertsheader: {
    id: 'cert.card.all',
    defaultMessage: 'All certs',
  },
  allcertstext: {
    id: 'cert.card.all.description',
    defaultMessage: 'Here you can follow-up, edit and resend eDiplomas',
  },
}
