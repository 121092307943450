import React, { useCallback, useEffect, useState } from 'react';
import { EventParamTypes } from '../../types/types';
import { Loading } from '../../components/utils/Loading';
import { ErrorFetching } from '../../components/modals/ErrorFetching';
import { CreateRegistrationQuestions } from './components/registrationquestions/CreateRegistrationQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import Tab from '../../components/tabs/Tab';
import { Tabs } from '../../components/tabs/Tabs';
import { EventInformationForm } from './components/EventInformationForm';
import allActions from '../../actions/allActions';
import { PageStylingForm } from './components/PageStylingForm';
import PreviewForm from './components/PreviewForm';
import * as fState from '../../reducers/formReducer';
import * as erState from '../../reducers/errorReducer';
import { selectHasSubEventForm } from '../eventReducer';
import { ValidationList } from './components/ValidationList';
import { useNotify } from '../../hooks/useNotify';
import { BreadRoute } from '../../components/breadcrumbs/BreadRoute';
import { BreadCrumb } from '../../components/breadcrumbs/BreadCrumb';
import SaveQuestions from './components/SaveQuestions';
import { useIntl } from 'react-intl';
import { messages } from './translations/tabTranslations';
import { AppState } from '../../reducers/combineReducer';
import { selectLoadingMultiple } from '../../reducers/loadingReducer';
import { ImportModal } from './components/ImportModal';

export const CreateRegistrationContainer: React.FC = () => {
  const previewItems = useSelector(fState.getPreviewFormItems);
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'getForm',
      'saveRegistration',
      'importRegistrationstate',
    ])
  );
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { id, subEventId } = useParams<EventParamTypes>();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);
  const { path, url } = useRouteMatch();
  const { formatMessage } = useIntl();
  const { subEventFormId, subEventState } = useSelector((state: AppState) =>
    selectHasSubEventForm(state, id, subEventId)
  );
  const previewFormName = 'previewRegistrationForm';
  const basicInformationTab = formatMessage({ ...messages.basic });
  const pageStylingTab = formatMessage({ ...messages.styling });
  const registrationQuestionsTab = formatMessage({ ...messages.questions });
  const registrationValidationTab = formatMessage({ ...messages.validation });
  const previewTab = formatMessage({ ...messages.preview });
  const saveTab = formatMessage({ ...messages.save });

  const getActiveTab = useCallback(() => {
    const pathArray = location.pathname.split('/');
    return pathArray.length === 6
      ? setActiveTab(basicInformationTab)
      : setActiveTab(pathArray[pathArray.length - 1]);
  }, [location, basicInformationTab]);

  const confirmRegistrationQuestions = () => {
    dispatch(
      allActions.eventActions.startSaveRegistrationQuestions(
        previewItems,
        subEventId,
        subEventFormId,
        subEventState
      )
    );
  };

  useEffect(() => {
    getActiveTab();
  }, [getActiveTab]);

  return isLoading ? (
    <Loading styles="items-center m-auto" />
  ) : (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb label={`subevents`} link={`/events/${id}/subevents/`} />
        <BreadCrumb
          label={`subevent ${subEventId}`}
          link={`/events/${id}/subevents/${subEventId}`}
        />
        <BreadCrumb
          label={`registration`}
          link={`/events/${id}/subevents/${subEventId}/registration`}
          lastChild={true}
        />
      </BreadRoute>
      <Tabs>
        <Tab
          activeLabel={activeTab}
          label={basicInformationTab}
          link={`${url}`}
        />
        <Tab
          activeLabel={activeTab}
          label={pageStylingTab}
          link={`${url}/${pageStylingTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={registrationQuestionsTab}
          link={`${url}/${registrationQuestionsTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={registrationValidationTab}
          link={`${url}/${registrationValidationTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={previewTab}
          link={`${url}/${previewTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={saveTab}
          link={`${url}/${saveTab}`}
        />
      </Tabs>
      <ImportModal />
      <div className="h-full max-h-11/12 w-full flex justify-center border-r border-l border-b border-gray-400 overflow-auto scroller">
        <ErrorFetching
          styles={
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 border shadow-xl z-50 bg-brand-white'
          }
          message={errorMsg}
        />
        <Route exact path={`${path}`} render={() => <EventInformationForm />} />
        <Route
          path={`${path}/${registrationQuestionsTab}`}
          render={() => (
            <CreateRegistrationQuestions previewFormName={previewFormName} />
          )}
        />
        <Route
          path={`${path}/${pageStylingTab}`}
          render={() => <PageStylingForm />}
        />
        <Route
          path={`${path}/${registrationValidationTab}`}
          render={() => <ValidationList />}
        />
        <Route
          path={`${path}/${previewTab}`}
          render={() => <PreviewForm formName={previewFormName} />}
        />
        <Route
          path={`${path}/${saveTab}`}
          render={() => (
            <SaveQuestions confirm={confirmRegistrationQuestions} />
          )}
        />
      </div>
    </div>
  );
};

export default CreateRegistrationContainer;
