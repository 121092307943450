import {LoginObject, UserState} from "../types/types"

const startLogin = (logObj: LoginObject) => {
  return {type: 'START_LOGIN', payload: logObj}
}

const logout = () => {
  const emptyUserData: UserState = {
    user: {
      customerId: '',
      userEmail: '',
      userFirstName: '',
      userLastName: '',
      userId: '',
      userStatus: '',
      userType: ''
    },
    customer: {
      customerBillingAddressCity: '',
      customerBillingAddressCountry: '',
      customerBillingAddressStreet: '',
      customerBillingOvt: '',
      customerBusinessId: '',
      customerContactPersonEmail: '',
      customerContactPersonName: '',
      customerContactPersonPhoneNumber: '',
      customerId: '',
      customerName: '',
      customerSettings: {
        general: {
          enabledServices: {
            events: false,
            leadCollection: false,
            certificates: false
          },
          someOtherSetting: null
        },
        eventRegistrar: {
          invoicingStateOptions: [],
          customStateOptions: []
        },
        eventCreation: {
          linkCustomerPrefix: null,
          pageStyling: {
            backgroundColor: null,
            logoUrl: null
          }
        },
        postmark: {
          asetus1: 1
        }
      }
    }
  }
  return {type: 'LOGOUT', payload: emptyUserData}
}

export const loginActions = {
  startLogin,
  logout
}
