import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import allActions from '../actions/allActions';
import CertDetails from '../certs/CertDetails';
import EditCert from '../certs/EditCert';
import {NotFound} from '../components/utils/NotFound';

export const CertNavigation: React.FC = () => {
  let {path} = useRouteMatch();
  const dispatch = useDispatch();

  const getAllCerts = useCallback(() => {
    dispatch(allActions.certActions.getAllCerts());
  }, [dispatch]);

  useEffect(() => {
    getAllCerts();
  }, [getAllCerts]);

  return (
    <Switch>
      <Route exact path={path} render={() => <CertDetails />} />
      <Route path={`${path}/:mailId/edit`} render={() => <EditCert />} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default CertNavigation;
