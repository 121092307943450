import React from 'react';
import { FormattedMessage } from 'react-intl';

export const NoTemplate: React.FC = () => {
  const styles = 'text-gray-400 text-6xl';

  return (
    <div className={styles}>
      <FormattedMessage
        id="cert.create.notemplate.text"
        defaultMessage="No template selected"
      />
    </div>
  );
};

export default NoTemplate;
