import {DefinedMessages} from '../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'event.create.header',
    defaultMessage: 'Create event',
  },
  paragraph: {
    id: 'event.create.paragraph',
    defaultMessage:
      'Start creating event by filling basic information regarding event.',
  },
  eventname: {
    id: 'create.event.form.name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'create.event.form.description',
    defaultMessage: 'Description',
  },
  organiser: {
    id: 'create.event.form.organiser',
    defaultMessage: 'Organiser',
  },
  place: {
    id: 'create.event.form.place',
    defaultMessage: 'Place',
  },
  streetaddress: {
    id: 'create.event.form.streetaddress',
    defaultMessage: 'Street address',
  },
  zip: {
    id: 'create.event.form.zip',
    defaultMessage: 'Zip',
  },
  city: {
    id: 'create.event.form.city',
    defaultMessage: 'City',
  },
  country: {
    id: 'create.event.form.country',
    defaultMessage: 'Country',
  },
  start: {
    id: 'create.event.form.start',
    defaultMessage: 'Start',
  },
  end: {
    id: 'create.event.form.end',
    defaultMessage: 'End',
  },
  webpage: {
    id: 'create.event.form.webpage',
    defaultMessage: 'Webpage',
  },
  supportemail: {
    id: 'create.event.form.supportemail',
    defaultMessage: 'Support (email)',
  },
  supportphone: {
    id: 'create.event.form.supportphone',
    defaultMessage: 'Support (phone)',
  },
  instructions: {
    id: 'create.event.form.supportinstruction',
    defaultMessage: 'Instructions',
  },
  submit: {
    id: 'create.event.form.submit',
    defaultMessage: 'Create',
  },
}
