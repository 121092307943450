import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InvoiceErrors } from '../../reducers/invoiceReducer';
import { messages } from '../translations';

type Props = {
  errors: InvoiceErrors;
  container: string;
  sendInvoice: () => void;
};
export const InvoiceErrorsCard: React.FC<Props> = ({
  errors,
  container,
  sendInvoice,
}) => {
  const message =
    errors.missing.length > 0 ? messages.errorsmissing : messages.errorsok;
  const err = errors.missing.length > 0;
  const sendButton = !err
    ? 'bg-blue-400 hover:bg-brand-blue'
    : 'bg-red-300 cursor-default';
  return (
    <div className={container}>
      <div>
        <span className="text-xl text-brand-dark_blue py-2">
          <FormattedMessage {...message} />
        </span>
        {errors.missing.map((m, i) => (
          <div className="py-1" key={`missing_${m}_${i}`}>
            <span className="mx-2 text-brand-dark_blue truncate">❗{m}</span>
          </div>
        ))}
      </div>
      <div className="flex flex-col">
        {err && (
          <span className="text-brand-dark_blue self-center">
            <FormattedMessage {...messages.errorsdesciption} />
          </span>
        )}
        <button
          className={`w-1/6 mx-auto py-2 px-4 m-5 rounded text-brand-white ${sendButton}`}
          onClick={sendInvoice}
          disabled={err}
        >
          <FormattedMessage {...messages.sendbutton} />
        </button>
      </div>
    </div>
  );
};
