import { FormInputInfo } from '../types/types';

const getParticipantDetails = (subEventId: string, uuid: string) => {
  return {
    type: 'START_PARTICIPANT_DETAILS_FETCH',
    payload: { subEventId, uuid },
  };
};
const getParticipants = (id: string, name?: string) => {
  return {
    type: 'GET_EVENT_PARTICIPANTS',
    payload: { id, name: name ? name : null },
  };
};

const changeParticipantsData = (id: string, value: string, array: boolean) => {
  return {
    type: 'CHANGE_PARTICIPANTS_DATA',
    payload: { id, value, array },
  };
};

const saveParticipantChanges = (uuid: string, eventId: string) => {
  return {
    type: 'START_PARTICIPANT_PATCH_REQUEST',
    payload: { uuid, eventId },
  };
};

const newParticipant = (
  subEventId: string,
  eventId: string,
  items: FormInputInfo[],
  values: { [key: string]: string | string[] },
  send: boolean,
  link?: string
) => {
  return {
    type: 'START_NEW_PARTICIPANT_REQUEST',
    payload: {
      items,
      values,
      eventId,
      subEventId,
      send,
      ...(link && { link }),
    },
  };
};

const cancelParticipant = (
  eventId: string,
  subEventId: string,
  uuid: string
) => {
  return {
    type: 'START_CANCEL_PARTICIPANT_REQUEST',
    payload: { eventId, subEventId, uuid },
  };
};

const deleteParticipant = (
  eventId: string,
  subEventId: string,
  uuid: string
) => {
  return {
    type: 'START_DELETE_PARTICIPANT_REQUEST',
    payload: { eventId, subEventId, uuid },
  };
};

const moveParticipant = (
  from: string,
  to: string,
  eventId: string,
  uuid: string,
  moveGroup: boolean
) => {
  return {
    type: 'START_PARTICIPANT_MOVE_REQUEST',
    payload: { from, to, eventId, uuid, moveGroup },
  };
};

const participantActions = {
  getParticipantDetails,
  getParticipants,
  changeParticipantsData,
  saveParticipantChanges,
  newParticipant,
  cancelParticipant,
  deleteParticipant,
  moveParticipant,
};

export default participantActions;
