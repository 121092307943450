import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { GrDrag } from 'react-icons/gr';
import { FormattedMessage } from 'react-intl';
import { IconWrapper } from '../../utils/IconWrapper';

interface ItemProps {
  item: string;
  id: string;
  index: number;
  wrap: boolean;
  translations?: { [key: string]: string };
}

export const DraggableColumnItem: React.FC<ItemProps> = ({
  item,
  id,
  index,
  wrap,
  translations,
}) => {
  const itemStyle = wrap
    ? 'h-12 p-4 m-4 border border-solid border-gray-200 rounded shadow-lg'
    : 'mx-auto p-2 m-2 border border-solid border-gray-200 w-5/6 rounded shadow-lg';
  const iconStyle = 'text-gray-400 mr-2 inline-block';

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        const translation =
          translations && translations[item.toLowerCase()] ? true : false;
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`${itemStyle}`}
          >
            <IconWrapper styles={iconStyle}>
              <GrDrag />
            </IconWrapper>
            {translation ? (
              <FormattedMessage
                id={translations[item.toLowerCase()]}
                defaultMessage={item}
              />
            ) : (
              <span>{item}</span>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};
