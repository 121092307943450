import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  basic: {
    id: 'registration.tabs.basic',
    defaultMessage: 'Basicinformation',
  },
  styling: {
    id: 'registration.tabs.styling',
    defaultMessage: 'Styling',
  },
  questions: {
    id: 'registration.tabs.questions',
    defaultMessage: 'Questions',
  },
  validation: {
    id: 'registration.tabs.validation',
    defaultMessage: 'Validation',
  },
  preview: {
    id: 'create.cert.preview.button',
    defaultMessage: 'Preview',
  },
  save: {
    id: 'create.cert.save.button',
    defaultMessage: 'Save',
  },
};
