import React, { useState } from 'react';
import { AiOutlineMenuFold, AiOutlineClose } from 'react-icons/ai';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import FormCheckBox from '../../../components/form/FormCheckBox';
import FormSelect from '../../../components/form/FormSelect';
import { Button } from '../../../components/utils/Button';
import * as lState from '../../listEvents/listEventsReducer';
import { messages } from '../translations/importTranslations';

export type ImportTarget = 'basic' | 'styling' | 'questions';

export const ImportModal: React.FC = () => {
  const initialState = {
    subevent: '',
    importTargets: [],
  };
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();

  const changeSubEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState({ ...state, subevent: event.target.value });
  };

  const selectImportTargets = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedImports = state.importTargets.includes(event.target.value)
      ? state.importTargets.filter((imp) => imp !== event.target.value)
      : state.importTargets.concat([event.target.value]);
    setState({ ...state, importTargets: updatedImports });
  };

  const importState = () => {
    dispatch(
      allActions.eventActions.importRegistrationState(
        state.subevent,
        subEvents,
        state.importTargets
      )
    );
  };

  const { formatMessage } = useIntl();
  const subEvents = useSelector(lState.selectSubEventsWithRegistrationState);
  const buttonOK =
    'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 border border-gray-200 shadow-lg';
  const buttonDisabled =
    'bg-gray-500 text-white m-4 rounded w-20 h-10 shadow-lg cursor-default';

  return isOpen ? (
    <>
      <div
        className="absolute inset-0 bg-gray-700 opacity-80 z-10"
        onClick={() => setIsOpen(!isOpen)}
      />
      <div
        className={`absolute right-0 top-0 mt-14 rounded w-1/2 z-20 bg-brand-white`}
      >
        <div className="flex">
          <div className="text-brand-dark_blue hover:text-yellow-400 ml-2 mt-2">
            <span onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
              <AiOutlineClose size={24} />
            </span>
          </div>
          <div>
            <div className="p-8">
              <span className="text-3xl text-brand-dark_blue">
                <FormattedMessage {...messages.importheader} />
              </span>
            </div>
            <FormSelect
              id="subevent"
              question={formatMessage({ ...messages.selectsubevent })}
              cursiveLabel={formatMessage({
                ...messages.selectsubeventdescription,
              })}
              handleInputChange={changeSubEvent}
              options={[''].concat(subEvents.map((s) => s.subEventName))}
              value={state.subevent}
            />
            <FormCheckBox
              id="targets"
              inputStyle={'text-gray-700'}
              question={formatMessage({ ...messages.targets })}
              cursiveLabel={formatMessage({ ...messages.targetsdescription })}
              handleInputChange={selectImportTargets}
              options={[
                formatMessage({ ...messages.targetbasic }),
                formatMessage({ ...messages.targetstyling }),
                formatMessage({ ...messages.targetquestions }),
              ]}
              checked={state.importTargets}
            />
            <Button
              styles={
                state.importTargets.length === 0 || state.subevent === ''
                  ? buttonDisabled
                  : buttonOK
              }
              onOk={importState}
              id={state.subevent}
              msg={messages.importbutton}
              disabled={
                state.importTargets.length === 0 || state.subevent === ''
                  ? true
                  : false
              }
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      className={`absolute right-0 top-0 mt-14 rounded w-10 h-10 align-middle bg-brand-dark_blue`}
    >
      <div className="w-full m-auto">
        <span
          onClick={() => setIsOpen(!isOpen)}
          className={
            'inline-block h-1/2 m-1 align-middle cursor-pointer text-brand-white hover:text-yellow-400'
          }
        >
          <AiOutlineMenuFold size={30} />
        </span>
      </div>
    </div>
  );
};
