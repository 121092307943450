import { UploadContext } from '../reducers/fileUploadReducer';
import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

export type FileUploadResponse = {
  size: number;
  extension: 'png';
  fileType: 'file';
  fileUrl: string;
  fileName: string;
  fileUri: string;
  resourceId: number;
};

const fileUpload = async (
  imageBlob: Blob,
  formName: string,
  toggleId: string,
  questionId: string
): Promise<{
  data: FileUploadResponse;
  status: number;
  context: UploadContext;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers = { Jwt, 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();
  const file = new File([imageBlob], 'croppedPicture.jpg');
  formData.append('picture', file);
  const result = await axiosInstance.post(
    `${config.baseUrl}/file/picture`,
    formData,
    {
      headers,
    }
  );
  const context = {
    formName,
    toggleId,
    value: result.data.fileUrl,
    questionId,
  };
  return { data: result.data, status: result.status, context };
};

const fileService = {
  fileUpload,
};

export default fileService;
