import React from 'react';
import { BiPlus, BiMinus } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import { IconWrapper } from '../../../../components/utils/IconWrapper';
import { messages } from '../../translations/questionTranslations';

interface Props {
  options?: string[];
  message: string;
  addOptions: () => void;
  removeOption: () => void;
  arrayInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  validate: (str: string) => void;
  hideOptions: 'visible' | 'invisible';
}
export const QuestionOptions: React.FC<Props> = ({
  options,
  message,
  addOptions,
  removeOption,
  arrayInputChange,
  validate,
  hideOptions,
}) => {
  const labelStyle = 'm-2 uppercase font-semibold text-lg text-brand-dark_blue';
  const inputStyle =
    'font-semibold text-brand-dark_blue focus:outline-none focus:bg-yellow-200 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal cursor-pointer';

  return (
    <div className={`flex flex-wrap ${hideOptions}`}>
      {options?.map((option, index: number) => {
        const optionValue = options ? options[index] : '';
        return (
          <div key={index} className="flex flex-col justify-start">
            <label htmlFor={`option${index}`} className={labelStyle}>
              <FormattedMessage {...messages.option} />
            </label>
            <input
              name={`option${index}`}
              id={`option${index}`}
              value={optionValue}
              className={inputStyle}
              onChange={(e) => arrayInputChange(e, index)}
              onBlur={(e) => validate(e.target.value)}
            />
          </div>
        );
      })}

      <div>
        <IconWrapper styles="text-brand-blue hover:text-brand-dark_blue cursor-pointer">
          <BiPlus size={20} onClick={addOptions} />
        </IconWrapper>
        <IconWrapper styles="text-brand-blue hover:text-brand-dark_blue cursor-pointer">
          <BiMinus size={20} onClick={removeOption} />
        </IconWrapper>
      </div>
      {message ? <span className="text-red-400">{message}</span> : null}
    </div>
  );
};
