import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  add: {
    id: 'form.repeatgroup.add',
    defaultMessage: 'Add {type}',
  },
  remove: {
    id: 'form.repeatgroup.remove',
    defaultMessage: 'Remove {type}',
  },
}
