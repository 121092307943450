import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MarketingPage: React.FC = () => {
  return (
    <div>
      <FormattedMessage id="marketing.header" />
    </div>
  );
};

export default MarketingPage;
