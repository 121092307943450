import React from "react";
import { Link } from "react-router-dom";

export interface TabProps {
  label: string;
  link: string;
  activeLabel: string;
  hidden?: boolean;
}

export const Tab: React.FC<TabProps> = ({
  label,
  link,
  activeLabel,
  hidden,
}) => {
  const activeBorder =
    activeLabel === label
      ? `border border-gray-400 border-b-white rounded-t-lg`
      : "";
  const hide = hidden ? "hidden" : "";

  return (
    <li
      className={`inline-block list-none mt-1 -mb-px px-1 py-2 font-thin uppercase text-brand-dark_blue ${activeBorder} ${hide}`}
    >
      <Link to={link}>{label}</Link>
    </li>
  );
};

export default Tab;
