import {DefinedMessages} from '../../types/types';

export const messages: DefinedMessages = {
  pageheader: {
    id: 'cert.create.preview.header',
    defaultMessage: 'Great, everything is ready!',
  },
  previewheader: {
    id: 'cert.create.preview.previewcard.header',
    defaultMessage: 'Preview',
  },
  previewtext: {
    id: 'cert.create.preview.previewcard.paragraph',
    defaultMessage:
      'Preview will generate the PDF - eDiploma, and open it in a new tab. If sending to multiple receivers, preview will display first receiver.',
  },
  previewbutton: {
    id: 'cert.create.preview.button',
    defaultMessage: 'Send',
  },
  sendheader: {
    id: 'cert.create.preview.confirmcard.header',
    defaultMessage: 'Send',
  },
  sendtext: {
    id: 'cert.create.preview.confirmcard.paragraph',
    defaultMessage:
      'eDiplomas will be sent to selected participants via email.',
  },
  sendbutton: {
    id: 'cert.create.send.button',
    defaultMessage: 'Send',
  },
}
