import { InvoiceValues } from '../invoicing/components/InvoicePreviewItem';
import { InputDetails } from '../invoicing/invoiceMappings';
import { InvoiceOperator, SourceOption } from '../reducers/invoiceReducer';
import { ImportCSVData } from '../types/types';

const changeEventsource = (source: SourceOption) => {
  return { type: 'INVOICE_CHANGE_EVENT_SOURCE', payload: source };
};

const changeEvent = (
  eventId: string,
  eventSource: SourceOption,
  eventName: string
) => {
  return {
    type: 'CHANGE_EVENT_INVOICE',
    payload: { eventId, eventSource, eventName },
  };
};

const toggleMultisend = () => {
  return { type: 'INVOICING_TOGGLE_MULTISEND' };
};

const toggleRecipient = (id: string) => {
  return {
    type: 'TOGGLE_INVOICE_RECIPIENT',
    payload: id,
  };
};

const toggleAllRecipients = () => {
  return {
    type: 'TOGGLE_ALL_INVOICE_RECIPIENTS',
  };
};

const setMapping = (
  mapping: { [key: string]: InputDetails },
  destination: InvoiceOperator
) => {
  return { type: 'SET_INVOICE_MAPPING', payload: { mapping, destination } };
};

const importReceivers = (receivers: ImportCSVData[], headers: boolean) => {
  return {
    type: 'IMPORT_INVOICE_RECEIVERS',
    payload: { data: receivers, headers },
  };
};

const changeInheritance = (key: string) => {
  return { type: 'CHANGE_INVOICE_MAPPING_INHERITANCE', payload: key };
};

const startInvoiceSend = (payload: InvoiceValues[]) => {
  return { type: 'START_INVOICE_SEND', payload };
};

const startGetFennoaProducts = () => {
  return { type: 'START_GET_FENNOA_PRODUCTS' };
};

const startGetFennoaInvoices = () => {
  return { type: 'START_GET_FENNOA_INVOICES' };
};

export const invoiceActions = {
  changeEventsource,
  changeEvent,
  toggleMultisend,
  toggleRecipient,
  toggleAllRecipients,
  setMapping,
  changeInheritance,
  startInvoiceSend,
  startGetFennoaProducts,
  startGetFennoaInvoices,
  importReceivers,
};
