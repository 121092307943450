import React from 'react';
import { BreadCrumb } from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import { Card } from '../components/utils/Card';
import * as nt from '../navigation/navigationTranslations';
import { messages } from './translations/menu';
import * as imgs from '../img/images';

export const CertMenu: React.FC = () => {
  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'cert-menu'} link={'/certmenu'} lastChild={true} />
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center">
        <Card
          header={{ ...messages.createheader }}
          paragraph={{ ...messages.createtext }}
          link={`/createcert`}
          linkMessage={{ ...nt.messages.createcert }}
          image={imgs.CERT_CARD_CREATE}
          canClick={true}
        />
        <Card
          header={{ ...messages.allcertsheader }}
          paragraph={{ ...messages.allcertstext }}
          link={`/certs`}
          linkMessage={{ ...nt.messages.allcerts }}
          image={imgs.CERT_CARD_ALL}
          canClick={true}
        />
      </div>
    </div>
  );
};

export default CertMenu;
