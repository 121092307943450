import {IntlMessageID} from "../..";

export const translations: {[key: string]: IntlMessageID} = {
  certoperationid: 'certs.headers.certoperationid',
  certoperationstatus: 'certs.headers.certoperationstatus',
  certoperationtype: 'certs.headers.certoperationtype',
  certoperationname: 'certs.headers.certoperationname',
  certoperationcreationtime: 'certs.headers.certoperationcreationtime',
  certoperationmodifiedtime: 'certs.headers.certoperationmodifiedtime',
  templateid: 'certs.headers.templateid',
  templatename: 'certs.headers.templatename',
  templatedescription: 'certs.headers.description',
  postmarktemplateid: 'certs.headers.postmarktemplateid',
  postmarktemplatename: 'certs.headers.postmarktemplatename',
  postmarktemplatedescription: 'certs.headers.postmarktemplatedescription',
  mailcount: 'certs.headers.mailcount',
};
