import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Link } from 'react-router-dom';
import { Paragraph } from './Paragrahp';

interface Props {
  header: MessageDescriptor;
  paragraph: MessageDescriptor;
  link: string;
  linkMessage: MessageDescriptor;
  canClick: boolean;
  image: string;
}

export const Card: React.FC<Props> = ({
  header,
  paragraph,
  link,
  linkMessage,
  canClick,
  image,
}) => {
  const lineBreak = { lineBreak: <br /> };

  return (
    <div className="rounded shadow-xl w-1/2 flex flex-col justify-center m-4 border border-gray-200 text-center">
      <div className="h-1/2">
        <img
          className="h-full mx-auto"
          src={`${process.env.PUBLIC_URL}/${image}`}
          alt={`image_${header}`}
        />
      </div>
      <Paragraph message={header} header={true} />
      <Paragraph message={paragraph} values={lineBreak} header={false} />
      <Link
        to={link}
        className={`m-4 p-4 mx-auto rounded hover:bg-yellow-600 text-white font-thin ${
          canClick ? 'bg-yellow-500' : 'pointer-events-none bg-gray-500'
        }`}
      >
        <FormattedMessage {...linkMessage} />
      </Link>
    </div>
  );
};

export default Card;
