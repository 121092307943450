import { loginActions } from '../login/loginActions';
import toggleActions from './toggleActions';
import formActions from './formActions';
import eventActions from './eventActions';
import certActions from './certActions';
import errorActions from './errorActions';
import participantActions from './participantActions';
import { invoiceActions } from './invoiceActions';

const allActions = {
  toggleActions,
  formActions,
  loginActions,
  eventActions,
  certActions,
  errorActions,
  participantActions,
  invoiceActions,
};

export default allActions;
