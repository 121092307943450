import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import allActions from '../../actions/allActions';
import {getCustomerCertTemplate} from '../certReducer';
import * as eState from '../../reducers/errorReducer';
import {useNotify} from '../../hooks/useNotify';
import {ErrorFetching} from '../../components/modals/ErrorFetching';
import {FormattedMessage} from 'react-intl';
import NoTemplate from './NoTemplate';
import {Paragraph} from '../../components/utils/Paragrahp';
import {messages} from '../translations/confirmsend';
import {AppState} from '../../reducers/combineReducer';

interface ConfirmSendProps {
  templateName: string;
}

export const ConfirmSend: React.FC<ConfirmSendProps> = ({templateName}) => {
  const dispatch = useDispatch();
  const selected = useSelector((state: AppState) =>
    getCustomerCertTemplate(state, templateName)
  );
  const {message} = useSelector(eState.getErrorMessage);
  const errorMsg = useNotify(message);
  const errorDivStyles =
    'absolute z-50 transform translate-y-1/2 translate-x-1/2 top-0 left-0 flex justify-center place-items-center w-1/2 h-1/6 shadow-xl bg-white border-none rounded';

  const sendNewCert = (id: string) => {
    dispatch(allActions.certActions.startCertSend(id, false));
  };

  const sendCertPreview = (id: string) => {
    dispatch(allActions.certActions.startCertSend(id, true));
  };

  return selected.found ? (
    <div className="h-full w-full bg-white rounded overflow-auto scroller">
      <div className="flex flex-col w-full h-full items-center">
        <div className="text-brand-dark_blue text-4xl align-middle">
          <span className="align-middle">
            <FormattedMessage {...messages.pageheader} />
          </span>
        </div>
        <div className="flex w-full justify-around items-center relative">
          <div className="rounded shadow-xl w-1/2 flex flex-col justify-center m-4 border border-gray-200 text-center">
            <Paragraph message={{...messages.previewheader}} header={true} />
            <Paragraph message={{...messages.previewtext}} header={false} />
            <button
              className="m-4 p-4 mx-auto rounded bg-yellow-500 hover:bg-yellow-600 text-white font-thin"
              onClick={() => sendCertPreview(selected.template.templateId)}
            >
              <FormattedMessage {...messages.previewbutton} />
            </button>
          </div>
          <div className="rounded shadow-xl w-1/2 flex flex-col justify-center m-4 border border-gray-200 text-center">
            <Paragraph message={{...messages.sendheader}} header={true} />
            <Paragraph message={{...messages.sendtext}} header={false} />
            <button
              className="m-4 p-4 mx-auto rounded bg-blue-400 hover:bg-blue-500 font-thin text-white"
              onClick={() => sendNewCert(selected.template.templateId)}
            >
              <FormattedMessage {...messages.sendbutton} />
            </button>
          </div>
        </div>
        <ErrorFetching message={errorMsg} styles={errorDivStyles} />
      </div>
    </div>
  ) : (
      <NoTemplate />
    );
};

export default ConfirmSend;
