import React from 'react';
import { FormattedMessage } from 'react-intl';

export const AccountPage: React.FC = () => {
  return (
    <div>
      <FormattedMessage id="account.header" />
    </div>
  );
};

export default AccountPage;
