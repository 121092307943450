import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'registration.subevent.productquestion.modal.header',
    defaultMessage: 'Is this a product-question?',
  },
  paragraph: {
    id: 'registration.subevent.productquestion.modal.paragraph',
    defaultMessage:
      'Fill the form, if you want a summary of chosen products at the end of the registration form',
  },
  multiplierquestion: {
    id: 'registration.subevent.productquestion.modal.multiplier',
    defaultMessage: 'Multiplier',
  },
  multiplierdescription: {
    id: 'registration.subevent.productquestion.modal.multiplierlabel',
    defaultMessage:
      "Choose a question which will set the product-count, or choose 'self' if amount is a repeatable-question",
  },
  taxquestion: {
    id: 'registration.subevent.productquestion.modal.tax',
    defaultMessage: 'Tax',
  },
  taxlabel: {
    id: 'registration.subevent.productquestion.modal.taxlabel',
    defaultMessage: 'Tax-rate in percents',
  },
  confirm: {
    id: 'registration.subevent.productquestion.modal.save.button',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'registration.subevent.productquestion.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
  remove: {
    id: 'registration.subevent.productquestion.modal.clear.button',
    defaultMessage: 'Remove',
  },
}
