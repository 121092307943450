import { DefinedMessages } from '../types/types';

export const messages: DefinedMessages = {
  createinvoicingheader: {
    id: 'invoicing.card.create.header',
    defaultMessage: 'Invoicing',
  },
  createinvoicingtext: {
    id: 'invoicing.card.create.description',
    defaultMessage: 'Invoicing',
  },
  allinvoicesheader: {
    id: 'invoicing.card.all.header',
    defaultMessage: 'Invoicing',
  },
  allinvoicestext: {
    id: 'invoicing.card.all.description',
    defaultMessage: 'Invoicing',
  },
  eventsource: {
    id: 'create.invoicing.eventsource',
    defaultMessage: 'Event source',
  },
  event: {
    id: 'create.invoicing.event',
    defaultMessage: 'Event',
  },
  multiple: {
    id: 'create.invoicing.multiple',
    defaultMessage: 'Invoice multiple',
  },
  destination: {
    id: 'create.invoicing.destination',
    defaultMessage: 'Invoice destination',
  },
  receiverstab: {
    id: 'invoicing.tabs.receivers',
    defaultMessage: 'Receivers',
  },
  mappingtab: {
    id: 'invoicing.tabs.mapping',
    defaultMessage: 'Mapping',
  },
  sendingtab: {
    id: 'invoicing.tabs.send',
    defaultMessage: 'Sending',
  },
  prevheader: {
    id: 'preview.invoicing.header',
    defaultMessage: 'Preview',
  },
  prevdescription: {
    id: 'preview.invoicing.description',
    defaultMessage: 'Creating invoices',
  },
  prevrow: {
    id: 'preview.invoicing.row',
    defaultMessage: 'Row',
  },
  prevprev: {
    id: 'preview.invoicing.prev',
    defaultMessage: 'Previous',
  },
  prevnext: {
    id: 'preview.invoicing.next',
    defaultMessage: 'Next',
  },
  prevnoreceivers: {
    id: 'preview.invoicing.noreceivers',
    defaultMessage: 'No receivers',
  },
  errorsmissing: {
    id: 'errors.invoicing.missing',
    defaultMessage: 'Missing required information from mapping',
  },
  errorsok: {
    id: 'errors.invoicing.ok',
    defaultMessage: 'Everything OK',
  },
  errorsdesciption: {
    id: 'errors.invoicing.description',
    defaultMessage: 'Cannot send before above errors are handled',
  },
  sendbutton: {
    id: 'send.invoicing.button',
    defaultMessage: 'Send',
  },
  csvupload: {
    id: 'cert.create.csvupload',
    defaultMessage: 'Drag here or click to upload',
  },
  csvheaders: {
    id: 'cert.create.csvheaders',
    defaultMessage: 'Data has headers',
  },
};
