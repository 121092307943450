export const overlays = {
  cancellation: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_CANCELLATION',
    position: 'left-0 -mt-20',
  },
  modifying: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_MODIFYING',
    position: 'left-0 -mt-20',
  },
  queueing: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUEUING',
    position: '-left-12 -mt-20',
  },
  grouping: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_GROUPING',
    position: '-left-10 -mt-20',
  },
  maxparticipants: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_MAXPARTICIPANTS',
    position: 'left-32',
  },
  questionquestion: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_QUESTION',
    position: '',
  },
  questionid: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_ID',
    position: 'right-1/3',
  },
  questiondescription: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_DESCRIPTION',
    position: 'right-0 mr-8',
  },
  questiontype: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_TYPE',
    position: '',
  },
  questiongroup: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_GROUP',
    position: 'right-1/3',
  },
  questionrequired: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_REQUIRED',
    position: 'right-0 mr-8',
  },
  questiondbkey: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_DBKEY',
    position: 'right-1/2 mr-12',
  },
  questionmailkey: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_MAILKEY',
    position: 'right-1/2 mr-12',
  },
  questionlink: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_LINK',
    position: 'top-1/3 right-0 mr-8',
  },
  questionperson: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_PERSON',
    position: 'left-1/2',
  },
  questionreservation: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_RESERVATION',
    position: 'left-1/3',
  },
  questionrepeatable: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_REPEATABLE',
    position: 'top-1/2 right-2/3',
  },
  questionproduct: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_PRODUCT',
    position: 'top-1/2',
  },
  questionhidden: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_HIDDEN',
    position: 'top-1/2 left-1/2',
  },
  questiondelete: {
    overlayId: 'SUBEVENT_FORM_REGISTRATION_HELP_QUESTION_DELETE',
    position: 'top-1/2 right-0 mr-4',
  },
};
