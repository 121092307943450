import React from "react";
import { FormattedMessage } from "react-intl";
import * as h from './headerTranslations'

interface EventTableHeaderProps {
  headers: string[];
  sort: (header: string) => void;
}

export const EventTableHeader: React.FC<EventTableHeaderProps> = ({
  headers,
  sort,
}) => {
  const styles =
    "bg-blue-100 text-left text-brand-dark_blue px-8 py-4 h-12 cursor-pointer hover:text-brand-blue truncate";


  return (
    <thead>
      <tr className="flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0">
        {headers.map((header) => {
          const headerStr = h.translations[header.toLowerCase()] 
          ? h.translations[header.toLowerCase()] 
          : header
         return (
          <th key={header} className={styles} onClick={() => sort(header)}>
              <FormattedMessage id={headerStr} defaultMessage={header}/>
            </th>
          )}
        )}
      </tr>
    </thead>
  );
};

export default EventTableHeader;
