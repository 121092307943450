import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

export type PieData = {
  label: string;
  value: number;
};
interface Props {
  data: PieData[];
  outerRadius: number;
  innerRadius: number;
}
export const PieChart: React.FC<Props> = ({
  data,
  outerRadius,
  innerRadius,
}) => {
  const margin = {
    top: 50,
    right: 50,
    bottom: 50,
    left: 50,
  };

  const width = 2 * outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;
  const colorScale = d3
    .scaleSequential()
    .interpolator(d3.interpolateCool)
    .domain([0, data.length]);

  const drawChart = () => {
    // Remove the old svg
    d3.select('#pie-chart').select('svg').remove();

    // Create new svg
    const svg = d3
      .select('#pie-chart')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const arcGenerator = d3
      .arc<PieArcDatum<PieData>>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const pieGenerator = d3
      .pie<PieData>()
      .padAngle(0)
      .value((d: PieData) => d.value);

    const arc = svg.selectAll().data(pieGenerator(data)).enter();

    // Append arcs
    arc
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (_: any, i: number) => colorScale(i))
      .style('stroke', '#ffffff')
      .style('stroke-width', 2);

    const label = d3
      .arc<PieArcDatum<PieData>>()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius);
    const text = arc
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text((d) => d.data.label)
      .style('fill', '#ffffff');
    text.attr('transform', (d) => {
      const [x, y] = label.centroid(d);
      return `translate(${x}, ${y})`;
    });
    const labelsData = [];
    text.each((d, i, texts) => {
      labelsData.push({
        el: texts[i],
        centroid: label.centroid(d),
        startAngle: d.startAngle,
        endAngle: d.endAngle,
      });
    });
  };

  useEffect(() => {
    drawChart();
  }, [data]); //eslint-disable-line

  return <div className="z-10" id="pie-chart" />;
};
