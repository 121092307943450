import React from 'react';
import { FormattedMessage } from 'react-intl';

interface FilterModalProps {
  filterDivContainer: string;
  filterPillDiv: string;
  filterPill: string;
  filterButtonDivStyle: string;
  filterDivToggleId: string;
  filterDivButtonStyle: string;
  sortKeys: string[];
  activeFilters: string[];
  translations?: { [key: string]: string };
  toggleFilter: (key: string) => void;
  toggle: (id: string) => void;
}

export const FilterModal: React.FC<FilterModalProps> = ({
  filterDivContainer,
  filterPillDiv,
  sortKeys,
  activeFilters,
  filterPill,
  filterButtonDivStyle,
  filterDivButtonStyle,
  filterDivToggleId,
  translations,
  toggleFilter,
  toggle,
}) => {
  return (
    <div className={filterDivContainer}>
      <div className={filterPillDiv}>
        {sortKeys.map((key) => {
          const bgColor = activeFilters.includes(key)
            ? 'bg-blue-500'
            : 'bg-blue-300 hover:bg-blue-500';
          const translation =
            translations && translations[key.toLowerCase()] ? true : false;
          return (
            <button
              key={key}
              className={`${filterPill} ${bgColor}`}
              onClick={() => toggleFilter(key)}
            >
              {translation ? (
                <FormattedMessage
                  id={translations[key.toLowerCase()]}
                  defaultMessage={key}
                />
              ) : (
                <span>{key}</span>
              )}
            </button>
          );
        })}
      </div>
      <div className={filterButtonDivStyle}>
        <button
          className={filterDivButtonStyle}
          onClick={() => toggle(filterDivToggleId)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default FilterModal;
