import React from "react";
import { Link } from "react-router-dom";

export interface BreadProps {
  label: string;
  link: string;
  lastChild?: boolean;
}

export const BreadCrumb: React.FC<BreadProps> = ({
  label,
  link,
  lastChild,
}) => {
  const crumbStr = lastChild ? label : `${label} >`;
  const styles = lastChild
    ? "mx-1 text-brand-dark_blue font-semibold"
    : "mx-1 text-brand-dark_blue";

  return (
    <li className={styles}>
      <Link to={link}>{crumbStr}</Link>
    </li>
  );
};

export default BreadCrumb;
