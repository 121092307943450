import React from 'react';
import {FormattedMessage} from 'react-intl';
import {messages} from '../translations/linkTranslations'

interface Props {
  linkPrefix: string,
  link: string,
}

export const LinkDetails: React.FC<Props> = ({linkPrefix, link}) => {
  return (
    <div className="flex flex-col w-full h-1/3 border overflow-auto scroller shadow-lg">
      <div className="m-auto justify-center">
        <span className="text-3xl text-brand-dark_blue">
          <FormattedMessage
            {...messages.linkprefix}
            values={{prefix: linkPrefix}}
          />
        </span>
        {link && (
          <div className="text-brand-dark_blue p-2 text-xl">
            <FormattedMessage
              {...messages.linkpublished}
              values={{
                a: (...chunks: string[]) => (
                  <b>
                    <a
                      href={`${link}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  </b>
                ),
                link: link,
                emoji: '🎉',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
