import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import { AppState } from '../../reducers/combineReducer';
import { selectToggleStateMultiple } from '../../reducers/toggleReducer';

interface Props {
  modalIds: string[];
  growIndex?: boolean;
}

export const BackDrop: React.FC<Props> = ({ modalIds, growIndex }) => {
  const toggle = useSelector((state: AppState) =>
    selectToggleStateMultiple(state, modalIds)
  );
  const dispatch = useDispatch();

  if (!toggle) {
    return null;
  }

  const zIndex = growIndex ? 'z-30' : 'z-10';

  const toggleModal = (id: string) => {
    dispatch(allActions.toggleActions.toggle(id));
  };

  return (
    <div
      className={`absolute inset-0 bg-gray-700 opacity-80 ${zIndex}`}
      onClick={() => toggleModal(toggle)}
    />
  );
};
