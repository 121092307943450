import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../actions/allActions';
import { FormSelect } from '../components/form/FormSelect';
import * as cState from './certReducer';
import { TemplateCard } from './components/TemplateCard';
import { PostmarkCard } from './components/PostmarkCard';
import Tab from '../components/tabs/Tab';
import { Tabs } from '../components/tabs/Tabs';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfirmSend } from './components/ConfirmSend';
import Loading from '../components/utils/Loading';
import CertParticipantTable from './certParticipantTable/CertParticipantTable';
import { Switch } from '../components/utils/Switch';
import { CSVReader } from 'react-papaparse';
import { ImportCSVData } from '../types/types';
import ConsoleHelper from '../utils/logger';
import BreadCrumb from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import storeService from '../services/storage';
import * as uState from '../reducers/userReducer';
import { messages } from './translations/newcert';
import { Crop } from 'react-image-crop';
import { selectLoadingMultiple } from '../reducers/loadingReducer';
import { AppState } from '../reducers/combineReducer';
import { csvReaderStyles } from '../components/utils/csvReaderStyles';

export const CertPage: React.FC = () => {
  //TODO: getLyytikey to errorReducer!
  //TODO: getLyytievents to errorReducer
  //TODO: getallLyytiParticipants to errorReducer!

  const dispatch = useDispatch();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState(null);
  const [importHeaders, setImportHeaders] = useState(true);
  const {
    eventSourceOptions,
    certTemplateOptions,
    selectedSource,
    lyytiEvents,
    events,
    selectedEvent,
    participants,
    participantHeaders,
    sendMultiple,
    selectedTemplate,
  } = useSelector(cState.selectCertPageData);
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'createCert',
      'getLyytikey',
      'getEvents',
      'getAllLyytiParticipants',
      'getAllLyytiEvents',
      'fileUpload',
    ])
  );
  const { userId } = useSelector(uState.selectUserData);
  const templateOptions = [''].concat(certTemplateOptions);
  const formName = 'newCert';
  const eventOptions =
    selectedSource.toLowerCase() === 'contactmate'
      ? [''].concat(events.map((e) => e.name))
      : [''].concat(lyytiEvents.map((l) => l.name));
  const { formatMessage, locale } = useIntl();
  const certTab = formatMessage({ ...messages.cert });
  const mailTab = formatMessage({ ...messages.mail });
  const confirmTab = formatMessage({ ...messages.send });
  const participantTab = formatMessage({ ...messages.participants });
  const selectEventsQuestion = formatMessage({ ...messages.selectevents });
  const multiSendSwitch = formatMessage({ ...messages.multisend });
  const tableName = `newcert_${selectedEvent}_participants_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : participantHeaders;
  const crumbs = [
    { label: 'certmenu', link: '/certmenu' },
    { label: 'create', link: '/createcert' },
  ];

  const changeTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(allActions.certActions.changeSelectedTemplate(e.target.value));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    dispatch(allActions.formActions.updateValue(id, e.target.value, formName));
  };

  const changeEventSource = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(allActions.certActions.changeEventSource(e.target.value));
  };

  const changeEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== '') {
      const event =
        selectedSource.toLowerCase() === 'lyyti'
          ? lyytiEvents.find((event) => event.name === e.target.value)
          : events.find((event) => event.name === e.target.value);
      return dispatch(
        allActions.certActions.changeEvent(
          event.id.toString(),
          selectedSource,
          event.name
        )
      );
    }
  };

  const upload = (
    canvas: HTMLCanvasElement,
    crop: Crop,
    id: string,
    toggleId: string
  ) => {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) =>
        dispatch(
          allActions.formActions.fileUploadToForm(formName, blob, toggleId, id)
        ),
      'image/png',
      1
    );
  };

  const changeSendType = (id: string) => {
    dispatch(allActions.certActions.changeSendType());
  };

  const getActiveTab = useCallback(() => {
    const pathArray = location.pathname.split('/');
    return pathArray.length === 2
      ? setActiveTab(certTab)
      : setActiveTab(pathArray[pathArray.length - 1]);
  }, [location, certTab]);

  const getCertNew = useCallback(() => {
    dispatch(allActions.certActions.startCertNewFetch());
    dispatch(allActions.certActions.getLyytikey());
  }, [dispatch]);

  const getAllEvents = useCallback(
    (source: string) => {
      if (source.toLowerCase() === 'lyyti') {
        dispatch(allActions.eventActions.getAllLyytiEvents(locale));
      }
      if (source.toLowerCase() === 'contactmate')
        dispatch(
          allActions.eventActions.getAllEvents({ date: '0', limit: '100' })
        );
    },
    [dispatch, locale]
  );

  const handleOnDrop = (data: ImportCSVData[]) => {
    dispatch(allActions.certActions.importParticipants(data, importHeaders));
  };

  const handleOnError = (err: Error) => {
    ConsoleHelper(err);
  };

  useEffect(() => {
    getAllEvents(selectedSource);
  }, [selectedSource, getAllEvents]);

  useEffect(() => {
    getCertNew();
  }, [getCertNew]);

  useEffect(() => {
    getActiveTab();
  }, [getActiveTab]);

  return (
    <div className="w-11/12 h-full mx-auto">
      <BreadRoute>
        {crumbs.map((c, i) => {
          const last = i + 1 === crumbs.length;
          return (
            <BreadCrumb
              key={c.link}
              label={c.label}
              link={c.link}
              lastChild={last}
            />
          );
        })}
      </BreadRoute>
      <div className="flex">
        <FormSelect
          id="eventSource"
          question={formatMessage({ ...messages.eventsource })}
          handleInputChange={changeEventSource}
          options={eventSourceOptions}
          value={selectedSource}
        />
        {selectedSource === 'CSV-import' ? (
          <>
            <Switch
              id="dataHasHeaders"
              header={formatMessage({ ...messages.csvheaders })}
              show={importHeaders}
              labelStyle={'text-sm italic text-brand-dark_blue'}
              divStyle={'mt-8'}
              switchAction={() => setImportHeaders(!importHeaders)}
            />
            <div className="mt-6 m-2">
              <CSVReader
                style={csvReaderStyles}
                onDrop={handleOnDrop}
                onError={handleOnError}
              >
                <span className="italic text-sm text-brand-dark_blue">
                  <FormattedMessage {...messages.csvupload} />
                </span>
              </CSVReader>
            </div>
          </>
        ) : selectedSource !== '' ? (
          <>
            <FormSelect
              id="eventCert"
              question={selectEventsQuestion}
              handleInputChange={changeEvent}
              options={eventOptions}
              value={selectedEvent}
            />
            <Switch
              id="certSendType"
              header={multiSendSwitch}
              show={sendMultiple}
              switchAction={changeSendType}
            />
          </>
        ) : null}
        <FormSelect
          id="newCertTemplateName"
          question={formatMessage({ ...messages.selecttemplate })}
          handleInputChange={changeTemplate}
          options={templateOptions}
          value={selectedTemplate}
        />
      </div>
      <Tabs>
        <Tab
          activeLabel={activeTab}
          label={participantTab}
          link={`${url}/${participantTab}`}
          hidden={participants.length === 0 || !sendMultiple}
        />
        <Tab activeLabel={activeTab} label={certTab} link={`${url}`} />
        <Tab
          activeLabel={activeTab}
          label={mailTab}
          link={`${url}/${mailTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={confirmTab}
          link={`${url}/${confirmTab}`}
        />
      </Tabs>
      <div className="h-full max-h-3/4 w-full flex justify-center items-center border-r border-l border-b border-gray-400 overflow-auto scroller">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Route
              path={`${path}/${participantTab}`}
              render={() => (
                <CertParticipantTable
                  list={participants}
                  headerRow={headers}
                  tableName={tableName}
                />
              )}
            />
            <Route
              exact
              path={path}
              render={() => (
                <TemplateCard
                  upload={upload}
                  templateName={selectedTemplate}
                  handleInputChange={handleInputChange}
                />
              )}
            />
            <Route
              path={`${path}/${mailTab}`}
              render={() => (
                <PostmarkCard
                  upload={upload}
                  templateName={selectedTemplate}
                  handleInputChange={handleInputChange}
                />
              )}
            />
            <Route
              path={`${path}/${confirmTab}`}
              render={() => <ConfirmSend templateName={selectedTemplate} />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CertPage;
