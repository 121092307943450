import axios from 'axios';
import ConsoleHelper from '../utils/logger';
import storeService from './storage';
import * as config from './config';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((x) => {
  ConsoleHelper(x);
  return x;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response.status === 409 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq.__isRetryRequest = true;
        const Jwt = storeService.getString('jwt');
        const Re = storeService.getString('re')
        let res = fetch(`${config.baseUrl}/auth/refresh`, {
          method: 'POST',
          headers: {Jwt, Re},
          mode: 'cors',
          credentials: 'include',
          body: JSON.stringify({})
        })
          .then((res) => res.json())
          .then((resJson) => {
            storeService.storeString('jwt', resJson.jwt);
            const newRequest =
            {
              ...err.config,
              headers: {
                Accept: 'application/json',
                Jwt: resJson.jwt
              }
            }
            return axios(newRequest);
          });
        resolve(res);
      }
      return reject(err);
    });
  }
);
