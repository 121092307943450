import React from 'react';
import { HelpIcon } from '../../../../components/form/labels/HelpIcon';
import { HoverAttributes } from '../../../../components/utils/HoverMessage';
import { IconWrapper } from '../../../../components/utils/IconWrapper';

interface Props {
  overlayProps: HoverAttributes;
}
export const QuestionIconButton: React.FC<Props> = ({
  overlayProps,
  children,
}) => {
  const toggleOverlay = () => {
    overlayProps.overlayToggle(overlayProps.overlayId);
  };

  const iconStyle =
    'text-brand-white hover:bg-brand-dark_blue cursor-pointer m-2 p-2 bg-brand-blue rounded-full';
  return (
    <div className="flex justify-evenly">
      <IconWrapper styles={iconStyle}>{children}</IconWrapper>
      <HelpIcon onClick={toggleOverlay} overlayId={overlayProps.overlayId} />
    </div>
  );
};
