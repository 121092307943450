import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../types/types';

type Props = {
  onClick: () => void;
  message: FormattedMessageProps;
};

export const NavItemWithButton: React.FC<Props> = ({
  onClick,
  message,
  children,
}) => {
  const styles =
    'flex text-lg lg:mt-0 text-white hover:text-blue-300 mr-4 mt-4';
  return (
    <div className="w-full ml-4">
      <button className={styles} onClick={onClick}>
        {children}
        <span>
          <FormattedMessage {...message} />
        </span>
      </button>
    </div>
  );
};
