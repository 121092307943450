import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import allActions from '../actions/allActions';
import * as cState from './certReducer';
import {TemplateCard} from './components/TemplateCard';
import {PostmarkCard} from './components/PostmarkCard';
import Tab from '../components/tabs/Tab';
import {Tabs} from '../components/tabs/Tabs';
import {Route, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {ConfirmSend} from './components/ConfirmSend';
import Loading from '../components/utils/Loading';
import {useFormatMessage} from '../utils/functions';
import CertParticipantTable from './certParticipantTable/CertParticipantTable';
import BreadCrumb from '../components/breadcrumbs/BreadCrumb';
import {BreadRoute} from '../components/breadcrumbs/BreadRoute';
import {CertParamTypes} from '../types/types';
import * as uState from '../reducers/userReducer';
import storeService from '../services/storage';
import {Crop} from 'react-image-crop';
import certService from '../services/certs';
import ConsoleHelper from '../utils/logger';
import {AppState} from '../reducers/combineReducer';
import {selectLoadingMultiple} from '../reducers/loadingReducer';

export const EditCert: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {path, url} = useRouteMatch();
  const [activeTab, setActiveTab] = useState(null);
  const {certOperationId, mailId} = useParams<CertParamTypes>();
  const {
    participants,
    selectedTemplate,
    selectedEvent,
    participantHeaders,
  } = useSelector(cState.selectCertPageData);
  const isLoading = useSelector((state: AppState) => selectLoadingMultiple(state, ['getCertNew', 'createCert']))
  const {userId} = useSelector(uState.selectUserData);
  const certTab = useFormatMessage('cert.tabs.cert');
  const mailTab = useFormatMessage('cert.tabs.mail');
  const confirmTab = useFormatMessage('cert.tabs.send');
  const participantTab = useFormatMessage('cert.tabs.participants');
  const [headers, setHeaders] = useState(participantHeaders);
  const tableName = `newcert_${selectedEvent}_participants_${userId}`;
  const crumbs = [
    {label: 'certmenu', link: '/certmenu'},
    {label: 'certs', link: '/certs'},
    {label: `cert ${certOperationId}`, link: `/certs/${certOperationId}`},
    {label: `mail ${mailId}`, link: `/certs/${certOperationId}/${mailId}`},
  ];

  const handleInputChange = (e: any, id: string) => {
    dispatch(allActions.formActions.updateValue(id, e.target.value, 'newCert'));
  };

  const upload = (
    canvas: HTMLCanvasElement,
    crop: Crop,
    id: string,
    toggleId: string
  ) => {
    if (!crop || !canvas) {
      return;
    }
    try {
      canvas.toBlob(
        (blob) => {
          certService.fileUpload(blob);
        },
        'image/png',
        1
      );
    } catch (error) {
      ConsoleHelper('error');
    }
  };

  const getActiveTab = useCallback(() => {
    const pathArray = location.pathname.split('/');
    return pathArray.length === 5
      ? setActiveTab(certTab)
      : setActiveTab(pathArray[pathArray.length - 1]);
  }, [location, certTab]);

  useEffect(() => {
    getActiveTab();
  }, [getActiveTab]);

  useEffect(() => {
    const savedHeaders = storeService.getHeaders(tableName);
    if (savedHeaders.length > 0) {
      setHeaders(savedHeaders);
    }
  }, [participantHeaders]); //eslint-disable-line

  return (
    <div className="container w-screen h-full">
      <BreadRoute>
        {crumbs.map((c, i) => {
          const last = i + 1 === crumbs.length;
          return (
            <BreadCrumb
              key={c.link}
              label={c.label}
              link={c.link}
              lastChild={last}
            />
          );
        })}
      </BreadRoute>
      <Tabs>
        <Tab activeLabel={activeTab} label={certTab} link={`${url}`} />
        <Tab
          activeLabel={activeTab}
          label={mailTab}
          link={`${url}/${mailTab}`}
        />
        <Tab
          activeLabel={activeTab}
          label={confirmTab}
          link={`${url}/${confirmTab}`}
        />
      </Tabs>
      <div className="container h-full max-h-11/12 w-full flex justify-center items-center border-r border-l border-b border-gray-400 overflow-auto scroller">
        {isLoading ? (
          <Loading />
        ) : (
            <>
              <Route
                path={`${path}/${participantTab}`}
                render={() => (
                  <CertParticipantTable
                    list={participants}
                    headerRow={headers}
                    tableName={tableName}
                  />
                )}
              />
              <Route
                exact
                path={path}
                render={() => (
                  <TemplateCard
                    upload={upload}
                    templateName={selectedTemplate}
                    handleInputChange={handleInputChange}
                  />
                )}
              />
              <Route
                path={`${path}/${mailTab}`}
                render={() => (
                  <PostmarkCard
                    upload={upload}
                    templateName={selectedTemplate}
                    handleInputChange={handleInputChange}
                  />
                )}
              />
              <Route
                path={`${path}/${confirmTab}`}
                render={() => <ConfirmSend templateName={selectedTemplate} />}
              />
            </>
          )}
      </div>
    </div>
  );
};

export default EditCert;
