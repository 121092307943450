import React from "react";
import { TableData } from "../../components/utils/TableData";
import { Cert } from "../../types/types";

interface RowProps {
  cert: Cert;
  headerKeys: string[];
  gotoCert: (id: string) => void;
}

export const CertTableRow: React.FC<RowProps> = ({
  cert,
  headerKeys,
  gotoCert,
}) => {
  const hasErrors = cert.mail.find((c) => c.mailOperationResult !== "OK")
    ? "text-yellow-500"
    : "";
  const styles = "border cursor-pointer px-8 py-4 h-12 truncate";
  const rowStyles = `hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0 ${hasErrors}`;

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key) => {
          const value = cert[key] ? cert[key] : "";
          const valueStr = value.toString();
          return (
            <TableData
              key={key}
              value={valueStr}
              open={() => gotoCert(cert.certOperationId)}
              styles={styles}
            />
          );
        })}
      </tr>
    </>
  );
};

export default CertTableRow;
