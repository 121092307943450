import React, { useState } from 'react';
import { FormInputInfo } from '../../../../types/types';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { DroppableBasket } from '../draggable/DroppableBasket';
import { Toggle } from '../../../../components/utils/Toggle';
import allActions from '../../../../actions/allActions';
import { useDispatch, useSelector } from 'react-redux';
import { AlertDialog } from '../../../../components/modals/AlertDialog';
import { QuestionModal } from './QuestionModal';
import { ProductModal } from './ProductModal';
import { HideModal } from './HideModal';
import { DependencyModal } from './DependencyModal';
import {
  getArrays,
  getEmptyFormInputObject,
} from '../../../../utils/functions';
import { arrayToObject, concatNarrays } from '../../../../utils/functions';
import * as rState from '../../../eventReducer';
import * as tState from '../../../../reducers/toggleReducer';
import * as t from '../../translations/createRegistrationTranslations';
import { BackDrop } from '../../../../components/tableSearchBar/BackDrop';
import { AppState } from '../../../../reducers/combineReducer';

interface FormProps {
  message?: string;
  previewFormName: string;
}

export const CreateRegistrationQuestions: React.FC<FormProps> = ({
  previewFormName,
}) => {
  const toggleState = useSelector(tState.getToggleState);
  const {
    state,
    dbKeys,
    options,
    amountOptions,
    itemsTable,
    mailKeys,
  } = useSelector(rState.getRegistrationFormItems);
  const [item, setItem] = useState<FormInputInfo>(getEmptyFormInputObject());
  const [basket, setBasket] = useState<string>('');
  const alertDialogStyles =
    'absolute right-0 top-1/2 z-50 mr-64 text-brand-dark_blue text-center bg-yellow-200 border border-gray-200 rounded w-2/12 shadow-xl';
  const dependencyModalStyles =
    'absolute mt-6 z-50 text-center bg-blue-200 rounded w-1/3 shadow-xl';
  const basketDialogStyles =
    'absolute top-0 right-0 z-50 mr-64 text-center text-brand-dark_blue bg-yellow-200 rounded w-2/12 shadow-xl';
  const modalToggle = 'NEWITEM_TOGGLE';
  const deleteQuestionToggle = 'FORM_MODAL_ALERT_TOGGLE';
  const deleteBasketToggle = 'DELETE_BASKET_ALERT_TOGGLE';
  const dependencyToggle = 'FORM_MODAL_DEPENDENCY_TOGGLE';
  const productToggle = 'FORM_MODAL_PRODUCT_TOGGLE';
  const hideToggle = 'FORM_MODAL_HIDE_TOGGLE';
  const dispatch = useDispatch();
  const amountOfModalsOpen = useSelector((state: AppState) =>
    tState.selectAmountOfOpenModals(state, [
      modalToggle,
      deleteQuestionToggle,
      dependencyToggle,
      productToggle,
      hideToggle,
    ])
  );

  const onDragEnd = (dragObject: DropResult) => {
    const { destination, source, draggableId } = dragObject;
    if (!destination) {
      return;
    }
    const startBasket = source.droppableId;
    const destinationBasket = destination.droppableId;
    if (startBasket === destinationBasket) {
      if (source.index !== destination.index) {
        const copyOfItems: string[] = [
          ...state.baskets[destinationBasket].questionIds,
        ];
        const newArr = copyOfItems.filter((item) => item !== draggableId);
        newArr.splice(destination.index, 0, draggableId);
        const newColumn = {
          ...state.baskets[destinationBasket],
          questionIds: newArr,
        };
        const newState = {
          ...state,
          baskets: {
            ...state.baskets,
            [destinationBasket]: newColumn,
          },
        };
        const allBaskets = Object.keys(newState.baskets);
        const previewList = concatNarrays(
          getArrays(allBaskets, newState.baskets)
        ).map((id) => itemsTable[id]);
        dispatch(allActions.eventActions.setRegistrationQuestions(newState));
        dispatch(allActions.formActions.setForm(previewFormName, previewList));
      }
    } else {
      const startBasketQuestionIds = [
        ...state.baskets[startBasket].questionIds,
      ];
      const newStartQuestions = startBasketQuestionIds.filter(
        (item) => item !== draggableId
      );
      const newStart = {
        ...state.baskets[startBasket],
        questionIds: newStartQuestions,
      };
      const destBasketQuestionIds = [
        ...state.baskets[destinationBasket].questionIds,
      ];
      destBasketQuestionIds.splice(destination.index, 0, draggableId);
      const newDest = {
        ...state.baskets[destinationBasket],
        questionIds: destBasketQuestionIds,
      };
      const newItems = [...state.items].map((item) => {
        if (item.id === draggableId) {
          return { ...item, page: Number(destination.droppableId) };
        } else {
          return item;
        }
      });
      const newState = {
        items: newItems,
        baskets: {
          ...state.baskets,
          [startBasket]: newStart,
          [destinationBasket]: newDest,
        },
      };
      const newItemsTable = arrayToObject(newItems, 'id');
      const allBaskets = Object.keys(newState.baskets);
      const previewList = concatNarrays(
        getArrays(allBaskets, newState.baskets)
      ).map((id) => newItemsTable[id]);
      dispatch(allActions.eventActions.setRegistrationQuestions(newState));
      dispatch(allActions.formActions.setForm(previewFormName, previewList));
    }
  };

  const toggleElement = (id: string, basketId?: string) => {
    if (toggleState[id]) {
      dispatch(allActions.toggleActions.hideToggle(id));
    } else {
      if (basketId !== undefined) {
        setBasket(basketId);
      }
      dispatch(allActions.toggleActions.showToggle(id));
    }
  };

  const save = (obj: FormInputInfo, basket: string) => {
    const copyOfState = { ...item };
    const id = obj.id === '' ? obj.question.replace(/\s/g, '') : obj.id;
    const page = Number.isInteger(Number(basket)) ? Number(basket) : 1;
    toggleElement(modalToggle);
    setItem({ id: '', question: '', type: '', show: false, group: 1, page: 1 });
    if (itemsTable[id]) {
      const updatedArr = [...state.items].map((item) => {
        if (item.id === id) {
          let updatedObject: FormInputInfo = {
            id,
            question: obj.question,
            type: obj.type,
            show: true,
            group: obj.group,
            page,
            ...(obj.options.length > 0 && { options: obj.options }),
            ...(obj.required.type !== '' && { required: obj.required }),
            ...(obj.link !== '' && { link: obj.link }),
            ...(obj.description !== '' && { description: obj.description }),
            ...((copyOfState.hide ? copyOfState.hide.key !== '' : false) && {
              hide: copyOfState.hide,
            }),
            ...((copyOfState.product
              ? copyOfState.product.multiplier !== ''
              : false) && { product: copyOfState.product }),
            ...((copyOfState.repeatable
              ? copyOfState.repeatable.key !== ''
              : false) && { repeatable: copyOfState.repeatable }),
            ...(obj.reservation && { reservation: obj.reservation }),
            ...(obj.person && { person: obj.person }),
            ...(obj.mailKeys &&
              obj.mailKeys.key !== '' && { mailKeys: obj.mailKeys }),
            ...(obj.dbKeys && obj.dbKeys.key !== '' && { dbKeys: obj.dbKeys }),
          };
          return updatedObject;
        } else {
          return item;
        }
      });
      const newState = {
        ...state,
        items: updatedArr,
      };
      const updatedItems = arrayToObject(updatedArr, 'id');
      const allBaskets = Object.keys(newState.baskets);
      const previewList = concatNarrays(
        getArrays(allBaskets, newState.baskets)
      ).map((id) => updatedItems[id]);
      dispatch(allActions.eventActions.setRegistrationQuestions(newState));
      dispatch(allActions.formActions.setForm(previewFormName, previewList));
    } else {
      let newObject: FormInputInfo = {
        id,
        question: obj.question,
        type: obj.type,
        show: true,
        group: obj.group,
        page,
        ...(obj.options.length > 0 &&
          obj.options[0].length > 0 && { options: obj.options }),
        ...(obj.required.type !== '' && { required: obj.required }),
        ...(obj.link !== '' && { link: obj.link }),
        ...(obj.description !== '' && { description: obj.description }),
        ...((copyOfState.hide ? copyOfState.hide.key !== '' : false) && {
          hide: copyOfState.hide,
        }),
        ...((copyOfState.product
          ? copyOfState.product.multiplier !== ''
          : false) && { product: copyOfState.product }),
        ...((copyOfState.repeatable
          ? copyOfState.repeatable.key !== ''
          : false) && { repeatable: copyOfState.repeatable }),
        ...(obj.reservation && { reservation: obj.reservation }),
        ...(obj.person && { person: obj.person }),
        ...(obj.dbKeys && obj.dbKeys.key !== '' && { dbKeys: obj.dbKeys }),
        ...(obj.mailKeys &&
          obj.mailKeys.key !== '' && { mailKeys: obj.mailKeys }),
      };
      const newArr = [...state.items].concat(newObject);
      const destBasketQuestionIds = [...state.baskets[basket].questionIds];
      const added = destBasketQuestionIds.concat(id);
      const newDest = {
        ...state.baskets[basket],
        questionIds: added,
      };
      const newState = {
        ...state,
        items: newArr,
        baskets: {
          ...state.baskets,
          [basket]: newDest,
        },
      };
      const updatedItems = arrayToObject(newArr, 'id');
      const allBaskets = Object.keys(newState.baskets);
      const previewList = concatNarrays(
        getArrays(allBaskets, newState.baskets)
      ).map((id) => updatedItems[id]);
      dispatch(allActions.eventActions.setRegistrationQuestions(newState));
      dispatch(allActions.formActions.setForm(previewFormName, previewList));
    }
  };

  const deleteBasket = (id: string) => {
    const copyOfBaskets = { ...state.baskets };
    const { [id]: string, ...removed } = copyOfBaskets;
    const updatedBasketsArr = Object.keys(removed).map((item, i) => {
      return {
        ...removed[item],
        id: (i + 1).toString(),
        title: (i + 1).toString(),
      };
    });
    const updatedBaskets = arrayToObject(updatedBasketsArr, 'id');
    const newState = {
      ...state,
      baskets: updatedBaskets,
    };
    dispatch(allActions.eventActions.setRegistrationQuestions(newState));
    toggleElement(deleteBasketToggle);
  };

  const addBasket = (id: string) => {
    const copyOfBaskets = { ...state.baskets };
    const shiftedBasket = { ...state.baskets[id] };
    const newId = (Number(shiftedBasket.id) + 1).toString();
    const { [id]: string, ...removed } = copyOfBaskets;
    const updated = {
      ...removed,
      [id]: {
        title: id,
        id,
        questionIds: [],
      },
      [newId]: {
        ...shiftedBasket,
        id: newId,
        title: newId,
      },
    };
    const newState = {
      ...state,
      baskets: updated,
    };
    dispatch(allActions.eventActions.setRegistrationQuestions(newState));
  };

  const saveOptions = (item: FormInputInfo, toggleId: string) => {
    setItem(item);
    toggleElement(toggleId);
  };

  const cancel = () => {
    toggleElement(modalToggle);
    setItem(getEmptyFormInputObject());
  };

  const openDeleteBasketDialog = (id: string) => {
    setBasket(id);
    toggleElement(deleteBasketToggle);
  };

  const openModalWithObject = (id: string, basketId: string) => {
    setBasket(basketId);
    setItem(itemsTable[id]);
    toggleElement(modalToggle);
  };

  const openModalWithItemId = (alertId: string) => {
    toggleElement(alertId);
  };

  const deleteItem = (id: string) => {
    const itemQuestionsWithoutId = [...state.items].filter((q) => q.id !== id);
    const basketsQuestionIdsWithoutId = [
      ...state.baskets[basket].questionIds.filter((q) => q !== id),
    ];
    const newDest = {
      ...state.baskets[basket],
      questionIds: basketsQuestionIdsWithoutId,
    };
    const newState = {
      ...state,
      items: itemQuestionsWithoutId,
      baskets: {
        ...state.baskets,
        [basket]: newDest,
      },
    };
    toggleElement(deleteQuestionToggle);
    toggleElement(modalToggle);
    dispatch(allActions.eventActions.setRegistrationQuestions(newState));
    dispatch(
      allActions.formActions.setForm(previewFormName, itemQuestionsWithoutId)
    );
    setItem(getEmptyFormInputObject());
  };

  return (
    <>
      <BackDrop
        modalIds={[
          deleteBasketToggle,
          dependencyToggle,
          productToggle,
          hideToggle,
          deleteQuestionToggle,
          modalToggle,
        ]}
        growIndex={amountOfModalsOpen > 1}
      />
      <div className="flex flex-col">
        <div className="flex relative">
          <DragDropContext onDragEnd={onDragEnd}>
            {Object.keys(state.baskets).map((colId) => {
              const basketQuestions = state.baskets[colId].questionIds;
              const basketList = basketQuestions.map(
                (questionId) => itemsTable[questionId]
              );
              const last =
                colId ===
                Object.keys(state.baskets)
                  .map((b) => b)
                  .length.toString()
                  ? true
                  : false;
              return (
                <DroppableBasket
                  key={state.baskets[colId].id}
                  id={state.baskets[colId].id}
                  title={state.baskets[colId].title}
                  questions={basketList}
                  handleClick={openModalWithObject}
                  toggleModal={toggleElement}
                  toggleId={modalToggle}
                  deleteBasket={openDeleteBasketDialog}
                  newBasket={addBasket}
                  last={last}
                />
              );
            })}
          </DragDropContext>
          <Toggle id={modalToggle}>
            <QuestionModal
              questionIds={Object.keys(itemsTable).filter(
                (id) => id !== item.id
              )}
              modalAction={save}
              modalCancel={cancel}
              modalId={modalToggle}
              modalOpen={openModalWithItemId}
              dbKeys={dbKeys}
              mailKeys={mailKeys}
              item={item}
              basket={basket}
              alertId={deleteQuestionToggle}
              dependencyId={dependencyToggle}
              productId={productToggle}
              hideId={hideToggle}
            />
          </Toggle>
          <Toggle id={deleteQuestionToggle}>
            <AlertDialog
              message={{ ...t.messages.delete }}
              itemId={item.id}
              alertId={deleteQuestionToggle}
              styles={alertDialogStyles}
              alertAction={deleteItem}
              alertCancel={toggleElement}
              type="warning"
            />
          </Toggle>
          <Toggle id={deleteBasketToggle}>
            <AlertDialog
              message={{ ...t.messages.delete }}
              itemId={basket}
              alertId={deleteBasketToggle}
              styles={basketDialogStyles}
              alertAction={deleteBasket}
              alertCancel={toggleElement}
              type="warning"
            />
          </Toggle>
          <Toggle id={dependencyToggle}>
            <DependencyModal
              item={item}
              options={options}
              amountOptions={amountOptions}
              modalId={dependencyToggle}
              styles={dependencyModalStyles}
              modalAction={saveOptions}
              modalCancel={toggleElement}
            />
          </Toggle>
          <Toggle id={productToggle}>
            <ProductModal
              item={item}
              options={amountOptions}
              modalId={productToggle}
              styles={dependencyModalStyles}
              modalAction={saveOptions}
              modalCancel={toggleElement}
            />
          </Toggle>
          <Toggle id={hideToggle}>
            <HideModal
              item={item}
              options={options}
              modalId={hideToggle}
              styles={dependencyModalStyles}
              modalAction={saveOptions}
              modalCancel={toggleElement}
            />
          </Toggle>
        </div>
      </div>
    </>
  );
};

export default CreateRegistrationQuestions;
