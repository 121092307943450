import React from "react";

interface ErrorFetchingProps {
  message: string;
  styles?: string;
  textStyle?: string;
}

export const ErrorFetching: React.FC<ErrorFetchingProps> = ({
  message,
  styles,
  textStyle,
}) => {
  const style = styles ? styles : "text-center";
  const text = textStyle ? textStyle : "text-red-500";

  if (!message) {
    return null;
  }

  return (
    <div className={style}>
      <span className={text}>{message}</span>
    </div>
  );
};

export default ErrorFetching;
