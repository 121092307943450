import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../../types/types';

interface AlertDialogProps {
  message: FormattedMessageProps;
  styles?: string;
  itemId?: string;
  type?: 'warning';
  alertId: string;
  alertAction: (itemId?: string) => void;
  alertCancel: (alertId?: string) => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  message,
  styles,
  alertId,
  itemId,
  alertAction,
  alertCancel,
  type,
}) => {
  const style = styles !== undefined ? styles : 'text-center';
  const confirmButtonStyle =
    type === 'warning'
      ? 'bg-red-300 hover:bg-red-400 text-brand-white m-4 rounded w-20 h-10 shadow-lg'
      : 'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 shadow-lg';

  const cancelButtonStyle =
    'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 shadow-lg';

  const lineBreak = { lineBreak: <br /> };

  return (
    <div className={style}>
      <div className="flex flex-col">
        <div className="p-4 text-center">
          <span className="text-brand-dark_blue">
            <FormattedMessage {...message} values={lineBreak} />
          </span>
        </div>
        <div className="flex justify-around">
          <div className="pb-6">
            <button
              className={confirmButtonStyle}
              onClick={() => alertAction(itemId)}
            >
              <FormattedMessage
                id="registration.subevent.dialog.confirm.button"
                defaultMessage="OK"
              />
            </button>
          </div>
          <div className="pb-6">
            <button
              className={cancelButtonStyle}
              onClick={() => alertCancel(alertId)}
            >
              <FormattedMessage
                id="registration.subevent.dialog.cancel.button"
                defaultMessage="Cancel"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
