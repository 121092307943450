import { DefinedMessages } from '../../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'registration.subevent.dependencyquestion.modal.header',
    defaultMessage: 'Is this a question to be repeated?',
  },
  paragraph1: {
    id: 'registration.subevent.dependencyquestion.modal.paragraph1',
    defaultMessage:
      'Question can be repeated, for example, for each participant. All of the contidions on the form must be fulfilled.',
  },
  paragraph2: {
    id: 'registration.subevent.dependencyquestion.modal.paragraph2',
    defaultMessage:
      "You can also use same conditions with multiple questions, and they will form a 'repeat' group automatically",
  },
  keyquestion: {
    id: 'registration.subevent.dependencyquestion.modal.key',
    defaultMessage: 'Key',
  },
  keydescription: {
    id: 'registration.subevent.dependencyquestion.modal.keylabel',
    defaultMessage: 'Choose a target',
  },
  valuequestion: {
    id: 'registration.subevent.dependencyquestion.modal.value',
    defaultMessage: 'Value',
  },
  valuedescription: {
    id: 'registration.subevent.dependencyquestion.modal.valuelabel',
    defaultMessage: 'Fill a value, that the target question must get',
  },
  amountquestion: {
    id: 'registration.subevent.dependencyquestion.modal.amount',
    defaultMessage: 'Amount',
  },
  amountdescription: {
    id: 'registration.subevent.dependencyquestion.modal.amountlabel',
    defaultMessage:
      'Choose a question which will set the amount of repeats (only number questions allowed)',
  },
  afterquestion: {
    id: 'registration.subevent.dependencyquestion.modal.after',
    defaultMessage: 'After',
  },
  afterdescription: {
    id: 'registration.subevent.dependencyquestion.modal.afterlabel',
    defaultMessage:
      'Repeat-groups will be set after this question. Note: If page has multiple groups, repeat-groups will append after last group.',
  },
  repeatheaderquestion: {
    id: 'registration.subevent.dependencyquestion.modal.repeatheader',
    defaultMessage: 'Header',
  },
  repeatheaderdescription: {
    id: 'registration.subevent.dependencyquestion.modal.repeatheaderlabel',
    defaultMessage:
      'Header which will show on the forms button when adding new items',
  },
  save: {
    id: 'registration.subevent.dependencyquestion.modal.save.button',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'registration.subevent.dependencyquestion.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
  remove: {
    id: 'registration.subevent.dependencyquestion.modal.clear.button',
    defaultMessage: 'Remove',
  },
};
