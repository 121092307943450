import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  hoverparagraph: {
    id: 'test.hovermessage.1',
    defaultMessage: 'On hover....',
  },
  hoverheader: {
    id: 'test.hovermessage.1.header',
    defaultMessage: 'Header',
  },
  subeventname: {
    id: 'registration.subevent.form.name',
    defaultMessage: 'Subevent name',
  },
  subeventstart: {
    id: 'registration.subevent.form.start',
    defaultMessage: 'Start',
  },
  subeventend: {
    id: 'registration.subevent.form.end',
    defaultMessage: 'End',
  },
  subeventregistrationstart: {
    id: 'registration.subevent.form.registrationstart',
    defaultMessage: 'Registration start',
  },
  subeventregistrationend: {
    id: 'registration.subevent.form.registrationend',
    defaultMessage: 'Registration end',
  },
  allowcancel: {
    id: 'registration.subevent.form.allowcancellation',
    defaultMessage: 'Allow cancelling',
  },
  allowcancelhelpheader: {
    id: 'registration.subevent.form.allowcancellation.help.header',
    defaultMessage: 'Cancellation'
  },
  allowcancelhelpparagaph: {
    id: 'registration.subevent.form.allowcancellation.help.paragraph',
    defaultMessage: 'You can allow cancellation of events by choosing either unlimited (cancellation is allowed anytime) or restricted, which allows cancellation until certain time.'
  },
  allowmodify: {
    id: 'registration.subevent.form.allowmodifying',
    defaultMessage: 'Allow modifying',
  },
  allowmodifyhelpheader: {
    id: 'registration.subevent.form.allowmodifying.help.header',
    defaultMessage: 'Modifying'
  },
  allowmodifyhelpparagaph: {
    id: 'registration.subevent.form.allowmodifying.help.paragraph',
    defaultMessage: 'You can allow queueing for this event. Meaning that when event is full, then the everyone following up will go into queue. You can choose either unlimited or restricted queue.'
  },
  allowqueue: {
    id: 'registration.subevent.form.allowqueuing',
    defaultMessage: 'Allow queueing',
  },
  allowqueuehelpheader: {
    id: 'registration.subevent.form.allowqueuing.help.header',
    defaultMessage: 'Queueing'
  },
  allowqueuehelpparagaph: {
    id: 'registration.subevent.form.allowqueuing.help.paragraph',
    defaultMessage: 'You can allow queueing for this event. Meaning that when event is full, then the everyone following up will go into queue. You can choose either unlimited or restricted queue.'
  },
  maxgroup: {
    id: 'registration.subevent.form.groupsize',
    defaultMessage: 'Allow grouping',
  },
  maxgrouplhelpheader: {
    id: 'registration.subevent.form.groupsize.help.header',
    defaultMessage: 'Groups'
  },
  maxgrouphelpparagaph: {
    id: 'registration.subevent.form.groupsize.help.paragraph',
    defaultMessage: 'You can limit how many people can be registered in group.'
  },
  maxregistrants: {
    id: 'registration.subevent.form.maxregistrants',
    defaultMessage: 'Max registrants',
  },
  maxregistrantshelpheader: {
    id: 'registration.subevent.form.maxregistrants.help.header',
    defaultMessage: 'Participant limit'
  },
  maxregistrantshelpparagaph: {
    id: 'registration.subevent.form.maxregistrants.help.paragraph',
    defaultMessage: 'This value represents how many participant can be registered in the event.'
  },
  supportemail: {
    id: 'registration.subevent.form.supportemail',
    defaultMessage: 'Support (email)',
  },
  supportphone: {
    id: 'registration.subevent.form.supportphone',
    defaultMessage: 'Support (phone)',
  },
  instructions: {
    id: 'registration.subevent.form.supportinstruction',
    defaultMessage: 'Instructions',
  },
  optionunlimited: {
    id: 'registration.subevent.form.option.unlimited',
    defaultMessage: 'Yes, unlimited'
  },
  optionrestricted: {
    id: 'registration.subevent.form.option.restricted',
    defaultMessage: 'Yes, with restriction'
  }
}
