import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { IoIosRepeat } from 'react-icons/io';
import { MdPerson } from 'react-icons/md';
import {
  HiOutlineTicket,
  HiOutlineEyeOff,
  HiOutlineKey,
  HiOutlineShieldCheck,
} from 'react-icons/hi';
import {
  AiOutlineFieldNumber,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';

interface ItemProps {
  item: string;
  id: string;
  index: number;
  type: string;
  options?: string[];
  basketId: string;
  group: number;
  repeatable: boolean;
  person: boolean;
  database: boolean;
  required: boolean;
  product: boolean;
  reservation: boolean;
  hidden: boolean;
  emailKey: boolean;
  handleClick: (itemId: string, basketId: string) => void;
}

export const DraggableItem: React.FC<ItemProps> = ({
  item,
  id,
  index,
  basketId,
  group,
  repeatable,
  handleClick,
  person,
  database,
  required,
  product,
  reservation,
  hidden,
  type,
  emailKey,
}) => {
  const itemStyle =
    'mx-auto p-2 m-2 border border-solid border-gray-200 w-5/6 rounded shadow-lg';
  const iconStyle = 'ml-2 inline-block';
  const repeatIcon = repeatable ? <IoIosRepeat className={iconStyle} /> : null;
  const personIcon = person ? <MdPerson className={iconStyle} /> : null;
  const productIcon = product ? (
    <HiOutlineTicket className={iconStyle} />
  ) : null;
  const reservationIcon = reservation ? (
    <AiOutlineFieldNumber className={iconStyle} />
  ) : null;
  const requiredIcon = required ? (
    <AiOutlineExclamationCircle className={iconStyle} />
  ) : null;
  const hiddenIcon = hidden ? <HiOutlineEyeOff className={iconStyle} /> : null;
  const databaseIcon =
    database || emailKey ? <HiOutlineKey className={iconStyle} /> : null;
  const acceptIcon =
    type === 'accept' ? <HiOutlineShieldCheck className={iconStyle} /> : null;
  const groupGradiant = `bg-blue-${group}00`;

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`${itemStyle} ${groupGradiant}`}
            onClick={() => handleClick(id, basketId)}
          >
            {item}
            {repeatIcon}
            {personIcon}
            {productIcon}
            {reservationIcon}
            {hiddenIcon}
            {requiredIcon}
            {databaseIcon}
            {acceptIcon}
          </div>
        );
      }}
    </Draggable>
  );
};
