import { ToggleState } from '../types/types';
import { AppState } from './combineReducer';

const initialState: ToggleState = {
  NAVBAR_TOGGLE: true,
};

export const toggleReducer = (
  state = initialState,
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case 'SHOW':
      return { ...state, [action.payload]: true };
    case 'HIDE':
      return { ...state, [action.payload]: false };
    case 'TOGGLE':
      return { ...state, [action.payload]: !state[action.payload] };
    default:
      return state;
  }
};

export function getToggleState(state: AppState): ToggleState {
  return state.toggleState;
}

export function selectToggleState(state: AppState, id: string): boolean {
  return state.toggleState[id] ? state.toggleState[id] : false;
}

export const selectToggleStateMultiple = (
  state: AppState,
  keys: string[]
): false | string => {
  const toggled = keys.find((key) => state.toggleState[key]);
  return toggled ? toggled : false;
};

export const selectAmountOfOpenModals = (
  state: AppState,
  keys: string[]
): number => {
  const accumulateIfTrue = (acc: number, key: string) =>
    state.toggleState[key] ? acc + 1 : acc;
  return keys.reduce(accumulateIfTrue, 0);
};
