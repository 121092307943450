import React from 'react';
import {Crop} from 'react-image-crop';
import {useSelector} from 'react-redux';
import {AppState} from '../../reducers/combineReducer';
import {CustomerCertTemplate} from '../../types/types';
import {getCustomerCertTemplate} from '../certReducer';
import {ImageComponent} from './ImageComponent';
import NoTemplate from './NoTemplate';
import {Form} from './TemplateForm';

interface PostMarkTemplateProps {
  templateName: string;
  handleInputChange: (e: React.ChangeEvent, id: string) => void;
  upload: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
}

interface TemplateProps {
  template: CustomerCertTemplate | undefined;
  handleInputChange: (e: React.ChangeEvent, id: string) => void;
  upload: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
}

const Card = ({template, handleInputChange, upload}: TemplateProps) => {
  return (
    <div className="relative w-full">
      <div className="flex justify-around text-3xl text-brand-dark_blue p-4">
        <div>{template.postmarkTemplateName}</div>
        <div>{template.templateDescription}</div>
      </div>
      <div className="flex">
        <Form
          upload={upload}
          questions={
            template.postmarkTemplateDataModel.postmarkTemplateQuestions
          }
          handleInputChange={handleInputChange}
        />
        <ImageComponent
          imageUrl={template.postmarkTemplateDataModel.templatePreviewUrl}
        />
      </div>
    </div>
  );
};

export const PostmarkCard: React.FC<PostMarkTemplateProps> = ({
  templateName,
  handleInputChange,
  upload,
}) => {
  const selected = useSelector((state: AppState) =>
    getCustomerCertTemplate(state, templateName)
  );

  return selected.found ? (
    <div className="m-auto bg-white overflow-auto scroller">
      <Card
        upload={upload}
        template={selected.template}
        handleInputChange={handleInputChange}
      />
    </div>
  ) : (
      <NoTemplate />
    );
};

export default PostmarkCard;
