import React from 'react';
import { EventStats } from '../../../types/types';
import { EventStatsItem } from './EventStatsItem';
import { SCANQRCODE } from '../../../img/images';

interface Props {
  eventStats: EventStats;
}

export const EventStatsCard: React.FC<Props> = ({
  eventStats: {
    eventName,
    registered,
    cancelled,
    subEvents,
    queue,
    eventCreatedAt,
    startDate,
    endDate,
  },
}) => {
  return (
    <div className="flex">
      <div className="flex flex-col ml-4 mb-4 w-1/2">
        <EventStatsItem stat={eventName} header={'events.header.eventname'} />
        <EventStatsItem
          stat={subEvents.toString()}
          header={'events.header.subevents'}
        />
        <EventStatsItem
          stat={registered.toString()}
          header={'events.header.registered'}
        />
        <EventStatsItem
          stat={cancelled.toString()}
          header={'events.header.cancelled'}
        />
        <EventStatsItem
          stat={queue.toString()}
          header={'events.header.queue'}
        />
        <EventStatsItem stat={startDate} header={'events.header.startdate'} />
        <EventStatsItem stat={endDate} header={'events.header.enddate'} />
        <EventStatsItem
          stat={eventCreatedAt}
          header={'events.header.eventcreatedat'}
        />
      </div>
      <div className="w-2/5 m-auto">
        <img
          src={`${process.env.PUBLIC_URL}/${SCANQRCODE}`}
          alt="Scan QR-Code"
        />
      </div>
    </div>
  );
};
