import {IntlMessageID} from "../../../..";

export const translations: {[key: string]: IntlMessageID} = {
  subeventid: 'subevents.headers.subeventid',
  subeventstate: 'subevents.headers.eventstate',
  subeventtype: 'subevents.headers.eventtype',
  subeventmaxgroupsize: 'subevents.headers.subeventsmaxgroupsize',
  subeventformid: 'subevents.headers.subeventformid',
  subeventpageid: 'subevents.headers.subeventpageid',
  subeventstarttime: 'subevents.headers.subeventstarttime',
  subeventendtime: 'subevents.headers.subeventendtime',
  subeventregistrationstarttime:
    'subevents.headers.subeventregistrationstarttime',
  subeventregistrationendtime: 'subevents.headers.subeventregistrationendtime',
  subeventcancellationendtime: 'subevents.headers.subeventcancellationendtime',
  subeventmodifyendtime: 'subevents.headers.subeventmodifyendtime',
  subeventallowcancel: 'subevents.headers.subeventallowcancel',
  subeventallowmodify: 'subevents.headers.subeventallowmodify',
  subeventmaxregistrants: 'subevents.headers.subeventmaxregistrants',
  subeventmaxquesize: 'subevents.headers.subeventmaxquesize',
  subeventlinkurl: 'subevents.headers.subeventlinkurl',
  subeventname: 'subevents.headers.subeventname',
};
