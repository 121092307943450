import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedMessageProps } from '../types/types';

type Props = {
  onClick: () => void;
  message: FormattedMessageProps;
  styleStr?: string;
};

export const NavHeader: React.FC<Props> = ({
  onClick,
  message,
  styleStr,
  children,
}) => {
  const styles = styleStr
    ? styleStr
    : 'text-lg lg:mt-0 text-blue-200 hover:text-yellow-300 mr-4 mt-4 right-0';
  return (
    <div className="flex w-full justify-between">
      <button onClick={onClick}>
        <span className={styles}>
          <FormattedMessage {...message} />
        </span>
      </button>
      {children}
    </div>
  );
};
