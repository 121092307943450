import React from 'react';
import { FcPrevious } from 'react-icons/fc';
import { FiSave, FiMail, FiTrash2, FiXCircle } from 'react-icons/fi';
import { BiGitPullRequest } from 'react-icons/bi';
import { DefinedMessages } from '../../../types/types';
import { HeaderItem } from './HeaderItem';

export interface Props {
  save: () => void;
  goback: () => void;
  move?: () => void;
  saveandsend?: () => void;
  cancel?: () => void;
  deleteItem?: () => void;
}

export const ParticipantFormHeader: React.FC<Props> = ({
  save,
  goback,
  saveandsend,
  deleteItem,
  cancel,
  move,
}) => {
  const messages: DefinedMessages = {
    back: {
      id: 'form.previouspage.button',
      defaultMessage: 'back',
    },
    save: {
      id: 'form.save.button',
      defaultMessage: 'save',
    },
    saveandsend: {
      id: 'form.saveandsend.button',
      defaultMessage: 'save and send',
    },
    cancel: {
      id: 'form.remove.button',
      defaultMessage: 'Remove',
    },
    delete: {
      id: 'form.delete.button',
      defaultMessage: 'Delete',
    },
    move: {
      id: 'form.move.button',
      defaultMessage: 'move',
    },
  };

  return (
    <div className="flex justify-center py-2 bg-blue-100 text-white text-center">
      <HeaderItem onClick={goback} message={messages.back}>
        <FcPrevious size={20} />
      </HeaderItem>
      <HeaderItem onClick={save} message={messages.save}>
        <FiSave size={20} />
      </HeaderItem>
      {saveandsend && (
        <HeaderItem onClick={saveandsend} message={messages.saveandsend}>
          <FiMail size={20} />
        </HeaderItem>
      )}
      {move && (
        <HeaderItem onClick={move} message={messages.move}>
          <BiGitPullRequest size={20} />
        </HeaderItem>
      )}
      {cancel && (
        <HeaderItem onClick={cancel} message={messages.cancel}>
          <FiXCircle size={20} />
        </HeaderItem>
      )}
      {deleteItem && (
        <HeaderItem
          onClick={deleteItem}
          message={messages.delete}
          danger={true}
        >
          <FiTrash2 size={20} />
        </HeaderItem>
      )}
    </div>
  );
};

export default ParticipantFormHeader;
