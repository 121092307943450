import React from 'react';
import TableData from '../../../../components/utils/TableData';
import { TableParticipant } from '../../../../types/types';

interface ParticipantRowProps {
  participant: TableParticipant;
  headerKeys: string[];
  open: (subEventId: string, id: string) => void;
}

export const ParticipantTableRow: React.FC<ParticipantRowProps> = ({
  participant,
  headerKeys,
  open,
}) => {
  const styles = 'border px-8 py-4 h-12 cursor-pointer truncate';
  const rowStyles =
    'hover:text-brand-blue flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key, i) => {
          const value = participant[key] ? participant[key] : '';
          const valueStr = typeof value !== 'string' ? value.join(',') : value;
          return (
            <TableData
              key={`${i}_${participant.uuid}`}
              value={valueStr}
              open={() => open(participant.subEventId, participant.uuid)}
              styles={styles}
            />
          );
        })}
      </tr>
    </>
  );
};

export default ParticipantTableRow;
