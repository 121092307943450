import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Footer: React.FC = () => {
  const styles =
    'flex justify-end items-center bg-brand-dark_blue w-screen h-40 text-white';

  return (
    <div className={styles}>
      <div className="flex flex-col">
        <div className="pr-4">
          <FormattedMessage
            id="footer.message.1"
            defaultMessage="ContactMate® services"
          />
        </div>
        <div className="pr-4">
          <FormattedMessage
            id="footer.message.2"
            defaultMessage="info@contactmate.fi"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
