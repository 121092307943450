import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { messages } from '../translations/newcert';
import 'react-image-crop/dist/ReactCrop.css';
import { FormattedMessage } from 'react-intl';

interface Props {
  width: number;
  height: number;
  maxSize?: number;
  acceptedTypes: string[];
  toggleId: string;
  questionId: string;
  upload: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
}
export const CropView: React.FC<Props> = ({
  width,
  height,
  toggleId,
  questionId,
  upload,
}) => {
  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const hcf = highestCommonFactor(width, height);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: width,
    aspect: width / hcf / (height / hcf),
  });

  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function highestCommonFactor(x: number, y: number) {
    while (Math.max(x, y) % Math.min(x, y) !== 0) {
      if (x > y) {
        x = x % y;
      } else {
        y = y % x;
      }
    }
    return Math.min(x, y);
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <div className="flex flex-col w-5/6 h-1/2 m-auto p-2 pt-4 mt-6 border shadow-xl">
      <div className="mx-auto mt-4 mb-8">
        <label>
          <input
            className="hidden"
            type="file"
            accept="image/*"
            onChange={onSelectFile}
          />
          <span className="bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-5 w-auto">
            <FormattedMessage {...messages.showcrop} />
          </span>
        </label>
      </div>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
      <div className="flex flex-col">
        {upImg && (
          <div className="mx-auto">
            <button
              className={`bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-5 w-auto`}
              onClick={() =>
                upload(
                  previewCanvasRef.current,
                  completedCrop,
                  questionId,
                  toggleId
                )
              }
            >
              <FormattedMessage {...messages.useimage} />
            </button>
          </div>
        )}
        <div className="flex flex-col invisible">
          <div>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropView;
