import { Cmd, Loop, loop, LoopReducer } from 'redux-loop';
import { ActionType, createAction, getType } from 'typesafe-actions';
import {
  getCertNew,
  newCertSend,
  setErrorMessageMissingCertFields,
} from '../certs/certReducer';
import {
  getEventAsync,
  createEventAsync,
  getDefaultForm,
  sendRegistrationAsync,
  saveRegistrationQuestions,
  getEventDetails,
  postLinkForm,
  getLinkSuggestion,
  getNewParticipantForm,
  getParticipantDetails,
} from '../events/eventReducer';
import { getAllEventsAsync } from '../events/listEvents/listEventsReducer';
import { loginAsync } from '../login/loginReducer';
import { ErrorState } from '../types/types';
import { AppState } from './combineReducer';
import { getFormAsync } from './formReducer';
import {
  getFennoaInvoices,
  getFennoaProducts,
  invoiceSend,
} from './invoiceReducer';
import {
  cancelParticipantAsync,
  deleteParticipantAsync,
  moveParticipantAsync,
  newParticipantAsync,
  saveParticipantChanges,
} from './participantReducer';

const initialState: ErrorState = {
  message: '',
};

const clearErrorMessage = createAction('CLEAR_ERROR_MESSAGE')<undefined>();

type Action =
  | ActionType<typeof clearErrorMessage>
  | ActionType<typeof getFormAsync>
  | ActionType<typeof getEventAsync>
  | ActionType<typeof getAllEventsAsync>
  | ActionType<typeof loginAsync>
  | ActionType<typeof getDefaultForm>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof sendRegistrationAsync>
  | ActionType<typeof saveRegistrationQuestions>
  | ActionType<typeof getEventDetails>
  | ActionType<typeof postLinkForm>
  | ActionType<typeof getLinkSuggestion>
  | ActionType<typeof getCertNew>
  | ActionType<typeof newCertSend>
  | ActionType<typeof setErrorMessageMissingCertFields>
  | ActionType<typeof saveParticipantChanges>
  | ActionType<typeof getNewParticipantForm>
  | ActionType<typeof newParticipantAsync>
  | ActionType<typeof getParticipantDetails>
  | ActionType<typeof cancelParticipantAsync>
  | ActionType<typeof moveParticipantAsync>
  | ActionType<typeof getFennoaInvoices>
  | ActionType<typeof getFennoaProducts>
  | ActionType<typeof invoiceSend>
  | ActionType<typeof deleteParticipantAsync>;

export const errorReducer: LoopReducer<ErrorState, Action> = (
  state: ErrorState = initialState,
  action: Action
): ErrorState | Loop<ErrorState> => {
  switch (action.type) {
    case getType(createEventAsync.success):
      return { ...state, message: null };
    case getType(getAllEventsAsync.success):
      return { ...state, message: null };
    case getType(getEventDetails.success):
      return { ...state, message: null };
    case getType(loginAsync.success):
      return { ...state, message: null };
    case getType(getFormAsync.success):
      return { ...state, message: null };
    case getType(getDefaultForm.success):
      return { ...state, message: null };
    case getType(getEventAsync.success):
      return { ...state, message: null };
    case getType(sendRegistrationAsync.success):
      return { ...state, message: null };
    case getType(saveRegistrationQuestions.success):
      return { ...state, message: null };
    case getType(postLinkForm.success):
      return { ...state, message: null };
    case getType(getLinkSuggestion.success):
      return { ...state, message: null };
    case getType(getCertNew.success):
      return { ...state, message: null };
    case getType(newCertSend.success):
      return { ...state, message: null };
    case getType(loginAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getEventAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getAllEventsAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getFormAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getDefaultForm.failure):
      return { ...state, message: action.payload.message };
    case getType(createEventAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(sendRegistrationAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(saveRegistrationQuestions.failure):
      return { ...state, message: action.payload.message };
    case getType(getEventDetails.failure):
      return { ...state, message: action.payload.message };
    case getType(postLinkForm.failure):
      if (action.payload.message.includes('400')) {
        return {
          ...state,
          message: 'Link is already taken, please choose another one',
        };
      }
      return { ...state, message: action.payload.message };
    case getType(getLinkSuggestion.failure):
      return { ...state, message: action.payload.message };
    case getType(getCertNew.failure):
      return { ...state, message: action.payload.message };
    case getType(newCertSend.failure):
      return { ...state, message: action.payload.message };
    case getType(setErrorMessageMissingCertFields):
      const missingFields = action.payload.join(', ');
      return loop(
        {
          ...state,
          message: `Error: Can not send cert! Missing required fields: \n\n${missingFields}`,
        },
        Cmd.setTimeout(Cmd.action({ type: 'CLEAR_ERROR_MESSAGE' }), 3000)
      );
    case getType(clearErrorMessage):
      return { ...state, message: null };
    case getType(saveParticipantChanges.success):
      return { ...state, message: null };
    case getType(saveParticipantChanges.failure):
      return { ...state, message: action.payload.message };
    case getType(getNewParticipantForm.success):
      return { ...state, message: null };
    case getType(getNewParticipantForm.failure):
      return { ...state, message: action.payload.message };
    case getType(newParticipantAsync.success):
      return { ...state, message: null };
    case getType(newParticipantAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getParticipantDetails.success):
      return { ...state, message: null };
    case getType(getParticipantDetails.failure):
      return { ...state, message: action.payload.message };
    case getType(cancelParticipantAsync.success):
      return { ...state, message: null };
    case getType(cancelParticipantAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(deleteParticipantAsync.success):
      return { ...state, message: null };
    case getType(deleteParticipantAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(moveParticipantAsync.success):
      return { ...state, message: null };
    case getType(moveParticipantAsync.failure):
      return { ...state, message: action.payload.message };
    case getType(getFennoaProducts.success):
      return { ...state, message: null };
    case getType(getFennoaProducts.failure):
      return { ...state, message: action.payload.message };
    case getType(getFennoaInvoices.success):
      return { ...state, message: null };
    case getType(getFennoaInvoices.failure):
      return { ...state, message: action.payload.message };
    case getType(invoiceSend.success):
      return { ...state, message: null };
    case getType(invoiceSend.failure):
      return { ...state, message: action.payload.message };
    default:
      return state;
  }
};

export function getErrorMessage(state: AppState): { message: string } {
  return { message: state.errorState.message };
}
