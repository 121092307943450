import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'registration.subevent.save.header',
    defaultMessage: 'You are almost ready',
  },
  paragraph1: {
    id: 'registration.subevent.save.paragraph1',
    defaultMessage:
      "Please check that all information is correct. If you are not ready, don't worry. You can save data and continue later at your convenience.",
  },
  paragraph2: {
    id: 'registration.subevent.save.paragraph2',
    defaultMessage:
      'After saving questions, you can create a link for the registration-form!',
  },
  paragraph3: {
    id: 'registration.subevent.save.paragraph3',
    defaultMessage:
      'Before publishing the registration-form, you have to resolve all validation-errors found on the Validation-tab!',
  },
  save: {
    id: 'registration.subevent.save.button',
    defaultMessage: 'Save',
  }
}
