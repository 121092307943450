import React from "react";
import TableData from "../../../../components/utils/TableData";
import {SubEventObject} from "../../../../types/types";

interface Props {
  subEvent: SubEventObject;
  headerKeys: string[];
  open: (id: string) => void;
}

export const SubEventTableRow: React.FC<Props> = ({
  subEvent,
  headerKeys,
  open,
}) => {
  const styles = "border px-8 py-4 h-12 cursor-pointer truncate";
  const rowStyles =
    "hover:text-brand-blue flex flex-col flex-nowrap md:table-row mb-2 md:mb-0";

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key, i) => {
          const value = subEvent[key] ? subEvent[key] : "";
          const valueStr = typeof value !== "string" ? value.join(", ") : value;
          return (
            <TableData
              key={`${i}_${key}`}
              value={valueStr}
              open={() => open(subEvent.subEventId)}
              styles={styles}
            />
          );
        })}
      </tr>
    </>
  );
};

export default SubEventTableRow;
