const clearError = () => {
  return {
    type: "CLEAR_ERROR_MESSAGE",
  };
};

const errorActions = {
  clearError,
};

export default errorActions;
