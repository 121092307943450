import {IntlMessageID} from "../../..";

export const translations: {[key: string]: IntlMessageID} = {
  eventname: 'events.header.eventname',
  startdate: 'events.header.startdate',
  enddate: 'events.header.enddate',
  eventcreatedat: 'events.header.eventcreatedat',
  subevents: 'events.header.subevents',
  registered: 'events.header.registered',
  eventid: 'events.header.eventId',
  customerid: 'events.header.customerId',
  registerid: 'events.header.registerId',
  eventplace: 'events.header.eventplace',
  eventstreetaddress: 'events.header.eventstreetaddress',
  eventzip: 'events.header.eventzip',
  eventcity: 'events.header.eventcity',
  eventcountry: 'events.header.eventcountry',
  eventstate: 'events.header.eventstate',
  startdatetime: 'events.header.startdatetime',
  enddatetime: 'events.header.enddatetime',
  description: 'events.header.description',
  eventhomepage: 'events.header.eventhomepage',
  eventsupportemail: 'events.header.eventsupportemail',
  eventsupportphone: 'events.header.eventsupportphone',
  eventsupportinstructions: 'events.header.eventsupportinstructions',
  cancelled: 'events.header.cancelled',
  queue: 'events.header.queue',
  filterbuttonempty: 'table.searchbar.filter.button',
  filterbuttonmulti: 'table.searchbar.multifilter.button'
};
