import React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { RiSearchEyeLine, RiSettings5Fill } from 'react-icons/ri';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import allActions from '../../actions/allActions';
import { ExportObject, FormattedMessageProps } from '../../types/types';
import FilterModal from './FilterModal';
import { ExportSettings } from './ExportSettings';
import { IconWrapper } from '../utils/IconWrapper';
import TableSettings from './TableSettings';
import { Toggle } from '../utils/Toggle';
import { ToggleFilterButton } from './ToggleFilterButton';
import { IntlMessageID } from '../..';
import { messages } from './translations';

export type OptionalAction = {
  action: () => void;
  message: FormattedMessageProps;
};

interface SearchBarProps {
  styles?: string;
  sortKeys: string[];
  activeFilters: string[];
  selectedColumns: string[];
  restColumns: string[];
  filterDivToggleId: string;
  tableSettingsDivToggleId: string;
  exportSettingsDivToggleId: string;
  exportSettings: {
    exportAll: ExportObject;
    exportView: ExportObject;
  };
  headerTranslations?: { [key: string]: IntlMessageID };
  toggleFilter: (key: string) => void;
  clearFilters: () => void;
  optionalAction?: OptionalAction;
  onSearchWordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDragEnd: (dragObject: DropResult) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  sortKeys,
  filterDivToggleId,
  activeFilters,
  exportSettings,
  tableSettingsDivToggleId,
  exportSettingsDivToggleId,
  selectedColumns,
  restColumns,
  headerTranslations,
  toggleFilter,
  onSearchWordChange,
  optionalAction,
  clearFilters,
  onDragEnd,
}) => {
  const barStyle = 'bg-blue-100 h-12 w-full flex flex-row text-brand-dark_blue';
  const inputStyle =
    'w-full h-full rounded px-4 focus:outline-none focus:ring-4 focus:ring-blue-400 focus:ring-opacity-50';
  const iconStyle = 'absolute top-0 right-0 text-brand-blue mt-1 mr-4';
  const settingsIconStyle = 'text-brand-blue mt-1 mr-4 hover:text-blue-500';
  const inputContainer = 'bg-white relative w-2/3 m-1 rounded';
  const buttonContainerStyle =
    'w-1/6 bg-white text-center m-1 flex justify-center rounded';
  const settingsIconDiv = 'm-2 cursor-pointer';
  const buttonStyle = 'w-full';
  const filterDivContainer =
    'flex-col justify-center divide-y divide-gray-500 bg-white mt-5 p-4 w-full absolute rounded border border-gray-400 shadow-md z-50';
  const filterPillDiv = 'flex flex-row flex-wrap my-4';
  const filterPill =
    'rounded-full mx-8 my-4 py-2 px-4 uppercase text-white focus:outline-none focus:ring focus:ring-blue-600';
  const filterButtonDivStyle = 'flex justify-center';
  const filterDivButtonStyle =
    'bg-blue-400 hover:bg-brand-blue font-thin text-brand-white py-2 px-4 rounded m-5';
  const tableSettingsDiv =
    'right-0 flex bg-white mt-5 p-4 rounded border border-gray-400 shadow-md z-50 absolute w-full';
  const exportSettingsDiv =
    'right-0 flex-col bg-white mt-5 p-4 rounded border border-gray-400 shadow-md z-50 absolute w-1/4';
  const dispatch = useDispatch();

  const translations = headerTranslations ? headerTranslations : {};

  const toggle = (id: string) => {
    dispatch(allActions.toggleActions.toggle(id));
  };

  const { formatMessage } = useIntl();

  return (
    <div className="relative w-full">
      <div className={barStyle}>
        <div className={inputContainer}>
          <input
            className={inputStyle}
            onChange={(e) => onSearchWordChange(e)}
            placeholder={formatMessage({ ...messages.searchbar })}
          />
          <IconWrapper styles={iconStyle}>
            <RiSearchEyeLine size={30} />
          </IconWrapper>
        </div>
        <ToggleFilterButton
          translations={translations}
          buttonContainerStyle={buttonContainerStyle}
          buttonStyle={buttonStyle}
          toggle={toggle}
          activeFilters={activeFilters}
          amount={activeFilters.length}
          filterDivToggleId={filterDivToggleId}
        />
        <div className={`${buttonContainerStyle} bg-blue-300 text-blue-600`}>
          <button className={buttonStyle} onClick={clearFilters}>
            <FormattedMessage {...messages.clearfilters} />
          </button>
        </div>
        <div className={`${buttonContainerStyle} flex bg-green-400 text-white`}>
          <button
            className={`${buttonStyle} font-thin`}
            onClick={() => toggle(exportSettingsDivToggleId)}
          >
            <FormattedMessage {...messages.exportdata} />
          </button>
        </div>
        {optionalAction && (
          <div
            className={`${buttonContainerStyle} flex bg-yellow-400 text-white`}
          >
            <button
              className={`${buttonStyle} font-thin`}
              onClick={optionalAction.action}
            >
              <FormattedMessage {...optionalAction.message} />
            </button>
          </div>
        )}
        <div
          className={settingsIconDiv}
          onClick={() => toggle(tableSettingsDivToggleId)}
        >
          <IconWrapper styles={settingsIconStyle}>
            <RiSettings5Fill size={30} />
          </IconWrapper>
        </div>
      </div>
      <Toggle id={filterDivToggleId}>
        <FilterModal
          filterDivContainer={filterDivContainer}
          filterDivToggleId={filterDivToggleId}
          filterPillDiv={filterPillDiv}
          filterPill={filterPill}
          filterButtonDivStyle={filterButtonDivStyle}
          filterDivButtonStyle={filterDivButtonStyle}
          sortKeys={sortKeys}
          toggleFilter={toggleFilter}
          toggle={toggle}
          activeFilters={activeFilters}
          translations={translations}
        />
      </Toggle>
      <Toggle id={tableSettingsDivToggleId}>
        <TableSettings
          onDragEnd={onDragEnd}
          tableSettingsDiv={tableSettingsDiv}
          selectedColumns={selectedColumns}
          columns={restColumns}
          translations={translations}
        />
      </Toggle>
      <Toggle id={exportSettingsDivToggleId}>
        <ExportSettings
          toggleId={exportSettingsDivToggleId}
          exportAll={exportSettings.exportAll}
          exportView={exportSettings.exportView}
          exportSettingsDiv={exportSettingsDiv}
        />
      </Toggle>
    </div>
  );
};

export default SearchBar;
