import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParticipantTable from '../components/participantTable/ParticipantTable';
import {
  selectEventParticipantTableData,
  SubEventsMinimal,
} from '../../eventReducer';
import allActions from '../../../actions/allActions';
import { useParams } from 'react-router-dom';
import { EventParamTypes } from '../../../types/types';
import { BreadCrumb } from '../../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import * as uState from '../../../reducers/userReducer';
import storeService from '../../../services/storage';
import { AppState } from '../../../reducers/combineReducer';
import { selectLoading } from '../../../reducers/loadingReducer';
import { historyService } from '../../../services/historyService';

interface Props {}

export interface NewParticipantModal {
  modalId: string;
  modalOpen: () => void;
  modalAction: (event: SubEventsMinimal) => void;
  options: SubEventsMinimal[];
}

export const EventParticipants: React.FC<Props> = () => {
  const { id } = useParams<EventParamTypes>();
  const {
    table,
    participants,
    startingTableHeaders,
    options,
  } = useSelector((state: AppState) =>
    selectEventParticipantTableData(state, id)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'getParticipants')
  );
  const dispatch = useDispatch();
  const { userId } = useSelector(uState.selectUserData);
  const tableName = `participants_${id}_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : startingTableHeaders;
  const newParticipantChooseSubEventDivId =
    'EVENT_PARTICIPANTS_NEW_CHOOSE_SUBEVENT';

  const openParticipant = (subEventId: string, uuid: string) => {
    dispatch(
      allActions.participantActions.getParticipantDetails(subEventId, uuid)
    );
  };

  const gotoNewParticipantForm = (event: SubEventsMinimal) => {
    historyService.goto(
      `/events/${id}/subevents/${event.subEventId}/newparticipant`
    );
  };

  const openNewParticipantModal = () => {
    dispatch(
      allActions.toggleActions.toggle(newParticipantChooseSubEventDivId)
    );
  };

  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb
          label={`participants`}
          link={`/events/${id}/participants`}
          lastChild={true}
        />
      </BreadRoute>
      <ParticipantTable
        open={openParticipant}
        createModal={{
          modalId: newParticipantChooseSubEventDivId,
          modalOpen: openNewParticipantModal,
          modalAction: gotoNewParticipantForm,
          options: options,
        }}
        list={participants}
        tableList={table}
        headerRow={headers}
        loading={isLoading}
        tableName={tableName}
      />
    </div>
  );
};

export default EventParticipants;
