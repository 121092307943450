import { DefinedMessages } from '../../types/types';

export const messages: DefinedMessages = {
  searchbar: {
    id: 'table.searchbar.filter.input.placeholder',
    defaultMessage: 'Search by any keyword or specific attribute',
  },
  clearfilters: {
    id: 'table.searchbar.clearfilters.button',
    defaultMessage: 'Clear filters',
  },
  exportdata: {
    id: 'table.searchbar.export.button',
    defaultMessage: 'Export data',
  },
  exportall: {
    id: 'table.searchbar.exportall.button',
    defaultMessage: 'Export all',
  },
  exportview: {
    id: 'table.searchbar.exportview.button',
    defaultMessage: 'Export view',
  },
  selectedcolumns: {
    id: 'table.searchbar.columnsettings.header1',
    defaultMessage: 'selected',
  },
  restofcolumns: {
    id: 'table.searchbar.columnsettings.header2',
    defaultMessage: 'rest',
  },
  newsubevent: {
    id: 'table.searchbar.newsubevent.button',
    defaultMessage: 'New subevent',
  },
  newparticipant: {
    id: 'table.searchbar.newparticipant.button',
    defaultMessage: 'New participant',
  },
  filterbutton: {
    id: 'table.searchbar.filter.button',
    defaultMessage: 'Filter',
  },
  filterbuttonmulti: {
    id: 'table.searchbar.multifilter.button',
    defaultMessage: 'Filters: {amount}',
  },
};
