const showToggle = (id: string) => {
  return {
    type: "SHOW",
    payload: id,
  };
};

const hideToggle = (id: string) => {
  return {
    type: "HIDE",
    payload: id,
  };
};

const toggle = (id: string) => {
  return {
    type: "TOGGLE",
    payload: id,
  };
};

const toggleActions = {
  showToggle,
  hideToggle,
  toggle,
};

export default toggleActions;
