import React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FormInput from '../../../../components/form/FormInput';
import FormSelect from '../../../../components/form/FormSelect';
import { FormInputInfo } from '../../../../types/types';
import { extractId } from '../../../../utils/functions';
import { messages } from '../../translations/hideModalTranslations';

interface HideModalProps {
  styles?: string;
  modalId: string;
  item: FormInputInfo;
  options: { id: string; name: string }[];
  modalAction: (item: FormInputInfo, modalId: string) => void;
  modalCancel: (modalId: string) => void;
}

export const HideModal: React.FC<HideModalProps> = ({
  styles,
  modalId,
  item,
  options,
  modalAction,
  modalCancel,
}) => {
  const [state, setState] = useState({
    ...item,
    hide: item.hide
      ? {
          key: findValue(item.hide.key),
          value: item.hide.value,
          type: item.hide.type,
        }
      : { key: '', value: '', type: '' },
  });
  const style = styles !== undefined ? styles : 'text-center shadow-xl';
  const { formatMessage } = useIntl();
  const optionsName = [''].concat(
    options.map((item) => `${item.name} :: ${item.id}`)
  );
  const typeOptions = ['', 'hide', 'show'];

  const localInputChange = (e: any) => {
    setState({
      ...state,
      hide: {
        ...state.hide,
        [e.target.id]: e.target.value,
      },
    });
  };

  function findValue(key: string) {
    const item = options.find((item) => item.id === key);
    return item !== undefined ? `${item.name} :: ${item.id}` : '';
  }

  function findId(key: string) {
    const id = extractId(key);
    const item = options.find((item) => item.id === id);
    return item !== undefined ? item.id : '';
  }

  return (
    <div className={style}>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className="uppercase font-semibold m-4 text-brand-dark_blue">
            <FormattedMessage {...messages.header} />
          </p>
          <p className="m-4 text-brand-dark_blue">
            <FormattedMessage {...messages.paragraph} />
          </p>
          <FormSelect
            id="type"
            question={formatMessage({ ...messages.typequestion })}
            options={typeOptions}
            value={state.hide.type}
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.type })}
          />
          <FormSelect
            id="key"
            question={formatMessage({ ...messages.keyquestion })}
            options={optionsName}
            value={state.hide.key}
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.key })}
          />
          <FormInput
            id="value"
            question={formatMessage({ ...messages.valuequestion })}
            value={state.hide.value}
            type="text"
            handleInputChange={localInputChange}
            cursiveLabel={formatMessage({ ...messages.value })}
          />
        </div>
        <div className="flex flex-row justify-around">
          <div className="pb-6">
            <button
              onClick={() =>
                modalAction(
                  {
                    ...state,
                    hide: {
                      key: findId(state.hide.key),
                      value: state.hide.value,
                      type: state.hide.type,
                    },
                  },
                  modalId
                )
              }
              className="bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.save} />
            </button>
          </div>
          <div className="pb-6">
            <button
              onClick={() =>
                modalAction(
                  {
                    ...state,
                    hide: {
                      key: '',
                      value: '',
                      type: '',
                    },
                  },
                  modalId
                )
              }
              className="bg-red-300 hover:bg-red-400 text-brand-white m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.remove} />
            </button>
          </div>
          <div className="pb-6">
            <button
              onClick={() => modalCancel(modalId)}
              className="bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10"
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HideModal;
