import React from "react";
import { TableData } from "../../components/utils/TableData";
import { CertMail } from "../../types/types";
import { BsArrowRepeat } from "react-icons/bs";
import { IconWrapper } from "../../components/utils/IconWrapper";

interface RowProps {
  cert: CertMail;
  headerKeys: string[];
  goEditCert: (mailId: string) => void;
  resend: (mailId: string) => void;
}

export const CertTableRow: React.FC<RowProps> = ({
  cert,
  headerKeys,
  goEditCert,
  resend,
}) => {
  const styles = "border cursor-pointer px-8 py-4 h-12";
  const rowStyles =
    "hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0";

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key, i) => {
          const value = cert[key] ? cert[key] : "";
          const valueStr = value.toString();
          return (
            <TableData
              open={() => goEditCert(cert.mailId)}
              key={key}
              value={valueStr}
              styles={styles}
            />
          );
        })}
        <td
          className={`${styles} text-center`}
          onClick={() => resend(cert.mailId)}
        >
          <IconWrapper styles={"text-green-400 hover:text-green-600"}>
            <BsArrowRepeat className='w-full text-center' size={22} />
          </IconWrapper>
        </td>
      </tr>
    </>
  );
};

export default CertTableRow;
