import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  linkerrorparagaph: {
    id: 'link.subevent.validationerrors',
    defaultMessage:
      'You cannot publish link before resolving {errors} validationerrors in Registration Form-tool!',
  },
  linksuggestionquestion: {
    id: 'link.subevent.form.linksuggestion',
    defaultMessage: 'Link for form',
  },
  linkprefix: {
    id: 'link.subevent.form.linkprefix',
    defaultMessage: 'Prefix for link: {prefix}',
  },
  existinglinksheader: {
    id: 'link.subevent.form.linksexistingheader',
    defaultMessage: 'Existing links {linkemoji}',
    values: {linkemoji: '🔗'}
  },
  noexistinglinks: {
    id: 'link.subevent.form.noexistinglinksheader',
    defaultMessage: 'No existing links {linkemoji}',
    values: {linkemoji: '🔗'}
  },
  save: {
    id: 'link.subevent.save.button',
    defaultMessage: 'Save',
  },
  publish: {
    id: 'link.subevent.publish.button',
    defaultMessage: 'Publish',
  },
  linkpublished: {
    id: 'link.subevent.linkpublishedmessage',
    defaultMessage:
      'Link was published successfully for your form: <a>{link}</a> {emoji}',
  }
}
