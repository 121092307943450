import { DefinedMessages } from '../../types/types';

export const messages: DefinedMessages = {
  cert: {
    id: 'cert.tabs.cert',
    defaultMessage: 'cert',
  },
  mail: {
    id: 'cert.tabs.mail',
    defaultMessage: 'mail',
  },
  send: {
    id: 'cert.tabs.send',
    defaultMessage: 'send',
  },
  participants: {
    id: 'cert.tabs.participants',
    defaultMessage: 'participants',
  },
  selectevents: {
    id: 'cert.create.selectevent',
    defaultMessage: 'Select event',
  },
  multisend: {
    id: 'cert.create.multisend',
    defaultMessage: 'Send multiple',
  },
  eventsource: {
    id: 'cert.create.eventsource',
    defaultMessage: 'Event source',
  },
  selecttemplate: {
    id: 'cert.create.selecttemplate',
    defaultMessage: 'Select template',
  },
  showcrop: {
    id: 'cert.create.selectimage',
    defaultMessage: 'Select image',
  },
  changeimage: {
    id: 'cert.create.changeimage',
    defaultMessage: 'Change image',
  },
  useimage: {
    id: 'cert.create.useimage',
    defaultMessage: 'Change image',
  },
  hidecrop: {
    id: 'cert.create.returntoform',
    defaultMessage: 'Return to form',
  },
  csvupload: {
    id: 'cert.create.csvupload',
    defaultMessage: 'Drag here or click to upload',
  },
  csvheaders: {
    id: 'cert.create.csvheaders',
    defaultMessage: 'Data has headers',
  },
};
