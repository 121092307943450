import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import { selectEventData } from '../../listEvents/listEventsReducer';
import { AppState } from '../../../reducers/combineReducer';
import { DefinedMessages, EventParamTypes } from '../../../types/types';
import { PieChart } from '../components/Piechart';
import { ParticipantDataCard } from '../components/ParticipantDataCard';
import { EventStatsCard } from '../components/EventStats';
import { NoData } from './NoData';

export const EventDetailCard: React.FC = () => {
  const { id } = useParams<EventParamTypes>();
  const { data, eventStats } = useSelector((state: AppState) =>
    selectEventData(state, id)
  );

  const messages: DefinedMessages = {
    participantdataheader: {
      id: 'event.details.participantdata.header',
      defaultMessage: 'Participants per event',
    },
    eventdetailsheader: {
      id: 'event.details.details.header',
      defaultMessage: 'Additional information',
    },
  };

  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb
          label={`details`}
          link={`/events/${id}/details`}
          lastChild={true}
        />
      </BreadRoute>
      <div className="flex w-11/12 mx-auto py-5 my-10">
        <ParticipantDataCard header={messages.eventdetailsheader}>
          <EventStatsCard eventStats={eventStats} />
        </ParticipantDataCard>
        <ParticipantDataCard header={messages.participantdataheader}>
          {data.length ? (
            <PieChart data={data} outerRadius={200} innerRadius={0} />
          ) : (
            <NoData />
          )}
        </ParticipantDataCard>
      </div>
    </div>
  );
};

export default EventDetailCard;
