import { CertSource, ImportCSVData } from '../types/types';

const startCertNewFetch = (id?: string) => {
  return {
    type: 'START_CERT_NEW_FETCH',
    payload: id ? id : null,
  };
};

const startCertSend = (id: string, preview: boolean) => {
  return {
    type: 'START_CERT_SEND',
    payload: { id, preview },
  };
};

const getLyytikey = () => {
  return {
    type: 'START_GET_LYYTIKEY_REQUEST',
  };
};

const changeEventSource = (eventSource: string) => {
  return {
    type: 'CHANGE_EVENT_SOURCE',
    payload: eventSource,
  };
};

const changeSendType = () => {
  return {
    type: 'CHANGE_CERT_SEND_TYPE',
  };
};

const toggleRecipient = (id: string) => {
  return {
    type: 'TOGGLE_CERT_RECIPIENT',
    payload: id,
  };
};

const toggleAllRecipients = () => {
  return {
    type: 'TOGGLE_ALL_CERT_RECIPIENTS',
  };
};

const getAllCerts = () => {
  return {
    type: 'START_GET_ALL_CERTS_FETCH',
  };
};

const setErrorMessageMissingFields = () => {
  return {
    type: 'SET_ERROR_MESSAGE_CERT_MISSING_REQUIRED_FIELD',
  };
};

const changeSelectedTemplate = (name: string) => {
  return {
    type: 'CHANGE_SELECTED_TEMPLATE',
    payload: name,
  };
};

const changeEvent = (
  eventId: string,
  eventSource: CertSource,
  eventName: string
) => {
  return {
    type: 'CHANGE_EVENT_CERT',
    payload: { eventId, eventSource, eventName },
  };
};

const mapEditData = (certOperationId: string, mailId: string) => {
  return {
    type: 'MAP_CERT_EDIT_DATA',
    payload: { certOperationId, mailId },
  };
};

const resend = (mailId: string) => {
  return {
    type: 'START_RESEND_CERT',
    payload: mailId,
  };
};

const importParticipants = (
  participants: ImportCSVData[],
  headers: boolean
) => {
  return {
    type: 'IMPORT_PARTICIPANTS',
    payload: { data: participants, headers },
  };
};

const certActions = {
  startCertNewFetch,
  startCertSend,
  getLyytikey,
  changeEventSource,
  changeSendType,
  toggleRecipient,
  toggleAllRecipients,
  getAllCerts,
  setErrorMessageMissingFields,
  changeSelectedTemplate,
  changeEvent,
  mapEditData,
  resend,
  importParticipants,
};

export default certActions;
