import React from 'react';
import {LinkLabel} from './LinkLabel';
import {LabelWithHelpIcon} from './LabelWithHelpIcon';
import {Label} from './Label';
import {AdditionalLabel} from './AdditionalLabel';
import {HoverAttributes} from '../../utils/HoverMessage';

interface Props {
  link: string | boolean;
  question: string;
  labelStyle?: string;
  cursiveLabel?: string;
  register?: any;
  overlayProps?: HoverAttributes
}

export const LabelContainer: React.FC<Props> = ({
  register,
  link,
  question,
  labelStyle,
  cursiveLabel,
  overlayProps
}) => {
  return (
    <>
      {typeof link === 'string' ? (
        <LinkLabel link={link} question={question} register={register} />
      ) : question && overlayProps ? (
        <LabelWithHelpIcon
          labelStyle={labelStyle}
          register={register}
          question={question}
          overlayProps={overlayProps}
        />
      ) : question ? (
        <Label
          question={question}
          register={register}
          labelStyle={labelStyle}
        />
      ) : null}
      <AdditionalLabel cursiveLabel={cursiveLabel} />
    </>
  );
};
