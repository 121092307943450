import { LoopReducer, loop, Loop, Cmd } from 'redux-loop';
import {
  ActionType,
  createAsyncAction,
  getType,
  createAction,
} from 'typesafe-actions';
import {
  getAllEventsAsync,
  getAllLyytiEventsAsync,
  getAllLyytiParticipantsAsync,
} from '../events/listEvents/listEventsReducer';
import { AppState } from '../reducers/combineReducer';
import { uploadFileToForm } from '../reducers/fileUploadReducer';
import { getParticipants } from '../reducers/participantReducer';
import certService from '../services/certs';
import eventService from '../services/events';
import { historyService } from '../services/historyService';
import {
  Cert,
  CertMailTable,
  CertNewResponse,
  CertOperationType,
  CertSource,
  CertState,
  CertTemplateDefaultValues,
  CustomerCertTemplate,
  ImportCSVData,
  LyytiParticipant,
} from '../types/types';
import { v4 as uuidv4 } from 'uuid';
import { convertParticipantToListFormat, sortByKey } from '../utils/functions';

const startingSourceOptions = ['', 'CSV-import', 'ContactMate'];
const initialState: CertState = {
  selectedTemplate: '',
  certSource: {
    options: startingSourceOptions,
    selected: '',
  },
  event: {
    selected: '',
    participants: [],
    selectedParticipants: [],
  },
  sendMultiple: false,
  allCerts: [],
};

const emptyCustomerTemplate = {
  templateName: '',
  templateId: '',
  templateDescription: '',
  templateDataModel: {
    templatePreviewUrl: '',
    templateQuestions: [],
  },
  postmarkTemplateId: '',
  postmarkTemplateName: '',
  postmarkTemplateDescription: '',
  postmarkTemplateDataModel: {
    templatePreviewUrl: '',
    postmarkTemplateQuestions: [],
  },
};

export const getCertNew = createAsyncAction(
  'START_CERT_NEW_FETCH',
  'CERT_NEW_FETCH_COMPLETE',
  'CERT_NEW_FETCH_FAIL'
)<
  string | null,
  { data: CertNewResponse; status: number; template: string | null },
  Error
>();

export type CertSendActionPayload = {
  certOperationId: string;
  certOperationType: CertOperationType;
  template: CustomerCertTemplate;
  selected: { [key: string]: string }[];
  preview: boolean;
};
export const newCertSend = createAsyncAction(
  'START_CERT_SEND_REQUEST',
  'CERT_SEND_COMPLETE',
  'CERT_SEND_FAIL'
)<CertSendActionPayload, { data: any; status: number }, Error>();

export const resendCert = createAsyncAction(
  'START_RESEND_CERT',
  'RESEND_CERT_COMPLETE',
  'RESEND_CERT_FAIL'
)<string, { data: any; status: number }, Error>();

export const getLyytikey = createAsyncAction(
  'START_GET_LYYTIKEY_REQUEST',
  'GET_LYYTIKEY_REQUEST_COMPLETE',
  'GET_LYYTIKEY_REQUEST_FAIL'
)<undefined, { data: any; status: number }, Error>();

export const getAllCerts = createAsyncAction(
  'START_GET_ALL_CERTS_FETCH',
  'GET_ALL_CERTS_FETCH_COMPLETE',
  'GET_ALL_CERTS_FETCH_FAIL'
)<undefined, { data: Cert[]; status: number }, Error>();

const toggleRecipient = createAction('TOGGLE_CERT_RECIPIENT')<string>();
const toggleAllRecipients = createAction(
  'TOGGLE_ALL_CERT_RECIPIENTS'
)<undefined>();
const startNewCertSend = createAction('START_CERT_SEND')<{
  id: string;
  preview: boolean;
}>();
const changeEventSource = createAction('CHANGE_EVENT_SOURCE')<CertSource>();
const changeSendType = createAction('CHANGE_CERT_SEND_TYPE')<undefined>();
const changeEvent = createAction('CHANGE_EVENT_CERT')<{
  eventId: string;
  eventSource: CertSource;
  eventName: string;
}>();
const mapCertEditData = createAction('MAP_CERT_EDIT_DATA')<{
  mailId: string;
  certOperationId: string;
}>();
const importParticipants = createAction('IMPORT_PARTICIPANTS')<{
  data: ImportCSVData[];
  headers: boolean;
}>();
export const setErrorMessageMissingCertFields = createAction(
  'SET_ERROR_MESSAGE_CERT_MISSING_REQUIRED_FIELD'
)<string[]>();
export const changeSelectedTemplate = createAction(
  'CHANGE_SELECTED_TEMPLATE'
)<string>();
export const setCertTemplateDefaultValues = createAction(
  'SET_CERT_TEMPLATE_DEFAULT_VALUES'
)<CertTemplateDefaultValues>();

type Action =
  | ActionType<typeof getCertNew>
  | ActionType<typeof newCertSend>
  | ActionType<typeof getLyytikey>
  | ActionType<typeof getAllLyytiEventsAsync>
  | ActionType<typeof getAllEventsAsync>
  | ActionType<typeof getAllLyytiParticipantsAsync>
  | ActionType<typeof getParticipants>
  | ActionType<typeof getAllCerts>
  | ActionType<typeof resendCert>
  | ActionType<typeof uploadFileToForm>
  | ActionType<typeof importParticipants>
  | ActionType<typeof mapCertEditData>
  | ActionType<typeof setErrorMessageMissingCertFields>
  | ActionType<typeof toggleRecipient>
  | ActionType<typeof toggleAllRecipients>
  | ActionType<typeof startNewCertSend>
  | ActionType<typeof changeEventSource>
  | ActionType<typeof changeSendType>
  | ActionType<typeof changeSelectedTemplate>
  | ActionType<typeof changeEvent>;

export const certReducer: LoopReducer<CertState, Action> = (
  state: CertState = initialState,
  action: Action
): CertState | Loop<CertState> => {
  switch (action.type) {
    case getType(getCertNew.request):
      return loop(
        { ...state, initLoading: true },
        Cmd.run(certService.getCertNew, {
          successActionCreator: getCertNew.success,
          failActionCreator: getCertNew.failure,
          args: [action.payload],
        })
      );

    case getType(getCertNew.success):
      if (action.payload.status === 200) {
        if (action.payload.template) {
          const selectedTemplate = action.payload.template;
          return {
            ...state,
            selectedTemplate,
            certNew: action.payload.data,
            event: {
              selected: '',
              participants: [],
              selectedParticipants: [],
            },
            certSource: {
              ...state.certSource,
              selected: '',
            },
          };
        }
        return {
          ...state,
          certNew: action.payload.data,
          event: {
            selected: '',
            participants: [],
            selectedParticipants: [],
          },
          certSource: {
            ...state.certSource,
            selected: '',
          },
        };
      }
      return state;

    case getType(getCertNew.failure):
      return initialState;

    case getType(importParticipants):
      const dataToMap = action.payload.headers
        ? action.payload.data.slice(1)
        : action.payload.data;
      const receivers = dataToMap.map((imported) => {
        return Object.assign(
          {},
          Object.assign(
            { uuid: uuidv4() },
            ...imported.data.map((value: any, i: number) => {
              const key = action.payload.headers
                ? action.payload.data[0].data[i]
                : `Column_${i}`;
              return { [key]: value };
            })
          )
        );
      });
      return {
        ...state,
        sendMultiple: true,
        event: {
          ...state.event,
          participants: receivers,
          selectedParticipants: [],
        },
      };

    case getType(resendCert.request):
      return loop(
        state,
        Cmd.run(certService.resend, {
          successActionCreator: resendCert.success,
          failActionCreator: resendCert.failure,
          args: [action.payload],
        })
      );

    case getType(resendCert.success):
      return loop(
        { ...state, initLoading: false },
        Cmd.run(historyService.goto, {
          args: [`/certs`],
        })
      );

    case getType(resendCert.failure):
      return state;

    case getType(changeEvent):
      if (action.payload.eventSource.toLowerCase() === 'lyyti') {
        return loop(
          state,
          Cmd.action({
            type: 'START_LYYTIPARTICIPANTS_FETCH',
            payload: action.payload.eventName,
          })
        );
      }
      return loop(
        state,
        Cmd.action({
          type: 'GET_EVENT_PARTICIPANTS',
          payload: {
            id: action.payload.eventId,
            name: action.payload.eventName,
          },
        })
      );

    case getType(getAllCerts.request):
      return loop(
        state,
        Cmd.run(certService.getAllCerts, {
          successActionCreator: getAllCerts.success,
          failActionCreator: getAllCerts.failure,
        })
      );

    case getType(getAllCerts.success):
      if (action.payload.status === 200) {
        const allCerts = sortByKey(
          'certOperationId',
          true,
          action.payload.data
        );
        return {
          ...state,
          allCerts,
        };
      }
      return state;

    case getType(getAllCerts.failure):
      return {
        ...state,
        allCerts: initialState.allCerts,
      };

    case getType(uploadFileToForm.success):
      const addedPreview = state.certNew.customerCertTemplates
        .find((c) => c.templateName === state.selectedTemplate)
        .templateDataModel.templateQuestions.map((q) => {
          return q.id === action.payload.context.questionId
            ? {
                ...q,
                pictureProps: {
                  ...q.pictureProps,
                  preview: action.payload.data.fileUrl,
                },
              }
            : q;
        });

      const updated = state.certNew.customerCertTemplates.map((c) => {
        return c.templateName === state.selectedTemplate
          ? {
              ...c,
              templateDataModel: {
                ...c.templateDataModel,
                templateQuestions: addedPreview,
              },
            }
          : c;
      });
      return {
        ...state,
        certNew: { ...state.certNew, customerCertTemplates: updated },
      };

    case getType(getAllLyytiParticipantsAsync.request):
      return {
        ...state,
        event: {
          ...state.event,
          selected: action.payload,
        },
      };

    case getType(getAllLyytiParticipantsAsync.success):
      const participants = Object.keys(action.payload.data.results).map((p) =>
        mapToLyytiParticipant(action.payload.data.results[p])
      );
      const selectedParticipants = participants.map((p) => p.uuid);
      return {
        ...state,
        event: {
          ...state.event,
          participants,
          selectedParticipants,
        },
      };

    case getType(getAllLyytiParticipantsAsync.failure):
      return {
        ...state,
        event: {
          ...state.event,
          participants: initialState.event.participants,
          selectedParticipants: initialState.event.selectedParticipants,
        },
      };

    case getType(getParticipants.request):
      const name = action.payload.name ? action.payload.name : null;
      return {
        ...state,
        event: {
          ...state.event,
          selected: name,
        },
      };

    case getType(getParticipants.success):
      if (!state.event.selected) {
        return {
          ...state,
          event: {
            ...state.event,
            participants: initialState.event.participants,
            selectedParticipants: initialState.event.selectedParticipants,
          },
        };
      }

      const EventParticipants = action.payload.data.map((p) =>
        convertParticipantToListFormat(p)
      );
      const selectedEventParticipants = EventParticipants.map((p) => p.uuid);
      return {
        ...state,
        event: {
          ...state.event,
          participants: EventParticipants,
          selectedParticipants: selectedEventParticipants,
        },
      };

    case getType(getParticipants.failure):
      return {
        ...state,
        event: {
          ...state.event,
          participants: initialState.event.participants,
          selectedParticipants: initialState.event.selectedParticipants,
        },
      };

    case getType(getLyytikey.request):
      return loop(
        state,
        Cmd.run(eventService.getLyytikey, {
          successActionCreator: getLyytikey.success,
          failActionCreator: getLyytikey.failure,
        })
      );

    case getType(getLyytikey.success):
      if (action.payload.status === 200) {
        const options = state.certSource.options.includes('Lyyti')
          ? state.certSource.options
          : state.certSource.options.concat('Lyyti');
        return {
          ...state,
          certSource: {
            ...state.certSource,
            options,
          },
        };
      }
      return state;

    case getType(newCertSend.success):
      if (action.payload.status === 200) {
        //TODO: Virheviestikäsittely!
        if (action.payload.data.status.toLowerCase() === 'preview') {
          if (!action.payload.data.url) {
            return state;
          }
          window.open(action.payload.data.url);
          return state;
        }
        const { certNew, ...rest } = state;
        return loop(
          {
            ...rest,
            selectedTemplate: '',
            certSource: {
              options: startingSourceOptions,
              selected: '',
            },
            event: {
              selected: '',
              participants: [],
              selectedParticipants: [],
            },
            sendMultiple: false,
            allCerts: [],
          },
          Cmd.run(historyService.goto, {
            args: [`/certs`],
          })
        );
      }
      return state;

    case getType(newCertSend.failure):
      return state;

    case getType(startNewCertSend):
      if (state.sendMultiple && state.event.selectedParticipants.length === 0) {
        return state;
        //TODO: Tänne action jota seurataan myös error-reducerissa. Siellä laitetaan viesti "Ei valittu yhtään vastaanottajaa tms."
        //Tai sitten tämä käsitellään jo näkymän puolella, ehkä siellä parempi :p
      }
      const payload = {
        certOperationId: state.certNew.certOperationId,
        certOperationType: state.sendMultiple ? 'multiSend' : 'singleSend',
        template: state.certNew
          ? state.certNew.customerCertTemplates.find(
              (ct) => ct.templateId === action.payload.id
            )
          : emptyCustomerTemplate,
        selected: state.event.participants.filter((p) =>
          state.event.selectedParticipants.includes(p.uuid)
        ),
        preview: action.payload.preview,
      };

      return loop(
        state,
        Cmd.action({ type: 'START_CERT_SEND_REQUEST', payload })
      );

    case getType(changeSendType):
      return {
        ...state,
        sendMultiple: !state.sendMultiple,
      };

    case getType(toggleRecipient):
      return {
        ...state,
        event: {
          ...state.event,
          selectedParticipants: state.event.selectedParticipants.includes(
            action.payload
          )
            ? state.event.selectedParticipants.filter(
                (uuid) => uuid !== action.payload
              )
            : state.event.selectedParticipants.concat(action.payload),
        },
      };

    case getType(toggleAllRecipients):
      return {
        ...state,
        event: {
          ...state.event,
          selectedParticipants:
            state.event.selectedParticipants.length > 0
              ? []
              : state.event.participants.map((p) => p.uuid.toString()),
        },
      };

    case getType(changeEventSource):
      return {
        ...state,
        certSource: {
          ...state.certSource,
          selected: action.payload,
        },
        sendMultiple: false,
        event: {
          ...state.event,
          participants: [],
          selectedParticipants: [],
          selected: '',
        },
      };

    case getType(mapCertEditData):
      const cert = state.allCerts.find(
        (cert) => cert.certOperationId === action.payload.certOperationId
      );
      const mail = cert.mail.find(
        (mail) => mail.mailId === action.payload.mailId
      );
      const templateData = mail.rowData.templateData;
      const postmarkTemplateData = mail.rowData.postmarkTemplateData;
      const certNewEditData = { ...templateData, ...postmarkTemplateData };
      return loop(
        {
          ...state,
        },
        Cmd.list([
          Cmd.action({
            type: 'START_CERT_NEW_FETCH',
            payload: cert.templateName,
          }),
          Cmd.action({
            type: 'SET_FORM_VALUES',
            payload: { id: 'newCert', data: certNewEditData },
          }),
          Cmd.run(historyService.goto, {
            args: [
              `/certs/${action.payload.certOperationId}/${action.payload.mailId}/edit`,
            ],
          }),
        ])
      );

    case getType(changeSelectedTemplate):
      const template = state.certNew.customerCertTemplates.find(
        (t) => t.templateName === action.payload
      );
      if (!template) {
        return loop(
          {
            ...state,
            selectedTemplate: action.payload,
          },
          Cmd.action({ type: 'SET_CERT_TEMPLATE_DEFAULT_VALUES', payload: [] })
        );
      }

      const defaultValues = template.postmarkTemplateDataModel.postmarkTemplateQuestions
        .filter((q) => q.defaultValue)
        .map((q) => {
          return { id: q.id, defaultValue: q.defaultValue };
        })
        .concat(
          template.templateDataModel.templateQuestions
            .filter((q) => q.defaultValue)
            .map((q) => {
              return { id: q.id, defaultValue: q.defaultValue };
            })
        );

      return loop(
        {
          ...state,
          selectedTemplate: action.payload,
        },
        Cmd.action({
          type: 'SET_CERT_TEMPLATE_DEFAULT_VALUES',
          payload: defaultValues,
        })
      );

    default:
      return state;
  }
};

export function mapToLyytiParticipant(participant: any): LyytiParticipant {
  const participantObject = Object.keys(participant.answers).reduce(
    (p, key) => {
      const formattedKey = participant.answers[key].question
        ? removeHTMLTags(participant.answers[key].question)
        : key;
      const formattedAnswer = participant.answers[key].answer
        ? getAnswer(participant.answers[key].answer)
        : '';
      return {
        ...p,
        [formattedKey]: formattedAnswer,
      };
    },
    {}
  );
  return {
    ...participantObject,
    status: participant.status,
    uuid: participant.uid.toString(),
  };
}

const getAnswer = (a: any) => {
  if (typeof a === 'string') {
    return a;
  }
  const choice = Object.keys(a).map((key) =>
    a[key].choice ? a[key].choice : ''
  );
  return choice.length > 0 ? choice[0] : '';
};

const removeHTMLTags = (a: string) => {
  return a.replace(/<\/?[^>]+(>|$)/g, '');
};

export type SelectedCustomerTemplate = {
  template: CustomerCertTemplate;
  found: boolean;
};
export function getCustomerCertTemplate(
  state: AppState,
  id: string
): SelectedCustomerTemplate {
  const result =
    state.certState.certNew &&
    state.certState.certNew.customerCertTemplates.find(
      (ct) => ct.templateName === id
    )
      ? {
          template: state.certState.certNew.customerCertTemplates.find(
            (ct) => ct.templateName === id
          ),
          found: true,
        }
      : { template: emptyCustomerTemplate, found: false };
  return result;
}

export function selectCertDetails(
  state: AppState,
  id: string
): { data: CertMailTable[]; headerRow: string[] } {
  const foundCert: Cert = state.certState.allCerts.find(
    (cert) => cert.certOperationId === id
  );
  const result =
    state.certState.allCerts && foundCert
      ? foundCert.mail.map((mailDetails) => {
          const { mailTemplateModel, rowData, ...rest } = mailDetails;
          const templateData: {
            [key: string]: string | string[];
          } = Object.assign(
            {},
            ...Object.keys(mailTemplateModel).map((key) => {
              return { [`DATA__${key}`]: mailTemplateModel[key] };
            })
          );
          return { ...rest, ...templateData };
        })
      : [];
  const headerRow =
    result.length > 0
      ? Object.keys(result[0]).length > 5
        ? Object.keys(result[0]).slice(0, 5)
        : Object.keys(result[0])
      : [];
  return { data: result, headerRow };
}

export function getCertTemplateSelectOptions(state: AppState): string[] {
  const options = state.certState.certNew
    ? state.certState.certNew.customerCertTemplates.map((c) => c.templateName)
    : [];
  return options;
}

export function getCertOperationId(state: AppState): string {
  return state.certState.certNew
    ? state.certState.certNew.certOperationId.toString()
    : '';
}

export function getEventSourceOptions(state: AppState): string[] {
  return state.certState.certSource.options;
}

export type CertPageData = {
  eventSourceOptions: string[];
  selectedTemplate: string;
  certTemplateOptions: string[];
  selectedSource: CertSource;
  lyytiEvents: { name: string; id: number }[];
  events: { name: string; id: string }[];
  selectedEvent: string;
  participants: any[];
  participantHeaders: string[];
  sendMultiple: boolean;
};
export function selectCertPageData(state: AppState): CertPageData {
  const certTemplateOptions = state.certState.certNew
    ? state.certState.certNew.customerCertTemplates.map((c) => c.templateName)
    : [];
  const eventSourceOptions = state.certState.certSource.options;
  const selectedSource = state.certState.certSource.selected;
  const lyytiEvents =
    state.listEventsState.lyytiEvents.length > 0
      ? state.listEventsState.lyytiEvents.map((l) => {
          return { name: l.name, id: l.eid };
        })
      : [];
  const events =
    state.listEventsState.events.length > 0
      ? state.listEventsState.events.map((e) => {
          return { name: `${e.eventName} :: ${e.eventId}`, id: e.eventId };
        })
      : [];
  const selectedEvent = state.certState.event.selected;
  const participants = state.certState.event.participants;
  const sendMultiple = state.certState.sendMultiple;
  const selectedTemplate = state.certState.selectedTemplate;
  const participantHeaders =
    participants.length > 0
      ? Object.keys(participants[0]).length > 5
        ? Object.keys(participants[0]).slice(0, 5)
        : Object.keys(participants[0])
      : [];
  return {
    certTemplateOptions,
    eventSourceOptions,
    selectedSource,
    events,
    lyytiEvents,
    selectedEvent,
    participants,
    sendMultiple,
    selectedTemplate,
    participantHeaders,
  };
}

export function getSelectedParticipants(state: AppState): string[] {
  return state.certState.event.selectedParticipants;
}

export type SendMultipleCerts = {
  sendMultiple: boolean;
  personQuestionIds: string[];
};
export function sendMultiple(state: AppState): SendMultipleCerts {
  const selected =
    state.certState.event.selectedParticipants.length > 0
      ? state.certState.event.participants.filter((p) =>
          state.certState.event.selectedParticipants.includes(p.uuid)
        )
      : state.certState.event.participants;
  const personQuestionIds = [''].concat(
    Object.keys(
      selected.reduce((result, obj) => {
        return Object.assign(result, obj);
      }, {})
    )
  );
  return { sendMultiple: state.certState.sendMultiple, personQuestionIds };
}

export function selectAllCustomerCerts(
  state: AppState
): { data: any[]; headerRow: string[] } {
  const data = state.certState.allCerts;
  const headerRow =
    data.length > 0
      ? Object.keys(data[0]).length > 5
        ? Object.keys(data[0]).slice(0, 5)
        : Object.keys(data[0])
      : [];
  return {
    data,
    headerRow,
  };
}
