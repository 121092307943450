import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import FormSelect from '../../../components/form/FormSelect';
import { Button } from '../../../components/utils/Button';
import { Switch } from '../../../components/utils/Switch';
import { DefinedMessages } from '../../../types/types';
import { SubEventsMinimal } from '../../eventReducer';

interface Props {
  options: SubEventsMinimal[];
  onCancel: () => void;
  isPartOfGroup: boolean;
  onOk: (to: string, moveGroup: boolean) => void;
}

export const MoveParticipantModal: React.FC<Props> = ({
  options,
  onCancel,
  isPartOfGroup,
  onOk,
}) => {
  const [subEvent, selectSubEvent] = useState('');
  const [moveGroup, setMoveGroup] = useState(false);
  const { formatMessage } = useIntl();
  const buttonStyles =
    subEvent === ''
      ? 'bg-gray-500 text-brand-white cursor-default m-4 rounded w-20 h-10 border border-gray-200 shadow-lg'
      : 'bg-brand-white hover:bg-blue-200 text-brand-blue m-4 rounded w-20 h-10 border border-gray-200 shadow-lg';

  const messages: DefinedMessages = {
    button: {
      id: 'event.participants.table.selectsubevent.button',
      defaultMessage: 'Select',
    },
    cancelbutton: {
      id: 'event.participants.table.cancel.button',
      defaultMessage: 'Cancel',
    },
    selectsubevent: {
      id: 'event.participants.table.selectsubevent',
      defaultMessage: 'Select subevent',
    },
    selectdescription: {
      id: 'event.participants.table.selectsubevent.description',
      defaultMessage: 'Select where the participant will participate',
    },
    movegroup: {
      id: 'event.participants.table.selectsubevent.movegroup',
      defaultMessage: 'Move the whole group?',
    },
  };

  const changeSubEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    selectSubEvent(e.target.value);
  };

  return (
    <div className="absolute top-1/4 right-1/2 px-20 py-10 bg-brand-white rounded z-10">
      <FormSelect
        id="selectSubEvent"
        options={[''].concat(options.map((o) => o.subEventName))}
        question={formatMessage({ ...messages.selectsubevent })}
        cursiveLabel={formatMessage({ ...messages.selectdescription })}
        value={subEvent}
        handleInputChange={changeSubEvent}
      />
      {isPartOfGroup && (
        <Switch
          header={formatMessage({ ...messages.movegroup })}
          switchAction={() => setMoveGroup(!moveGroup)}
          show={moveGroup}
          id="isPartOfGroupSwitch"
        />
      )}
      <Button
        id="SelectSubEventModalOkButton"
        onOk={() => onOk(subEvent, moveGroup)}
        msg={{ ...messages.button }}
        disabled={subEvent === ''}
        styles={buttonStyles}
      />
      <Button
        id="SelectSubEventModalCancelButton"
        onOk={onCancel}
        msg={{ ...messages.cancelbutton }}
      />
    </div>
  );
};
