import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormattedMessageProps } from '../types/types';

type Props = {
  link?: string;
  message: FormattedMessageProps;
  styleStr?: string;
  divStyle?: string;
};

export const NavItem: React.FC<Props> = ({
  link,
  message,
  styleStr,
  divStyle,
  children,
}) => {
  const divStyles = divStyle ? divStyle : 'w-full ml-4';
  const styles = styleStr
    ? styleStr
    : 'flex text-lg lg:mt-0 text-white hover:text-blue-300';
  return (
    <div className={divStyles}>
      <Link to={link} className={styles}>
        {children}
        <span>
          <FormattedMessage {...message} />
        </span>
      </Link>
    </div>
  );
};
