import { DraggableItem } from './DraggableItem';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FormInputInfo } from '../../../../types/types';
import { FormattedMessage, useIntl } from 'react-intl';
import * as t from '../../translations/createRegistrationTranslations';

interface BasketProps {
  title: string;
  questions: FormInputInfo[];
  id: string;
  toggleId: string;
  last: boolean;
  handleClick: (itemId: string, basketId: string) => void;
  toggleModal: (id: string, basketId: string) => void;
  deleteBasket: (id: string) => void;
  newBasket: (id: string) => void;
}

export const DroppableBasket: React.FC<BasketProps> = ({
  title,
  questions,
  id,
  last,
  handleClick,
  toggleId,
  toggleModal,
  deleteBasket,
  newBasket,
}) => {
  const deleteBasketHide = questions.length ? 'invisible' : 'visible';
  const newBasketHide = !last ? 'invisible' : 'visible';
  const { formatMessage } = useIntl();
  const lastBasketTitle = formatMessage({ ...t.messages.lastbasket });
  const basketUnit = formatMessage({ ...t.messages.basketunit });
  const titleFormatted = last ? lastBasketTitle : `${basketUnit} ${title}`;

  return (
    <div className="px-1 text-center w-1/4 min-w-1/5">
      <div className="text-2xl text-brand-blue">{titleFormatted}</div>
      <Droppable droppableId={id}>
        {(provided, snapshot) => {
          const BasketBG = snapshot.isDraggingOver
            ? 'bg-green-200'
            : 'bg-brand-white';
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`flex-grow mx-auto px-1 border border-solid border-gray-200 rounded shadow-xl ${BasketBG}`}
            >
              {questions.map((item, index) => {
                return (
                  <div key={item.id}>
                    <DraggableItem
                      item={item.question}
                      id={item.id}
                      type={item.type}
                      group={item.group}
                      repeatable={item.repeatable ? true : false}
                      person={item.person}
                      reservation={item.reservation}
                      database={item.dbKeys ? true : false}
                      required={item.required ? true : false}
                      product={item.product ? true : false}
                      hidden={item.hide ? true : false}
                      emailKey={item.mailKeys ? true : false}
                      options={item.options}
                      index={index}
                      handleClick={handleClick}
                      basketId={id}
                    />
                  </div>
                );
              })}
              {provided.placeholder}
              <div className="flex">
                <button
                  className="bg-blue-400 hover:bg-brand-blue text-brand-white m-4 rounded w-2/3 h-10 shadow-md"
                  onClick={() => toggleModal(toggleId, id)}
                >
                  <FormattedMessage {...t.messages.addquestion} />
                </button>
                <button
                  className={`bg-green-400 hover:bg-green-500 text-brand-white m-4 rounded w-2/3 h-10 shadow-md ${newBasketHide}`}
                  onClick={() => newBasket(id)}
                >
                  <FormattedMessage {...t.messages.addbasket} />
                </button>
                <button
                  className={`bg-red-400 hover:bg-red-500 text-brand-white m-4 rounded w-2/3 h-10 shadow-md ${deleteBasketHide}`}
                  onClick={() => deleteBasket(id)}
                >
                  <FormattedMessage {...t.messages.removebasket} />
                </button>
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};
