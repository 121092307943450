import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  header: {
    id: 'registration.subevent.hidequestion.modal.header',
    defaultMessage: 'Conditionally hide the question?',
  },
  paragraph: {
    id: 'registration.subevent.hidequestion.modal.paragraph',
    defaultMessage: 'Question can be hidden/shown with following rules',
  },
  typequestion: {
    id: 'registration.subevent.hidequestion.modal.type',
    defaultMessage: 'Type',
  },
  type: {
    id: 'registration.subevent.hidequestion.modal.typelabel',
    defaultMessage: 'Question can be hidden or shown with conditions',
  },
  keyquestion: {
    id: 'registration.subevent.hidequestion.modal.key',
    defaultMessage: 'Key',
  },
  key: {
    id: 'registration.subevent.hidequestion.modal.keylabel',
    defaultMessage: 'Choose a question that sets the show/hide-action',
  },
  valuequestion: {
    id: 'registration.subevent.hidequestion.modal.value',
    defaultMessage: 'Value',
  },
  value: {
    id: 'registration.subevent.hidequestion.modal.valuelabel',
    defaultMessage: 'Write a value that sets the show/hide-action',
  },
  save: {
    id: 'registration.subevent.hidequestion.modal.save.button',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'registration.subevent.hidequestion.modal.clear.button',
    defaultMessage: 'Remove',
  },
  cancel: {
    id: 'registration.subevent.hidequestion.modal.cancel.button',
    defaultMessage: 'Cancel',
  },
}
