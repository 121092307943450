import React from "react";

interface TableDataProps {
  value: string;
  id?: string;
  additionalId?: string;
  open?: (eventId: string, subEventId?: string) => void;
  styles?: string;
}

export const TableData: React.FC<TableDataProps> = ({
  styles,
  value,
  id,
  additionalId,
  open,
}) => {
  const style = styles
    ? styles
    : "border px-8 py-4 h-24 w-1/6 cursor-pointer truncate";


  return (
    <td
      className={`${style}`}
      onClick={open ? () => open(id, additionalId) : null}
    >
      {value}
    </td>
  );
};

export default TableData;
