import React from 'react';
import { Crop } from 'react-image-crop';
import { AdditionalLabel } from '../../components/form/labels/AdditionalLabel';
import { Label } from '../../components/form/labels/Label';
import { Button } from '../../components/utils/Button';
import { Toggle } from '../../components/utils/Toggle';
import { FormattedMessageProps, TemplateQuestion } from '../../types/types';
import CropView from './CropView';

interface Props {
  q: TemplateQuestion;
  visible: boolean;
  toggleId: string;
  upload?: (
    crop: Crop,
    blob: HTMLCanvasElement,
    id: string,
    toggleId: string
  ) => void;
  toggleImageQuestion: (id: string) => void;
  imageUrl: string;
  showMsg: FormattedMessageProps;
  hideMsg: FormattedMessageProps;
}

export const FileUploadInput: React.FC<Props> = ({
  q,
  visible,
  toggleId,
  upload,
  toggleImageQuestion,
  imageUrl,
  showMsg,
  hideMsg,
}) => {
  const msg = visible ? hideMsg : showMsg;
  const buttonStyle = visible ? 'w-1/4 m-2 p-2' : 'w-1/2 m-4 p-4';
  const containerStyle = visible
    ? `absolute w-full h-full bg-white`
    : 'flex flex-col w-1/2';

  return (
    <div key={q.id} className={containerStyle}>
      <div className="flex flex-col">
        <Label question={q.question} />
        <AdditionalLabel cursiveLabel={q.description} />
        <Button
          onOk={toggleImageQuestion}
          id={toggleId}
          msg={msg}
          styles={`${buttonStyle} border border-gray-200 text-center rounded shadow-lg bg-brand-white hover:bg-blue-200 text-brand-dark_blue`}
        />
        {imageUrl ? (
          <img
            className="mx-4 my-2"
            src={imageUrl}
            alt={`${q.id}_image`}
            onClick={() => toggleImageQuestion(toggleId)}
          ></img>
        ) : null}
      </div>
      <Toggle id={toggleId}>
        <CropView
          toggleId={`TOGGLE_${q.question}_CROP_VIEW`}
          width={q.pictureProps.size[0]}
          height={q.pictureProps.size[1]}
          acceptedTypes={q.pictureProps.fileFormat}
          questionId={q.id}
          upload={upload}
        />
      </Toggle>
    </div>
  );
};
