import React from 'react';
import BreadCrumb from '../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../components/breadcrumbs/BreadRoute';
import InvoicingPage from '../components/InvoicingPage';

export const CreateInvoice: React.FC = () => {
  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={`invoicingmenu`} link={`/invoicingmenu`} />
        <BreadCrumb label={`create`} link={`/createinvoice`} lastChild={true} />
      </BreadRoute>
      <div className="flex w-11/12 max-h-11/12 min-h-11/12 mx-auto py-5 my-5">
        <InvoicingPage />
      </div>
    </div>
  );
};

export default CreateInvoice;
