import React from 'react';
import {LoginPage} from './login/LoginPage'
import {Navigation} from './navigation/Navigation'
import {useSelector} from 'react-redux'
import {Loading} from './components/utils/Loading';
import * as lState from './login/loginReducer'

export const App: React.FC = () => {

  const {loggedIn, initLoading} = useSelector(lState.selectLoginState)
  const loadingStyle = 'flex justify-center align-center items-center h-screen w-screen'

  return (
    initLoading ?
      <Loading styles={loadingStyle} />
      : loggedIn ?
        <Navigation />
        : <LoginPage />
  );
}

export default App;
