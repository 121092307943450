import { DefinedMessages } from '../../../types/types';

export const messages: DefinedMessages = {
  subeventsheader: {
    id: 'event.card.subevents',
    defaultMessage: 'Subevents',
  },
  subeventstext: {
    id: 'event.card.subevents.description',
    defaultMessage:
      'Here are all information on events subevents.{lineBreak}Every event has at least one subevent!{lineBreak}Subevents are needed to create separate registration-forms for example, different visitor groups.',
  },
  subeventslink: {
    id: 'navigation.event.subevents',
    defaultMessage: 'Subevents',
  },
  participantheader: {
    id: 'event.card.participant',
    defaultMessage: 'Participants',
  },
  participanttext: {
    id: 'event.card.participant.description',
    defaultMessage:
      'Here are all information on event participants.{lineBreak}You can list, edit or export participant data.',
  },
  participantlink: {
    id: 'navigation.event.participants',
    defaultMessage: 'Participants',
  },
  detailsheader: {
    id: 'event.card.detail',
    defaultMessage: 'Details',
  },
  detailstext: {
    id: 'event.card.detail.description',
    defaultMessage: 'Here are additional event details.',
  },
  detaillink: {
    id: 'navigation.event.details',
    defaultMessage: 'Details',
  },
};
