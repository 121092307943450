import {
  CreateEventObject,
  DraggableFormItems,
  EventState,
  EventStateResponse,
  RegisterObject,
  PostLink,
  PostLinkResponse,
  SuggestionLinkResponse,
  EventObject,
  EventParticipant,
  TableParticipant,
  EventSettingsResponse,
  DbKeyValues,
  MailKeyValues,
  DbKey,
  MailKey,
  FormInputInfo,
  SubEventCreateResponse,
  RegistrationInfo,
  PageMessages,
  SubEventCreateState,
  SubEventDetailsResponse,
  EventDetailsResponse,
  DefaultFormResponse,
} from '../types/types';
import { LoopReducer, loop, Loop, Cmd } from 'redux-loop';
import {
  getType,
  createAsyncAction,
  ActionType,
  createAction,
} from 'typesafe-actions';
import eventService from '../services/events';
import formService from '../services/forms';
import { historyService } from '../services/historyService';
import {
  arrayToObject,
  getParticipantTableStartingHeaders,
} from '../utils/functions';
import storeService from '../services/storage';
import * as listState from './listEvents/listEventsReducer';
import { AppState } from '../reducers/combineReducer';
import { ImportTarget } from './eventRegistration/components/ImportModal';
import { getPreviewFormItems } from '../reducers/formReducer';

const initialState: EventState = {
  customerId: '',
  eventId: '',
  eventName: '',
  eventState: '',
  subEventCreateState: [
    {
      eventPage: false,
      selectRegistration: true,
      createRegistration: false,
    },
  ],
  registrationState: {
    type: '',
    registrationQuestions: {
      baskets: {},
      items: [],
    },
    subEventInfo: {
      subEventMaxRegistrants: '',
      subEventStartTime: null,
      subEventEndTime: null,
      subEventRegistrationEnd: null,
      subEventRegistrationStart: null,
      subEventUpdateEnd: null,
      subEventCancelEnd: null,
      subEventName: null,
      subEventType: null,
      subEventSupportEmail: null,
      subEventSupportInstruction: null,
      subEventSupportPhone: null,
      subEventAllowCancel: 0,
      subEventAllowModify: 0,
      subEventMaxGroupSize: 1,
      subEventAllowedQue: 0,
      subEventAllowGroupEdit: 0,
    },
    pageStyling: {
      styleId: null,
      backgroundColor: null,
      logoUrl: null,
    },
    pageMessages: {
      pageId: null,
      pageHeader: '',
      pageDescription: '',
      PageThankYouHeader: 'Thank you',
      pageThankYouText: 'Thank you for registering!',
      pageRegistrationFullHeader: 'Full',
      PageRegistrationFullText:
        'Unfortunately registration for this event is full',
      PageRegistrationNotYetOpenHeader: 'Registration has not begun',
      PageRegistrationNotYetOpenText:
        'Registration for this event has not yet began',
      PageRegistrationEndedHeader: 'Ended',
      PageRegistrationEndedText:
        'Unfortunately registration for this event has already ended',
    },
    link: {
      link: null,
      linkPrefix: null,
      linkSuggestion: null,
      linkId: null,
      linkUrl: null,
    },
    dbKeys: {
      dbKeys: [],
      mailKeys: [],
    },
  },
  eventDetails: {
    eventName: '',
    eventOrganiser: '',
    eventPlace: '',
    eventStreetAddress: '',
    eventZip: '',
    eventCity: '',
    eventCountry: '',
    eventStartDate: '',
    eventEndDate: '',
    eventStartDateTime: '',
    eventEndDateTime: '',
    eventWebPage: '',
    eventDescription: '',
    eventInfoText: '',
    eventHelpText: '',
    eventSupportEmail: '',
    eventSupportInstruction: '',
    eventSupportPhone: '',
  },
  eventPage: {
    subEventFilter: null,
  },
};

export const getNewParticipantForm = createAsyncAction(
  'START_NEW_PARTICIPANT_FORM_FETCH',
  'NEW_PARTICIPANT_FORM_FETCH_COMPLETE',
  'NEW_PARTICIPANT_FORM_FETCH_FAIL'
)<string, { data: SubEventDetailsResponse; status: number }, Error>();

export const getDefaultForm = createAsyncAction(
  'START_DEFAULT_FORM_FETCH',
  'DEFAULT_FORM_FETCH_COMPLETE',
  'DEFAULT_FORM_FETCH_FAIL'
)<
  { form: string; language: string },
  { data: DefaultFormResponse; status: number },
  Error
>();

export const getDbKeys = createAsyncAction(
  'START_DB_KEYS_FETCH',
  'DB_KEYS_FETCH_COMPLETE',
  'DB_KEYS_FETCH_FAIL'
)<undefined, { data: EventSettingsResponse; status: number }, Error>();

export const getEventAsync = createAsyncAction(
  'START_EVENTSTATE_FETCH',
  'EVENTSTATE_FETCH_COMPLETE',
  'EVENTSTATE_FETCH_FAIL'
)<string, { data: EventStateResponse; status: number }, Error>();

export const sendRegistrationAsync = createAsyncAction(
  'START_REGISTRATION_SELECT_REQUEST',
  'REGISTRATION_REQUEST_COMPLETE',
  'REGISTRATION_REQUEST_FAIL'
)<
  RegisterObject,
  { data: any; status: number; url: string; subEventId: string },
  Error
>();

export const createEventAsync = createAsyncAction(
  'START_EVENT_CREATE_REQUEST',
  'CREATE_EVENT_REQUEST_COMPLETE',
  'CREATE_EVENT_REQUEST_FAIL'
)<CreateEventObject, { data: any; status: number }, Error>();

export const createSubEventAsync = createAsyncAction(
  'START_CREATE_SUBEVENT_REQUEST',
  'CREATE_SUBEVENT_REQUEST_COMPLETE',
  'CREATE_SUBEVENT_REQUEST_FAIL'
)<string, { data: SubEventCreateResponse; status: number }, Error>();

export const saveRegistrationQuestions = createAsyncAction(
  'START_SAVE_REGISTRATION_QUESTIONS_REQUEST',
  'SAVE_REGISTRATION_QUESTIONS_COMPLETE',
  'SAVE_REGISTRATION_QUESTIONS_FAIL'
)<
  {
    data: FormInputInfo[];
    subEventId: string;
    subEventFormId: string;
    subEventState: string;
  },
  { data: any; status: number },
  Error
>();

export const getEventDetails = createAsyncAction(
  'START_GET_EVENT_DETAILS_REQUEST',
  'GET_EVENT_DETAILS_COMPLETE',
  'GET_EVENT_DETAILS_FAIL'
)<string, { data: EventDetailsResponse; status: number }, Error>();

export const postLinkForm = createAsyncAction(
  'START_POST_LINK_FORM',
  'POST_LINK_FORM_COMPLETE',
  'POST_LINK_FORM_FAIL'
)<PostLink, { data: PostLinkResponse; status: number }, Error>();

export const getLinkSuggestion = createAsyncAction(
  'START_GET_LINK_SUGGESTION',
  'GET_LINK_SUGGESTION_COMPLETE',
  'GET_LINK_SUGGESTION_FAIL'
)<undefined, { data: SuggestionLinkResponse; status: number }, Error>();

export const getParticipantDetails = createAsyncAction(
  'START_PARTICIPANT_DETAILS_FETCH',
  'PARTICIPANT_DETAILS_FETCH_COMPLETE',
  'PARTICIPANT_DETAILS_FETCH_FAIL'
)<
  { subEventId: string; uuid: string },
  { data: any; status: number; eventId: string },
  Error
>();

export const getSubEventDetails = createAsyncAction(
  'START_SUBEVENT_DETAILS_FETCH',
  'SUBEVENT_DETAILS_COMPLETE',
  'SUBEVENT_DETAILS_FAIL'
)<string, { data: SubEventDetailsResponse; status: number }, Error>();

export const importRegistrationState = createAsyncAction(
  'START_IMPORT_REGISTRATIONSTATE',
  'IMPORT_REGISTRATIONSTATE_COMPLETE',
  'IMPORT_REGISTRATIONSTATE_FAIL'
)<
  { subEventId: string; keys: Record<ImportTarget, boolean> },
  {
    data: SubEventDetailsResponse;
    status: number;
    keys: Record<ImportTarget, boolean>;
  },
  Error
>();

const fetchSubEventDataInOrder = createAction('FETCH_SUBEVENTDATA_IN_ORDER')<{
  eventId: string;
  subEventId: string;
}>();

const fetchEventDataInOrder = createAction('FETCH_EVENTDATA_IN_ORDER')<{
  eventId: string;
}>();

const changeCreateState = createAction('CHANGECREATESTATE')<{
  key: string;
  value: boolean;
}>();
const changeRegistrationInfo = createAction('CHANGEREGISTRATIONINFO')<{
  nestedKey: string;
  key: string;
  value: string;
}>();
const changeRegistrationInfoDate = createAction('CHANGEREGISTRATIONINFODATE')<{
  key: string;
  value: Date;
}>();
const updateRegistrationInfo = createAction('UPDATEREGISTRATIONINFO')<{
  key: string;
  value: string;
}>();
const setRegistrationQuestions = createAction(
  'SETREGISTRATIONQUESTIONS'
)<DraggableFormItems>();
const setEventDetails = createAction('SET_EVENT_DETAILS')<{
  details: EventDetailsResponse;
  subEventId?: string;
}>();
const changePageStyling = createAction('CHANGEPAGESTYLING')<{
  nestedKey: string;
  key: string;
  value: string;
}>();

type Action =
  | ActionType<typeof getEventAsync>
  | ActionType<typeof sendRegistrationAsync>
  | ActionType<typeof getDefaultForm>
  | ActionType<typeof getNewParticipantForm>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof createSubEventAsync>
  | ActionType<typeof saveRegistrationQuestions>
  | ActionType<typeof getEventDetails>
  | ActionType<typeof postLinkForm>
  | ActionType<typeof getLinkSuggestion>
  | ActionType<typeof getDbKeys>
  | ActionType<typeof fetchEventDataInOrder>
  | ActionType<typeof fetchSubEventDataInOrder>
  | ActionType<typeof getParticipantDetails>
  | ActionType<typeof getSubEventDetails>
  | ActionType<typeof changeCreateState>
  | ActionType<typeof changeRegistrationInfo>
  | ActionType<typeof updateRegistrationInfo>
  | ActionType<typeof changeRegistrationInfoDate>
  | ActionType<typeof setRegistrationQuestions>
  | ActionType<typeof setEventDetails>
  | ActionType<typeof importRegistrationState>
  | ActionType<typeof changePageStyling>;

export const eventReducer: LoopReducer<EventState, Action> = (
  state = initialState,
  action: Action
): EventState | Loop<EventState> => {
  switch (action.type) {
    case getType(fetchEventDataInOrder):
      return loop(
        state,
        Cmd.list([
          Cmd.action({
            type: 'START_EVENTSTATE_FETCH',
            payload: action.payload.eventId,
          }),
          Cmd.action({
            type: 'START_EVENTS_FETCH',
            payload: { date: '0', limit: '100' },
          }),
          Cmd.action({
            type: 'START_GET_EVENT_DETAILS_REQUEST',
            payload: action.payload.eventId,
          }),
          Cmd.action({
            type: 'GET_EVENT_PARTICIPANTS',
            payload: { id: action.payload.eventId },
          }),
        ])
      );

    case getType(fetchSubEventDataInOrder):
      if (state.eventId === '') {
        return loop(
          state,
          Cmd.list([
            Cmd.action({
              type: 'START_EVENTS_FETCH',
              payload: { date: '0', limit: '100' },
            }),
            Cmd.action({
              type: 'START_SUBEVENT_DETAILS_FETCH',
              payload: action.payload.subEventId,
            }),
          ])
        );
      }
      return loop(
        state,
        Cmd.action({
          type: 'START_SUBEVENT_DETAILS_FETCH',
          payload: action.payload.subEventId,
        })
      );
    // get one event
    case getType(getEventAsync.request):
      return loop(
        state,
        Cmd.run(eventService.getEventState, {
          successActionCreator: getEventAsync.success,
          failActionCreator: getEventAsync.failure,
          args: [action.payload],
        })
      );

    case getType(getEventAsync.success):
      if (action.payload.status === 200) {
        const subEventCreateState = action.payload.data.createState.map((d) => {
          return {
            ...d.subEventCreateState,
            subEventId: d.subEventId,
            subEventFormId: d.subEventFormId,
          };
        });
        return {
          ...state,
          subEventCreateState,
          customerId: action.payload.data.createState[0].customerId,
          eventId: action.payload.data.createState[0].eventId,
          eventName: action.payload.data.createState[0].eventName,
        };
      }
      return {
        ...state,
        subEventCreateState: initialState.subEventCreateState,
        customerId: initialState.customerId,
        eventId: initialState.eventId,
        eventName: initialState.eventName,
        eventState: initialState.eventState,
        registrationState: initialState.registrationState,
      };

    case getType(getEventAsync.failure):
      return loop(
        {
          ...state,
          subEventCreateState: initialState.subEventCreateState,
          customerId: initialState.customerId,
          eventId: initialState.eventId,
          eventName: initialState.eventName,
          eventState: initialState.eventState,
          registrationState: initialState.registrationState,
        },
        Cmd.run(historyService.goto, {
          args: ['/notfound'],
        })
      );

    //get draggableForm
    case getType(getDefaultForm.request):
      return loop(
        state,
        Cmd.run(formService.getDefaultForm, {
          successActionCreator: getDefaultForm.success,
          failActionCreator: getDefaultForm.failure,
        })
      );

    case getType(getDefaultForm.success):
      if (action.payload.status === 200) {
        return loop(
          {
            ...state,
            registrationState: {
              ...initialState.registrationState,
              subEventInfo: {
                ...initialState.registrationState.subEventInfo,
                subEventName: state.eventDetails.eventName,
                subEventStartTime: `${state.eventDetails.eventStartDate}T${state.eventDetails.eventStartDateTime}`,
                subEventEndTime: `${state.eventDetails.eventEndDate}T${state.eventDetails.eventEndDateTime}`,
                subEventSupportEmail: state.eventDetails.eventSupportEmail,
                subEventSupportPhone: state.eventDetails.eventSupportPhone,
                subEventSupportInstruction:
                  state.eventDetails.eventSupportInstruction,
              },
              registrationQuestions: {
                baskets: action.payload.data.defaultCustomerForm.baskets,
                items: action.payload.data.defaultCustomerForm.inputs,
              },
              pageMessages: {
                ...initialState.registrationState.pageMessages,
                pageHeader: state.eventDetails.eventName,
                pageDescription: state.eventDetails.eventDescription,
              },
            },
          },
          Cmd.action({ type: 'START_DB_KEYS_FETCH' })
        );
      }
      return state;

    case getType(getDbKeys.request):
      return loop(
        state,
        Cmd.run(eventService.getDbKeys, {
          successActionCreator: getDbKeys.success,
          failActionCreator: getDbKeys.failure,
        })
      );

    case getType(getDbKeys.success):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          dbKeys: {
            mailKeys: action.payload.data.mailKeys,
            dbKeys: action.payload.data.dbKeys,
          },
        },
      };

    case getType(importRegistrationState.request):
      return loop(
        state,
        Cmd.run(eventService.getSubEventDetailsImport, {
          successActionCreator: importRegistrationState.success,
          failActionCreator: importRegistrationState.failure,
          args: [action.payload.subEventId, action.payload.keys],
        })
      );

    case getType(importRegistrationState.success):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          registrationQuestions: action.payload.keys.questions
            ? action.payload.data.registrationState.registrationQuestions
            : state.registrationState.registrationQuestions,
          pageStyling: action.payload.keys.styling
            ? action.payload.data.registrationState.pageStyling
            : state.registrationState.pageStyling,
          subEventInfo: action.payload.keys.basic
            ? action.payload.data.registrationState.subEventInfo
            : state.registrationState.subEventInfo,
          pageMessages: action.payload.keys.styling
            ? action.payload.data.registrationState.pageMessages
            : state.registrationState.pageMessages,
        },
      };

    case getType(getSubEventDetails.request):
      return loop(
        state,
        Cmd.run(eventService.getSubEventDetails, {
          successActionCreator: getSubEventDetails.success,
          failActionCreator: getSubEventDetails.failure,
          args: [action.payload],
        })
      );

    case getType(getSubEventDetails.success):
      if (action.payload.data.registrationState) {
        return {
          ...state,
          registrationState: action.payload.data.registrationState,
        };
      }
      return loop(
        {
          ...state,
          registrationState: initialState.registrationState,
        },
        Cmd.action({
          type: 'START_DEFAULT_FORM_FETCH',
          payload: undefined,
        })
      );

    case getType(getSubEventDetails.failure):
      return loop(
        {
          ...state,
          registrationState: initialState.registrationState,
        },
        Cmd.action({
          type: 'START_DEFAULT_FORM_FETCH',
          payload: undefined,
        })
      );

    //send registration
    case getType(sendRegistrationAsync.request):
      const url = action.payload.registerName.startsWith('CM-Registration')
        ? `/events/${action.payload.eventId}/subevents/${action.payload.subEventId}/registration`
        : `/events/${action.payload.eventId}`;
      return loop(
        state,
        Cmd.run(eventService.saveRegister, {
          successActionCreator: sendRegistrationAsync.success,
          failActionCreator: sendRegistrationAsync.failure,
          args: [action.payload, url],
        })
      );

    case getType(sendRegistrationAsync.failure):
      return state;

    case getType(sendRegistrationAsync.success):
      const updatedSubEventCreateState = state.subEventCreateState.map((s) => {
        return s.subEventId === action.payload.subEventId
          ? {
              subEventId: action.payload.subEventId,
              eventPage: false,
              createRegistration: true,
              selectRegistration: false,
            }
          : s;
      });
      return loop(
        {
          ...state,
          subEventCreateState: updatedSubEventCreateState,
        },
        Cmd.run(historyService.goto, {
          args: [action.payload.url],
        })
      );

    //create event
    case getType(createEventAsync.request):
      const eventStartArr = action.payload.eventStartDate.split('T');
      const eventEndArr = action.payload.eventEndDate.split('T');
      const eventDetails = {
        ...action.payload,
        eventState: eventStartArr[0],
        eventEndDate: eventStartArr[1],
        eventEndDateTime: eventEndArr[0],
        eventStartDateTime: eventEndArr[1],
      };
      return loop(
        {
          ...state,
          eventDetails,
        },
        Cmd.run(eventService.create, {
          successActionCreator: createEventAsync.success,
          failActionCreator: createEventAsync.failure,
          args: [action.payload],
        })
      );

    case getType(createEventAsync.success):
      if (action.payload.status === 200) {
        return loop(
          {
            ...state,
            customerId: action.payload.data.customerId,
            subEventId: action.payload.data.subEventId,
            eventId: action.payload.data.eventId,
            eventName: action.payload.data.eventName,
            eventState: action.payload.data.eventState,
            subEventCreateState: action.payload.data.subEventCreateState,
            subEventFormId: action.payload.data.subEventFormId,
            subEventName: action.payload.data.subEventName,
            subEventState: action.payload.data.subEventState,
          },
          Cmd.run(historyService.goto, {
            args: [`events/${action.payload.data.eventId}`],
          })
        );
      }
      return loop(state, Cmd.run(createEventAsync.failure, {}));

    //createSubEvent
    case getType(createSubEventAsync.request):
      return loop(
        state,
        Cmd.run(eventService.createSubEvent, {
          successActionCreator: createSubEventAsync.success,
          failActionCreator: createSubEventAsync.failure,
          args: [action.payload],
        })
      );

    case getType(createSubEventAsync.success):
      if (action.payload.status === 200) {
        return loop(
          {
            ...state,
            subEventId: action.payload.data.subEventId,
            subEventFormId: action.payload.data.subEventFormId,
            subEventState: action.payload.data.subEventState,
            subEventName: action.payload.data.subEventName,
          },
          Cmd.list([
            Cmd.action({
              type: 'START_EVENTS_FETCH',
              payload: { date: '0', limit: '0' },
            }),
            Cmd.run(historyService.goto, {
              args: [
                `/events/${action.payload.data.eventId}/subevents/${action.payload.data.subEventId}`,
              ],
            }),
          ])
        );
      }
      return state;

    //get event details
    case getType(getEventDetails.request):
      return loop(
        state,
        Cmd.run(eventService.getEventDetails, {
          successActionCreator: getEventDetails.success,
          failActionCreator: getEventDetails.failure,
          args: [action.payload],
        })
      );

    case getType(getEventDetails.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          eventDetails: action.payload.data,
        };
      }
      return {
        ...state,
        eventDetails: initialState.eventDetails,
      };

    case getType(getEventDetails.failure):
      return {
        ...state,
        eventDetails: initialState.eventDetails,
      };

    case getType(saveRegistrationQuestions.request):
      const formLanguage = storeService.getString('language') || 'fi';
      const object = {
        registrationQuestions: action.payload.data,
        formLanguage,
        formPrivacy: 'customer',
        registrationState: state.registrationState,
        subEventCreateState: state.subEventCreateState.find(
          (s) => s.subEventId === action.payload.subEventId
        ),
        subEventFormId: action.payload.subEventFormId,
        subEventId: action.payload.subEventId,
        subEventState: action.payload.subEventState,
      };
      return loop(
        state,
        Cmd.run(eventService.saveEventRegistrationQuestions, {
          successActionCreator: saveRegistrationQuestions.success,
          failActionCreator: saveRegistrationQuestions.failure,
          args: [object],
        })
      );

    case getType(saveRegistrationQuestions.success):
      if (action.payload.status === 200) {
        const updatedEventCreateState = state.subEventCreateState.map((s) => {
          return s.subEventId === action.payload.data.subEventId
            ? { ...s, subEventFormId: action.payload.data.subEventFormId }
            : s;
        });
        return loop(
          {
            ...state,
            subEventCreateState: updatedEventCreateState,
            registrationState: {
              ...state.registrationState,
              pageMessages: {
                ...state.registrationState.pageMessages,
                pageId: action.payload.data.pageId,
              },
              pageStyling: {
                ...state.registrationState.pageStyling,
                styleId: action.payload.data.styleId,
              },
              link: {
                ...state.registrationState.link,
                linkId: action.payload.data.linkId,
                linkPrefix: action.payload.data.linkPrefix,
                linkSuggestion: action.payload.data.linkSuggestion,
              },
            },
          },
          Cmd.list([
            Cmd.action({
              type: 'START_EVENTS_FETCH',
              payload: { date: '0', limit: '0' },
            }),
            Cmd.action({
              type: 'START_EVENTSTATE_FETCH',
              payload: state.eventId,
            }),
            Cmd.run(historyService.goto, {
              args: [
                `/events/${state.eventId}/subevents/${action.payload.data.subEventId}`,
              ],
            }),
          ])
        );
      }
      return state;

    case getType(getParticipantDetails.request):
      return loop(
        state,
        Cmd.run(eventService.getParticipantDetails, {
          successActionCreator: getParticipantDetails.success,
          failActionCreator: getParticipantDetails.failure,
          args: [action.payload.subEventId, action.payload.uuid, state.eventId],
        })
      );
    case getType(getParticipantDetails.failure):
      return {
        ...state,
        registrationState: initialState.registrationState,
      };

    case getType(getParticipantDetails.success):
      return {
        ...state,
        registrationState: action.payload.data.registrationState,
      };

    //post linkform
    case getType(postLinkForm.request):
      return loop(
        state,
        Cmd.run(eventService.postFormLink, {
          successActionCreator: postLinkForm.success,
          failActionCreator: postLinkForm.failure,
          args: [action.payload],
        })
      );

    case getType(postLinkForm.success):
      if (action.payload.status === 200) {
        return loop(
          {
            ...state,
            subEventState: action.payload.data.subEventState,
            registrationState: {
              ...state.registrationState,
              link: {
                ...state.registrationState.link,
                link: action.payload.data.subEventLink,
              },
            },
          },
          Cmd.action({
            type: 'START_EVENTS_FETCH',
            payload: { date: '0', limit: '0' },
          })
        );
      }
      return state;

    //get link suggestion
    case getType(getLinkSuggestion.request):
      return loop(
        state,
        Cmd.run(eventService.getLinkSuggestion, {
          successActionCreator: getLinkSuggestion.success,
          failActionCreator: getLinkSuggestion.failure,
        })
      );

    case getType(getLinkSuggestion.success):
      if (action.payload.status === 200) {
        return {
          ...state,
          registrationState: {
            ...state.registrationState,
            link: {
              ...state.registrationState.link,
              linkId: action.payload.data.linkId,
              linkPrefix: action.payload.data.linkPrefix,
              linkSuggestion: action.payload.data.linkSuggestion,
              linkUrl: action.payload.data.eregUrl,
            },
          },
        };
      }
      return state;

    //synchronous changes in state
    case getType(changeCreateState):
      return {
        ...state,
        subEventCreateState: {
          ...state.subEventCreateState,
          [action.payload.key]: action.payload.value,
        },
      };

    case getType(changeRegistrationInfo):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          subEventInfo: {
            ...state.registrationState.subEventInfo,
            [action.payload.nestedKey]: {
              ...state.registrationState.subEventInfo[action.payload.nestedKey],
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };

    case getType(changePageStyling):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          [action.payload.key]: {
            ...state.registrationState[action.payload.key],
            [action.payload.nestedKey]: action.payload.value,
          },
        },
      };

    case getType(updateRegistrationInfo):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          subEventInfo: {
            ...state.registrationState.subEventInfo,
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case getType(changeRegistrationInfoDate):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          subEventInfo: {
            ...state.registrationState.subEventInfo,
            [action.payload.key]: action.payload.value
              ? action.payload.value.toISOString()
              : null,
          },
        },
      };

    case getType(setRegistrationQuestions):
      return {
        ...state,
        registrationState: {
          ...state.registrationState,
          registrationQuestions: action.payload,
        },
      };

    case getType(setEventDetails):
      return {
        ...state,
        eventDetails: action.payload.details,
        eventPage: {
          ...state.eventPage,
          subEventFilter: action.payload.subEventId
            ? action.payload.subEventId
            : null,
        },
      };

    default:
      return state;
  }
};

export function getEventState(state: AppState): EventState {
  return state.eventState;
}

export function selectSubEventCreateState(
  state: AppState,
  subEventId: string
): SubEventCreateState {
  const result = state.eventState.subEventCreateState.find(
    (s) => s.subEventId === subEventId
  );
  return result ?? initialState.subEventCreateState[0];
}

export function getRegistrationStylingData(
  state: AppState
): { backgroundColor: string; pageHeader: string } {
  return {
    backgroundColor:
      state.eventState.registrationState.pageStyling.backgroundColor,
    pageHeader: state.eventState.registrationState.pageMessages.pageHeader,
  };
}

export type RegistrationQuestionsData = {
  state: DraggableFormItems;
  dbKeys: DbKeyValues[];
  mailKeys: MailKeyValues[];
  options: { id: string; name: string }[];
  amountOptions: { id: string; name: string }[];
  itemsTable: { [key: string]: any };
};
export function getRegistrationFormItems(
  state: AppState
): RegistrationQuestionsData {
  const formItems = state.eventState.registrationState.registrationQuestions;
  const dbKeys = state.eventState.registrationState.dbKeys
    ? state.eventState.registrationState.dbKeys.dbKeys
    : [];
  const mailKeys = state.eventState.registrationState.dbKeys
    ? state.eventState.registrationState.dbKeys.mailKeys
    : [];

  const items = getPreviewFormItems(state);
  const options = items.map((item) => {
    return { id: item.id, name: item.question };
  });
  const amountOptions = items
    .filter((item) => item.type === 'number')
    .map((item) => {
      return {
        id: item.id,
        name: item.question,
      };
    });
  const itemsTable = arrayToObject(
    state.eventState.registrationState.registrationQuestions.items,
    'id'
  );
  return {
    state: formItems,
    dbKeys,
    options,
    amountOptions,
    itemsTable,
    mailKeys,
  };
}

export function selectLinkPageData(
  state: AppState
): {
  linkId: string;
  linkPrefix: string;
  linkSuggestion: string;
  link: string;
} {
  return {
    linkId: state.eventState.registrationState.link.linkId,
    linkPrefix: state.eventState.registrationState.link.linkPrefix,
    linkSuggestion: state.eventState.registrationState.link.linkSuggestion,
    link: state.eventState.registrationState.link.link,
  };
}

export type EventParticipantTableData = {
  table: TableParticipant[];
  startingTableHeaders: string[];
  participants: EventParticipant[];
  event: EventObject;
  options: SubEventsMinimal[];
};
export function selectEventParticipantTableData(
  state: AppState,
  eventId: string
): EventParticipantTableData {
  const mainEvent = state.listEventsState.events.find(
    (e) => e.eventId === eventId
  );
  const empty = { eventId: null, subEvents: 0 } as EventObject;
  const table = state.participantState.participantsFormatted;
  const participants = state.participantState.participants.filter(
    (p) => p.partypantsAnswer !== null && p.partypantsAnswerCommon !== null
  );
  const event = mainEvent ? mainEvent : empty;
  const startingTableHeaders =
    table.length > 0
      ? getParticipantTableStartingHeaders(participants, table)
      : [];
  const options = selectSubEventForNewParticipant(state, eventId);
  return {
    table,
    participants,
    event,
    startingTableHeaders,
    options,
  };
}

export type ValidationDbKey = {
  name: string;
  additionalMessages: string[];
  key: string;
};

export type ValidationMessage = {
  message: string;
  variables?: {
    [key: string]: string;
  };
  defaultMessage: string;
};

export type ValidationError = {
  error: string;
  additionalMessages: ValidationMessage[];
};

export type RegistrationValidationData = {
  groupRegistration: boolean;
  missingRequiredDatabaseFields: ValidationDbKey[];
  missingRequiredEmailFields: MailKeyValues[];
  missingReservationQuestion: boolean;
  hideValidationErrors: ValidationError[];
  repeatableValidationErrors: ValidationError[];
  dbFieldValidationErrors: ValidationError[];
  mailKeysValidationErrors: ValidationError[];
  missingInformation: ValidationMessage[];
  errors: number;
};
export function selectRegistrationValidationData(
  state: AppState
): RegistrationValidationData {
  const items = state.formState.items.previewRegistrationForm
    ? state.formState.items.previewRegistrationForm
    : [];
  const groupRegistration =
    Number(
      state.eventState.registrationState.subEventInfo.subEventMaxGroupSize
    ) === 1 ||
    Number(
      state.eventState.registrationState.subEventInfo.subEventMaxGroupSize
    ) === 0
      ? false
      : true;
  const requiredDbFields = state.eventState.registrationState.dbKeys.dbKeys.filter(
    (key) => key.required
  );
  const questionsWithDbFields = items
    .filter((item) => item.dbKeys)
    .map((item) => item.dbKeys);
  const requiredEmailFields = state.eventState.registrationState.dbKeys.mailKeys.filter(
    (key) => key.groupRequired
  );
  const questionsWithEmailFields = items
    .filter((item) => item.mailKeys)
    .map((item) => item.mailKeys);
  const missingRequiredDatabaseFields = findMissingRequiredFields(
    questionsWithDbFields,
    requiredDbFields,
    groupRegistration
  );
  const missingRequiredEmailFields = groupRegistration
    ? findMissingRequiredEmailFields(
        questionsWithEmailFields,
        requiredEmailFields
      )
    : [];
  const missingReservationQuestion = groupRegistration
    ? !findAndValidateReservationQuestion(items)
    : false;
  const hideQuestions = items.filter((item) => item.hide);
  const hideValidationErrors = validateHideQuestions(items, hideQuestions);
  const repeatQuestions = items.filter((item) => item.repeatable);
  const repeatableValidationErrors = validateRepeatQuestions(
    items,
    repeatQuestions
  );
  const mailKeysQuestions = items.filter((item) => item.mailKeys);
  const mailKeysValidationErrors = validateMailkeysFieldQuestions(
    mailKeysQuestions
  );
  const dbFieldQuestions = items.filter((item) => item.dbKeys);
  const dbFieldValidationErrors = validateDbFieldQuestions(dbFieldQuestions);
  const info = state.eventState.registrationState.subEventInfo;
  const missingInformation = checkMissingInfo(info);
  const errors = countValidationErrors(
    missingRequiredDatabaseFields,
    missingRequiredEmailFields,
    missingReservationQuestion,
    missingInformation,
    hideValidationErrors.concat(
      repeatableValidationErrors,
      dbFieldValidationErrors,
      mailKeysValidationErrors
    )
  );
  return {
    groupRegistration,
    missingRequiredDatabaseFields,
    missingRequiredEmailFields,
    missingReservationQuestion,
    hideValidationErrors,
    repeatableValidationErrors,
    dbFieldValidationErrors,
    mailKeysValidationErrors,
    missingInformation,
    errors,
  };
}

const checkMissingInfo = (info: RegistrationInfo): ValidationMessage[] => {
  const missingStartTime = checkMissing(info.subEventStartTime)
    ? [
        {
          message:
            'registration.subevent.validation.missinginformation.eventstart',
          defaultMessage: 'Missing event start time',
        },
      ]
    : [];
  const missingEndTime = checkMissing(info.subEventEndTime)
    ? [
        {
          message:
            'registration.subevent.validation.missinginformation.eventend',
          defaultMessage: 'Missing event end time',
        },
      ]
    : [];
  const missingRegisterStart = checkMissing(info.subEventRegistrationStart)
    ? [
        {
          message:
            'registration.subevent.validation.missinginformation.registerstart',
          defaultMessage: 'Missing register start time',
        },
      ]
    : [];
  const missingRegisterEnd = checkMissing(info.subEventRegistrationEnd)
    ? [
        {
          message:
            'registration.subevent.validation.missinginformation.registerend',
          defaultMessage: 'Missing register end time',
        },
      ]
    : [];
  const missingCancelEnd =
    checkMissing(info.subEventCancelEnd) &&
    Number(info.subEventAllowCancel) === 1
      ? [
          {
            message:
              'registration.subevent.validation.missinginformation.cancelend',
            defaultMessage: 'Missing cancel end time',
          },
        ]
      : [];
  const missingEditEnd =
    checkMissing(info.subEventUpdateEnd) &&
    Number(info.subEventAllowModify) === 1
      ? [
          {
            message:
              'registration.subevent.validation.missinginformation.modifyend',
            defaultMessage: 'Missing modify end time',
          },
        ]
      : [];
  const missingSupportEmail = checkMissing(info.subEventSupportEmail)
    ? [
        {
          message:
            'registration.subevent.validation.missinginformation.supportemail',
          defaultMessage: 'Missing support email',
        },
      ]
    : [];
  return missingStartTime.concat(
    missingEndTime,
    missingRegisterStart,
    missingRegisterEnd,
    missingCancelEnd,
    missingEditEnd,
    missingSupportEmail
  );
};

const checkMissing = (str: string | null) => {
  return !(str && str.length > 0);
};

function countValidationErrors(
  requiredDbFields: ValidationDbKey[],
  missingEmailFields: MailKeyValues[],
  missingReservationQuestion: boolean,
  missingInfo: ValidationMessage[],
  otherErrors: ValidationError[]
): number {
  let errorCounter = missingReservationQuestion ? 1 : 0;
  errorCounter += requiredDbFields.length;
  errorCounter += missingEmailFields.length;
  errorCounter += missingInfo.length;
  errorCounter += otherErrors.length;
  return errorCounter;
}

function validateMailkeysFieldQuestions(mailkeyQuestions: FormInputInfo[]) {
  const result = mailkeyQuestions.map((mailQuestion) =>
    checkMailQuestion(mailQuestion)
  );
  return result.filter((item) => item.error !== null);
}

function checkMailQuestion(question: FormInputInfo) {
  const isAPersonQuestion = question.person ? question.id : false;
  const isNotPersonError = isAPersonQuestion
    ? [
        {
          message: 'registration.subevent.validation.mailkeysfield.person',
          defaultMessage:
            '{key} cannot have Person attribute active. Either change the question to non-person type or remove Mailkey-attribute.',
          variables: {
            key: question.id,
          },
        },
      ]
    : [];
  return {
    error: isAPersonQuestion ? question.id : null,
    additionalMessages: isNotPersonError,
  };
}

function validateDbFieldQuestions(dbQuestions: FormInputInfo[]) {
  const result = dbQuestions.map((dbQuestion) => checkDbQuestion(dbQuestion));
  return result.filter((item) => item.error !== null);
}

function checkDbQuestion(question: FormInputInfo) {
  const isAPersonQuestion = question.person ? question.person : false;
  const isNotPersonError = !isAPersonQuestion
    ? [
        {
          message: 'registration.subevent.validation.databasefield.nonperson',
          defaultMessage:
            '{key} has to have Person attribute active. Add Person attribute for this question, or remove Database-attribute.',
          variables: {
            key: question.id,
          },
        },
      ]
    : [];
  return {
    error: !isAPersonQuestion ? question.id : null,
    additionalMessages: isNotPersonError,
  };
}

function validateRepeatQuestions(
  items: FormInputInfo[],
  repeatQuestions: FormInputInfo[]
) {
  const result = repeatQuestions.map((repeatQuestion) =>
    checkRepeatQuestion(repeatQuestion, items)
  );
  return result.filter((item) => item.error !== null);
}

function checkRepeatQuestion(question: FormInputInfo, items: FormInputInfo[]) {
  const keyExist = items.find((item) => item.id === question.repeatable.key);
  const valueCanBeOfType =
    keyExist && keyExist.options
      ? keyExist.options.includes(question.repeatable.value)
      : false;
  const amountKeyExist = items.find(
    (item) => item.id === question.repeatable.amount
  );
  const amountIsNumberType = amountKeyExist && amountKeyExist.type === 'number';
  const conditionsToCheck = [
    question.repeatable.key === '*' || keyExist !== undefined,
    question.repeatable.value === '*' || valueCanBeOfType,
    amountKeyExist !== undefined,
    amountIsNumberType,
  ];
  const keyNotExistError =
    !keyExist && question.repeatable.key !== '*'
      ? [
          {
            message: 'registration.subevent.validation.repeat.key',
            defaultMessage: 'Key {key} does not exist!',
            variables: {
              key: question.repeatable.key,
            },
          },
        ]
      : [];
  const valueError =
    !valueCanBeOfType && question.repeatable.value !== '*'
      ? [
          {
            message: 'registration.subevent.validation.repeat.value',
            defaultMessage: '{value} is not an option in question {key}',
            variables: {
              key: question.repeatable.key,
              value: question.repeatable.value,
            },
          },
        ]
      : [];
  const amountKeyNotExistError = !amountKeyExist
    ? [
        {
          message: 'registration.subevent.validation.repeat.amount.key',
          defaultMessage: 'Key {key} does not exist!',
          variables: {
            key: question.repeatable.amount,
          },
        },
      ]
    : [];
  const amountIsNumberTypeError = !amountIsNumberType
    ? [
        {
          message: 'registration.subevent.validation.repeat.amount.Type',
          defaultMessage: '{key} is not a number-type question',
          variables: {
            key: question.repeatable.amount,
          },
        },
      ]
    : [];
  return {
    error: conditionsToCheck.includes(false) ? question.id : null,
    additionalMessages: keyNotExistError.concat(
      valueError,
      amountKeyNotExistError,
      amountIsNumberTypeError
    ),
  };
}

function validateHideQuestions(
  items: FormInputInfo[],
  hideQuestions: FormInputInfo[]
) {
  const result = hideQuestions.map((hideQuestion) =>
    checkHideQuestion(hideQuestion, items)
  );
  return result.filter((item) => item.error !== null);
}

function checkHideQuestion(question: FormInputInfo, items: FormInputInfo[]) {
  const keyExist = items.find((item) => item.id === question.hide.key);
  const valueCanBeOfType =
    keyExist && keyExist.options
      ? keyExist.options.includes(question.hide.value)
      : false;
  const hideKeyError = !keyExist
    ? [
        {
          message: 'registration.subevent.validation.hide.key',
          defaultMessage: 'Key {key} does not exist!',
          variables: {
            key: question.hide.key,
          },
        },
      ]
    : [];
  const hideErrorValue = !valueCanBeOfType
    ? [
        {
          message: 'registration.subevent.validation.hide.value',
          defaultMessage: '{value} is not an option in question {key}',
          variables: {
            value: question.hide.value,
            key: question.hide.key,
          },
        },
      ]
    : [];
  const conditionsToCheck = [keyExist !== undefined, valueCanBeOfType];
  return {
    error: conditionsToCheck.includes(false)
      ? question.id
      : null
      ? question.id
      : null,
    additionalMessages: hideKeyError.concat(hideErrorValue),
  };
}

function findAndValidateReservationQuestion(items: FormInputInfo[]) {
  return items.find((item) => item.reservation && item.type === 'number');
}

function findMissingRequiredEmailFields(
  items: MailKey[],
  requiredEmailFields: MailKeyValues[]
) {
  const result = requiredEmailFields.filter(
    (emailField) => !checkIfEmailKeyFound(emailField, items)
  );
  return result;
}

function checkIfEmailKeyFound(key: MailKeyValues, items: MailKey[]) {
  return items.find((item) => item.key === key.internalDbValue);
}

function findMissingRequiredFields(
  items: DbKey[],
  requiredDbFields: DbKeyValues[],
  group: boolean
) {
  const result = requiredDbFields.filter(
    (dbField) => !checkIfKeyFound(dbField, items)
  );
  return result.map((item) => {
    return {
      name: item.fieldName,
      key: item.internalDbValue,
      additionalMessages: group
        ? ['registration.subevent.validation.grouppersonerror']
        : [],
    };
  });
}

function checkIfKeyFound(key: DbKeyValues, items: DbKey[]) {
  return items.find((item) => item.key === key.internalDbValue);
}

export type EventIds = {
  eventId: string;
  eventName: string;
};
export function selectEventIds(state: AppState): EventIds {
  const eventId = state.eventState.eventId;
  const eventName = state.eventState.eventName;
  return { eventId, eventName };
}

export type SubEventDetails = {
  hasForm: boolean;
  subEventFormId: string;
  subEventState: string;
};
export function selectHasSubEventForm(
  state: AppState,
  eventId: string,
  subEventId: string
): SubEventDetails {
  const subEventCreateState = state.eventState.subEventCreateState.find(
    (s) => s.subEventId === subEventId
  );
  const hasForm =
    subEventCreateState &&
    subEventCreateState.subEventFormId &&
    subEventCreateState.subEventFormId !== '0' &&
    subEventCreateState.subEventFormId !== ''
      ? true
      : false;
  const subEventFormId = hasForm ? subEventCreateState.subEventFormId : '0';
  const subEventState = listState.selectSubEventState(
    state,
    eventId,
    subEventId
  );
  return { hasForm, subEventFormId, subEventState };
}

export type PageStylingData = PageMessages & {
  backgroundColor: string;
};
export function selectRegistrationPageStylingData(
  state: AppState
): PageStylingData {
  const backgroundColor: string = state.eventState.registrationState.pageStyling
    .backgroundColor
    ? state.eventState.registrationState.pageStyling.backgroundColor
    : 'rgba(103, 105, 195, 0.82)';
  const pageMessages: PageMessages =
    state.eventState.registrationState.pageMessages;
  return { backgroundColor: backgroundColor, ...pageMessages };
}

export function selectSubEventBasicInformation(
  state: AppState
): RegistrationInfo {
  return state.eventState.registrationState.subEventInfo;
}

export type LinkListObject = {
  link: string;
  linkStr: string;
};
export const selectSubEventLinks = (
  state: AppState,
  eventId: string,
  subEventId: string
): LinkListObject[] => {
  const { subEventLinksTable } = listState.selectSubEvents(state, eventId);
  const link = state.eventState.registrationState.link.linkUrl;
  const links = subEventLinksTable[subEventId]
    ? subEventLinksTable[subEventId].map((linkStr) => {
        return {
          linkStr: linkStr,
          link: `${link}/${linkStr}`,
        };
      })
    : [];
  return links;
};

export type SubEventsMinimal = {
  subEventId: string;
  subEventName: string;
};
export const selectSubEventForNewParticipant = (
  state: AppState,
  id: string
): SubEventsMinimal[] => {
  const event = state.listEventsState.eventsResponse.find(
    (s) => s.eventId === id
  );
  const subEvents = event
    ? event.subEvents
        .filter(
          (sub, index) =>
            event.subEvents.findIndex(
              (s) => s.subEventId === sub.subEventId && s.subEventFormId !== '0'
            ) === index
        )
        .map((s) => {
          const subEventName = s.subEventName ? s.subEventName : 'Unnamed';
          return {
            subEventId: s.subEventId,
            subEventName: `${subEventName} :: ${s.subEventId}`,
          };
        })
    : [];
  return subEvents;
};
