import React from "react";

interface PaginationProps {
  totalPages: number;
  pageLimit: number;
  pageNeighbours: number;
  currentPage: number;
  onPageChanged: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  pageLimit,
  pageNeighbours,
  currentPage,
  onPageChanged,
}) => {
  const range = (start: number, stop: number) =>
    Array.from({ length: stop - start }, (_, i) => i + 1 + start);
  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";

  const handleClick = (e: React.MouseEvent, page: number) => {
    e.preventDefault();
    onPageChanged(page);
  };

  const handleMoveLeft = (e: React.MouseEvent) => {
    e.preventDefault();
    onPageChanged(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = (e: React.MouseEvent) => {
    e.preventDefault();
    onPageChanged(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = (
    totalPages: number,
    currentPage: number,
    pageNeighbours: number
  ) => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2; //minimum of 5 blocks
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages: any[] = range(startPage - 1, endPage);
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(0, totalPages);
  };

  const pages = fetchPageNumbers(totalPages, currentPage, pageNeighbours);
  const listStyle =
    "flex list-none justify-center border border-gray-300 text-center text-lg";
  const itemStyle =
    "hover:bg-blue-200 border-r border-gray-300 px-4 py-1 cursor-pointer";
  const linkStyle = "font-bold inline-block align-middle";

  return (
    <div className='mt-auto'>
      <nav aria-label='pagination'>
        <ul className={listStyle}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <li key={index} className={itemStyle} onClick={handleMoveLeft}>
                  <button className={linkStyle} aria-label='Previous'>
                    <span aria-hidden='true'>&laquo;</span>
                    <span className='sr-only'>Previous</span>
                  </button>
                </li>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className={itemStyle} onClick={handleMoveRight}>
                  <button className={linkStyle} aria-label='Next'>
                    <span aria-hidden='true'>&raquo;</span>
                    <span className='sr-only'>Next</span>
                  </button>
                </li>
              );

            return (
              <li
                key={index}
                className={`${itemStyle} ${
                  currentPage === page ? " bg-blue-400 text-white" : ""
                }`}
                onClick={(e) => handleClick(e, page)}
              >
                <button className={linkStyle}>{page}</button>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
