import React from 'react';
import { useSelector } from 'react-redux';
import { selectLoadingMultiple } from '../reducers/loadingReducer';
import { EventDetailsPage } from './eventDetails/views/EventDetailsPage';
import { Loading } from '../components/utils/Loading';
import { AppState } from '../reducers/combineReducer';

export const EventPageContainer: React.FC = () => {
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'createEvent',
      'getEvent',
      'getEventDetails',
      'getParticipants',
    ])
  );

  return isLoading ? (
    <Loading styles={`items-center mx-auto`} />
  ) : (
    <EventDetailsPage />
  );
};

export default EventPageContainer;
