import React from "react";
import {Droppable} from "react-beautiful-dnd";
import {FormattedMessage, MessageDescriptor} from "react-intl";
import {DraggableColumnItem} from "./DraggableColumnItem";

interface BasketProps {
  title: MessageDescriptor;
  columns: string[];
  id: string;
  divStyle?: string;
  wrap: boolean;
  translations?: {[key: string]: string}
}

export const DroppableSettingsBasket: React.FC<BasketProps> = ({
  title,
  id,
  columns,
  divStyle,
  wrap,
  translations
}) => {
  const basketStyle = divStyle ? divStyle : "flex-grow mx-auto px-1";
  const containerWidth = wrap ? "min-w-1/3" : "w-1/3";

  return (
    <div className={`px-1 text-center ${containerWidth}`}>
      <div className='text-2xl text-brand-blue'>
        <FormattedMessage {...title} />
      </div>
      <Droppable droppableId={id}>
        {(provided, snapshot) => {
          const BasketBG = snapshot.isDraggingOver
            ? "bg-green-200"
            : "bg-brand-white";
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`${basketStyle} border border-solid border-gray-200 rounded shadow-xl ${BasketBG}`}
            >
              {provided.placeholder}
              {columns.map((column, index) => (
                <DraggableColumnItem
                  key={column}
                  item={column}
                  id={column}
                  index={index}
                  wrap={wrap}
                  translations={translations}
                />
              ))}
            </div>
          );
        }}
      </Droppable>
    </div>
  );
};
