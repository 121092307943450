import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as erState from '../../reducers/errorReducer';
import ErrorFetching from '../../components/modals/ErrorFetching';
import Pagination from '../../components/utils/Pagination';
import SearchBar from '../../components/tableSearchBar/SearchBar';
import { useNotify } from '../../hooks/useNotify';
import { mapFromKeys, getUniqueKeysForTable } from '../../utils/functions';
import { CertParticipantTableRow } from './CertTableRow';
import CertParticipantTableHeader from './CertParticipantHeader';
import * as t from '../../components/tableSearchBar/translations';
import { useTable } from '../../hooks/useTable';
import { BackDrop } from '../../components/tableSearchBar/BackDrop';
import { useIntl } from 'react-intl';

interface TableProps {
  list: any[];
  headerRow: string[];
  tableName: string;
}

export const CertParticipantTable: React.FC<TableProps> = ({
  list,
  headerRow,
  tableName,
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const filterDivToggleId = 'CERT_PARTICIPANTS_TABLE_FILTER';
  const tableSettingsDivId = 'CERT_PARTICIPANTS_SETTINGS';
  const exportSettingsDivId = 'CERT_PARTICIPANTS_EXPORT_SETTINGS';
  const pageLimit = 10;

  const initialState = {
    tableName: tableName,
    tableList: list,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(list.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: list.length,
    pageNeighbours: 0,
    filteredList: list,
    currentList: list.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: headerRow[0],
    ascending: false,
    possibleSearchKeys: list.length ? Object.keys(list[0]) : [],
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: list.length
        ? getUniqueKeysForTable(list).filter(
            (item) => !headerRow.includes(item)
          )
        : [],
    },
    export: {
      exportAll: {
        data: mapFromKeys(list, getUniqueKeysForTable(list)),
        filename: 'cert_participants_all',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: mapFromKeys(list, headerRow),
        filename: 'cert_participants_view',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(list, headerRow);
  }, [list, headerRow]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[filterDivToggleId, tableSettingsDivId, exportSettingsDivId]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:w-full h-full overflow-y-auto overflow-x-hidden scroller">
        <ErrorFetching message={errorMsg} />
        <SearchBar
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          activeFilters={table.state.filterKeys}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          exportSettings={table.state.export}
          onSearchWordChange={table.onSearchWordChange}
        />
        <table className="md:bg-white rounded-lg md:table-fixed w-full">
          <CertParticipantTableHeader
            headers={table.state.headers.droppableColumnsSelected}
            sort={table.sort}
          />
          <tbody>
            {table.state.currentList.map((p, i) => (
              <CertParticipantTableRow
                headerKeys={table.state.headers.droppableColumnsSelected}
                participant={p}
                key={i}
              />
            ))}
          </tbody>
        </table>
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};

export default CertParticipantTable;
