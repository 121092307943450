import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { EventParamTypes } from '../../../types/types';
import { SubEventTable } from '../components/subEventTable/SubEventTable';
import { selectSubEvents } from '../../listEvents/listEventsReducer';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history/history';
import BreadCrumb from '../../../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import allActions from '../../../actions/allActions';
import { selectLoadingMultiple } from '../../../reducers/loadingReducer';
import * as uState from '../../../reducers/userReducer';
import storeService from '../../../services/storage';
import { AppState } from '../../../reducers/combineReducer';

interface Props {}

export const SubEvents: React.FC<Props> = () => {
  const { id } = useParams<EventParamTypes>();
  const { list, headerRow } = useSelector((state: AppState) =>
    selectSubEvents(state, id)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, ['createSubEvent', 'getEvents'])
  );
  const { userId } = useSelector(uState.selectUserData);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const tableName = `subevents_${id}_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;

  const openSubEvent = (subEventId: string) => {
    history.push(`/events/${id}/subevents/${subEventId}`);
  };

  const createSubEvent = () => {
    dispatch(allActions.eventActions.createSubEvent(id));
  };

  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id}`} link={`/events/${id}`} />
        <BreadCrumb label={`subevents`} link={`${url}`} lastChild={true} />
      </BreadRoute>
      <SubEventTable
        open={openSubEvent}
        createSubEvent={createSubEvent}
        headerRow={headers}
        tableList={list}
        loading={isLoading}
        tableName={tableName}
      />
    </div>
  );
};

export default SubEvents;
