import { LoopReducer, loop, Cmd } from 'redux-loop';
import {
  ActionType,
  getType,
  createAction,
  createAsyncAction,
} from 'typesafe-actions';
import { convertParticipantToListFormat, extractId } from '../utils/functions';
import { AppState } from './combineReducer';
import { getParticipants } from './participantReducer';
import {
  destinationMaps,
  Inheritance,
  InputDetails,
} from '../invoicing/invoiceMappings';
import invoiceService from '../services/invoicing';
import { InvoiceValues } from '../invoicing/components/InvoicePreviewItem';
import { historyService } from '../services/historyService';
import { ImportCSVData } from '../types/types';
import { v4 as uuidv4 } from 'uuid';

export type FennoaProduct = {
  tuoteKoodi: string;
  tuoteNimiSuomeksi: string;
  tuoteSelite: string;
  tuoteYksikkoSuomeksi: string;
  tuoteOletusmMyyntiHinta: string;
  tuoteAlv: string;
};
export type SalesInvoice = {
  SalesInvoice: {
    id: string;
    invoice_no: string;
    invoice_type_id: string;
    auxiliary_name_id: string;
    customer_id: string;
    name: string;
    name2: string;
    address: string;
    postalcode: string;
    city: string;
    total_net: string;
    total_vat: string;
    total_paid: string;
    total_due: string;
    banking_reference: string;
    invoice_date: string;
    due_date: string;
    created: string;
    approved: string;
  };
};
export type SourceOption = '' | 'ContactMate' | 'CSV-import';
export type InvoiceOperator = 'Fennoa';
export type InvoiceState = {
  selectedSource: SourceOption;
  destination: InvoiceOperator;
  selectedEvent: string;
  eventParticipants: any[];
  selectedParticipants: string[];
  destinationMapping: { [key: string]: InputDetails };
  multiSend: boolean;
  fennoaProducts?: FennoaProduct[];
  fennoaInvoices?: SalesInvoice[];
};
const initialState: InvoiceState = {
  selectedSource: '',
  destination: 'Fennoa',
  selectedEvent: '',
  eventParticipants: [],
  selectedParticipants: [],
  multiSend: true,
  destinationMapping: destinationMaps.Fennoa,
};

const changeEventSource = createAction(
  'INVOICE_CHANGE_EVENT_SOURCE'
)<SourceOption>();

const toggleMultisend = createAction('INVOICING_TOGGLE_MULTISEND')<undefined>();

const toggleRecipient = createAction('TOGGLE_INVOICE_RECIPIENT')<string>();

const importReceivers = createAction('IMPORT_INVOICE_RECEIVERS')<{
  data: ImportCSVData[];
  headers: boolean;
}>();

const toggleAllRecipients = createAction(
  'TOGGLE_ALL_INVOICE_RECIPIENTS'
)<undefined>();

const setMapping = createAction('SET_INVOICE_MAPPING')<{
  mapping: { [key: string]: InputDetails };
  destination: InvoiceOperator;
}>();

const changeInheritance = createAction(
  'CHANGE_INVOICE_MAPPING_INHERITANCE'
)<string>();

const changeEvent = createAction('CHANGE_EVENT_INVOICE')<{
  eventId: string;
  eventSource: SourceOption;
  eventName: string;
}>();

export const invoiceSend = createAsyncAction(
  'START_INVOICE_SEND',
  'INVOICE_SEND_COMPLETE',
  'INVOICE_SEND_FAIL'
)<InvoiceValues[], { data: any; status: number }, Error>();

export const getFennoaInvoices = createAsyncAction(
  'START_GET_FENNOA_INVOICES',
  'GET_FENNOA_INVOICES_COMPLETE',
  'GET_FENNOA_INVOICES_FAIL'
)<
  undefined,
  { data: { data: SalesInvoice[]; status: string }; status: number },
  Error
>();

export const getFennoaProducts = createAsyncAction(
  'START_GET_FENNOA_PRODUCTS',
  'GET_FENNOA_PRODUCTS_COMPLETE',
  'GET_FENNOA_PRODUCTS_FAIL'
)<undefined, { data: FennoaProduct[]; status: number }, Error>();

type Action =
  | ActionType<typeof changeEventSource>
  | ActionType<typeof getParticipants>
  | ActionType<typeof changeEvent>
  | ActionType<typeof toggleRecipient>
  | ActionType<typeof toggleAllRecipients>
  | ActionType<typeof setMapping>
  | ActionType<typeof changeInheritance>
  | ActionType<typeof invoiceSend>
  | ActionType<typeof importReceivers>
  | ActionType<typeof getFennoaProducts>
  | ActionType<typeof getFennoaInvoices>
  | ActionType<typeof toggleMultisend>;

export const invoiceReducer: LoopReducer<InvoiceState, Action> = (
  state: InvoiceState = initialState,
  action: Action
) => {
  switch (action.type) {
    case getType(changeEventSource):
      return { ...state, selectedSource: action.payload };

    case getType(changeEvent):
      return loop(
        state,
        Cmd.action({
          type: 'GET_EVENT_PARTICIPANTS',
          payload: {
            id: action.payload.eventId,
            name: action.payload.eventName,
          },
        })
      );

    case getType(getParticipants.request):
      const name = action.payload.name ? action.payload.name : null;
      return {
        ...state,
        selectedEvent: name,
      };

    case getType(getParticipants.success):
      if (!state.selectedEvent) {
        return {
          ...state,
          selectedParticipants: initialState.selectedParticipants,
          eventParticipants: initialState.eventParticipants,
        };
      }

      const eventParticipants = action.payload.data.map((p) =>
        convertParticipantToListFormat(p)
      );
      const selectedEventParticipants = eventParticipants.map((p) => p.uuid);
      return {
        ...state,
        eventParticipants: eventParticipants,
        selectedParticipants: selectedEventParticipants,
      };

    case getType(getParticipants.failure):
      return {
        ...state,
        eventParticipants: initialState.eventParticipants,
        selectedParticipants: initialState.selectedParticipants,
      };

    case getType(toggleMultisend):
      return {
        ...state,
        multiSend: !state.multiSend,
      };

    case getType(toggleRecipient):
      return {
        ...state,
        selectedParticipants: state.selectedParticipants.includes(
          action.payload
        )
          ? state.selectedParticipants.filter((uuid) => uuid !== action.payload)
          : state.selectedParticipants.concat(action.payload),
      };

    case getType(toggleAllRecipients):
      return {
        ...state,
        selectedParticipants:
          state.selectedParticipants.length > 0
            ? []
            : state.eventParticipants.map((p) => p.uuid.toString()),
      };

    case getType(setMapping):
      const updatedState = {
        ...state,
        destinationMapping: action.payload.mapping,
        destination: action.payload.destination,
      };
      if (action.payload.destination === 'Fennoa') {
        return loop(
          updatedState,
          Cmd.action({ type: 'START_GET_FENNOA_PRODUCTS' })
        );
      }
      return updatedState;

    case getType(changeInheritance):
      const inheritance: Inheritance =
        state.destinationMapping[action.payload].mapping === 'Hardcoded'
          ? 'Inherited'
          : 'Hardcoded';
      return {
        ...state,
        destinationMapping: {
          ...state.destinationMapping,
          [action.payload]: {
            ...state.destinationMapping[action.payload],
            mapping: inheritance,
          },
        },
      };

    case getType(importReceivers):
      const dataToMap = action.payload.headers
        ? action.payload.data.slice(1)
        : action.payload.data;
      const receivers = dataToMap.map((imported) => {
        return Object.assign(
          {},
          Object.assign(
            { uuid: uuidv4() },
            ...imported.data.map((value: any, i: number) => {
              const key = action.payload.headers
                ? action.payload.data[0].data[i]
                : `Column_${i}`;
              return { [key]: value };
            })
          )
        );
      });
      return {
        ...state,
        sendMultiple: true,
        eventParticipants: receivers,
        selectedParticipants: [],
      };

    case getType(invoiceSend.request):
      return loop(
        state,
        Cmd.run(invoiceService.newInvoiceSend, {
          successActionCreator: invoiceSend.success,
          failActionCreator: invoiceSend.failure,
          args: [action.payload],
        })
      );

    case getType(invoiceSend.success):
      return loop(
        state,
        Cmd.run(historyService.goto, {
          args: ['/invoices'],
        })
      );

    case getType(invoiceSend.failure):
      return state;

    case getType(getFennoaProducts.request):
      return loop(
        state,
        Cmd.run(invoiceService.getFennoaProducts, {
          successActionCreator: getFennoaProducts.success,
          failActionCreator: getFennoaProducts.failure,
        })
      );

    case getType(getFennoaProducts.success):
      if (action.payload.status === 200) {
        return { ...state, fennoaProducts: action.payload.data };
      }
      return state;

    case getType(getFennoaProducts.failure):
      return state;

    case getType(getFennoaInvoices.request):
      return loop(
        state,
        Cmd.run(invoiceService.getAllFennoaInvoices, {
          successActionCreator: getFennoaInvoices.success,
          failActionCreator: getFennoaInvoices.failure,
        })
      );

    case getType(getFennoaInvoices.success):
      if (action.payload.status === 200) {
        return { ...state, fennoaInvoices: action.payload.data.data };
      }
      return state;

    case getType(getFennoaInvoices.failure):
      return state;

    default:
      return state;
  }
};

type MappingData = {
  personsQuestions: string[];
  productOptions: string[];
};
export const getSendMultipleData = (state: AppState): MappingData => {
  const selected =
    state.invoiceState.selectedParticipants.length > 0
      ? state.invoiceState.eventParticipants.filter((p) =>
          state.invoiceState.selectedParticipants.includes(p.uuid)
        )
      : state.invoiceState.eventParticipants;
  const personsQuestions = [''].concat(
    Object.keys(
      selected.reduce((result, obj) => {
        return Object.assign(result, obj);
      }, {})
    )
  );
  const productOptions = state.invoiceState.fennoaProducts
    ? state.invoiceState.fennoaProducts.map(
        (p) => `${p.tuoteNimiSuomeksi} :: ${p.tuoteKoodi}`
      )
    : [];
  return { personsQuestions, productOptions };
};

export const getSelectedParticipants = (state: AppState): string[] => {
  return state.invoiceState.selectedParticipants;
};

export const getInheritance = (
  state: AppState,
  question: string
): Inheritance => {
  return state.invoiceState.destinationMapping[question].mapping;
};

export type InvoicingPageData = InvoiceState & {
  events: { name: string; id: string }[];
  participantHeaders: string[];
};
export const selectInvoicePageData = (state: AppState): InvoicingPageData => {
  const events =
    state.listEventsState.events.length > 0
      ? state.listEventsState.events.map((e) => {
          return { name: `${e.eventName} :: ${e.eventId}`, id: e.eventId };
        })
      : [];
  const eventParticipants = state.invoiceState.eventParticipants;
  const participantHeaders =
    eventParticipants.length > 0
      ? Object.keys(eventParticipants[0]).length > 5
        ? Object.keys(eventParticipants[0]).slice(0, 5)
        : Object.keys(eventParticipants[0])
      : [];
  return {
    selectedSource: state.invoiceState.selectedSource,
    destination: state.invoiceState.destination,
    selectedEvent: state.invoiceState.selectedEvent,
    selectedParticipants: state.invoiceState.selectedParticipants,
    multiSend: state.invoiceState.multiSend,
    destinationMapping: state.invoiceState.destinationMapping,
    eventParticipants,
    participantHeaders,
    events,
  };
};

export type InvoiceErrors = {
  missing: string[];
};
type InvoicePreviewData = {
  data: InvoiceValues[];
  errors: InvoiceErrors;
};
export const selectSendInvoiceData = (state: AppState): InvoicePreviewData => {
  const receivers = state.invoiceState.eventParticipants.filter((r) =>
    state.invoiceState.selectedParticipants.includes(r.uuid)
  );
  const mapping = state.invoiceState.destinationMapping;
  const inputs = state.formState.input.newInvoice;
  const missing = Object.keys(mapping).filter(
    (key) => mapping[key].required && !inputs[key]
  );
  const data = receivers.map((r) => {
    const groupid = r.groupUuid ? r.groupUuid : null;
    return Object.keys(inputs).reduce(
      (acc, curr) => {
        const key = inputs[curr] as string;
        const value =
          mapping[curr].mapping === 'Hardcoded'
            ? key
            : mapping[curr].productCode
            ? extractId(key)
            : mapping[curr].extract && mapping[curr].extract.test(r[key])
            ? r[key].match(mapping[curr].extract)[0]
            : r[key];

        return mapping[curr].rowData
          ? {
              ...acc,
              row: {
                ...acc.row,
                '1': {
                  ...acc.row['1'],
                  [mapping[curr].id]: value,
                },
              },
            }
          : {
              ...acc,
              [mapping[curr].id]: value,
            };
      },
      { row: {}, groupid }
    );
  });
  const invoicesWithGroup = data.filter((d) => d.groupid);
  const groupedDataTable = invoicesWithGroup.reduce((acc, curr) => {
    return acc[curr.groupid] && curr.row['1']
      ? {
          ...acc,
          [curr.groupid]: {
            ...acc[curr.groupid],
            row: {
              ...acc[curr.groupid].row,
              [Object.keys(acc[curr.groupid].row).length + 1]: curr.row['1'],
            },
          },
        }
      : { ...acc, [curr.groupid]: curr };
  }, {});
  const singleInvoices = data.filter((d) => !d.groupid);
  const all = (Object.keys(groupedDataTable)
    .map((key) => groupedDataTable[key])
    .concat(singleInvoices) as unknown) as InvoiceValues[];
  return { data: all, errors: { missing } };
};

export const selectInvoicesTableData = (state: AppState) => {
  const invoices = state.invoiceState.fennoaInvoices
    ? state.invoiceState.fennoaInvoices.map((f) => f.SalesInvoice)
    : [];

  const headerRow =
    invoices.length > 0
      ? Object.keys(invoices[0]).length > 5
        ? Object.keys(invoices[0]).slice(0, 5)
        : Object.keys(invoices[0])
      : [];
  return { data: invoices, headerRow };
};
