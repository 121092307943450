import { loginReducer } from '../login/loginReducer';
import { toggleReducer } from './toggleReducer';
import { formReducer } from './formReducer';
import { eventReducer } from '../events/eventReducer';
import { loadingReducer } from './loadingReducer';
import { combineReducers } from 'redux-loop';
import { errorReducer } from './errorReducer';
import { listEventsReducer } from '../events/listEvents/listEventsReducer';
import { userReducer } from './userReducer';
import { certReducer } from '../certs/certReducer';
import { participantReducer } from './participantReducer';
import { fileUploadReducer, FileUploadState } from './fileUploadReducer';
import {
  CertState,
  ErrorState,
  FormState,
  ListEventsState,
  LoadingState,
  LoginState,
  ParticipantState,
  UserState,
  ToggleState,
  EventState,
} from '../types/types';
import { invoiceReducer, InvoiceState } from './invoiceReducer';

export type AppState = {
  loginState: LoginState;
  formState: FormState;
  toggleState: ToggleState;
  eventState: EventState;
  loadingState: LoadingState;
  errorState: ErrorState;
  listEventsState: ListEventsState;
  userState: UserState;
  certState: CertState;
  participantState: ParticipantState;
  fileUploadState: FileUploadState;
  invoiceState: InvoiceState;
};

const rootReducer = combineReducers({
  loginState: loginReducer,
  formState: formReducer,
  toggleState: toggleReducer,
  eventState: eventReducer,
  loadingState: loadingReducer,
  errorState: errorReducer,
  listEventsState: listEventsReducer,
  userState: userReducer,
  certState: certReducer,
  participantState: participantReducer,
  fileUploadState: fileUploadReducer,
  invoiceState: invoiceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
