import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { EventPageContainer } from '../events/EventPageContainer';
import EventDetailCard from '../events/eventDetails/views/EventDetailCard';
import EventParticipants from '../events/eventDetails/views/EventParticipants';
import SubEvents from '../events/eventDetails/views/SubEvents';
import { SubEventNavigation } from './SubEventNavigation';
import { useDispatch } from 'react-redux';
import allActions from '../actions/allActions';
import { EventParamTypes } from '../types/types';
import { NotFound } from '../components/utils/NotFound';
import EditParticipant from '../events/eventDetails/components/EditParticipant';

export const EventNavigation: React.FC = () => {
  let { path } = useRouteMatch();
  const { id } = useParams<EventParamTypes>();
  const dispatch = useDispatch();

  const getAll = useCallback(() => {
    dispatch(allActions.eventActions.fetchEventdataInOrder(id));
  }, [id, dispatch]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  return (
    <Switch>
      <Route exact path={path} render={() => <EventPageContainer />} />
      <Route exact path={`${path}/subevents`} render={() => <SubEvents />} />
      <Route path={`${path}/details`} render={() => <EventDetailCard />} />
      <Route
        exact
        path={`${path}/participants`}
        render={() => <EventParticipants />}
      />
      <Route
        path={`${path}/participants/:participantId`}
        render={() => <EditParticipant />}
      />
      <Route
        path={`${path}/subevents/:subEventId`}
        render={() => <SubEventNavigation />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default EventNavigation;
