import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import { FormDate } from '../../../components/form/FormDate';
import { useForm } from 'react-hook-form';
import FormInput from '../../../components/form/FormInput';
import { Switch } from '../../../components/utils/Switch';
import { FormSelect } from '../../../components/form/FormSelect';
import { HoverMessage } from '../../../components/utils/HoverMessage';
import { useIntl } from 'react-intl';
import * as tState from '../../../reducers/toggleReducer';
import * as eState from '../../eventReducer';
import { messages } from '../translations/basicInformationTranslations';
import { overlays } from './overlayToggles';

export const EventInformationForm: React.FC = () => {
  const { register, errors, control } = useForm();
  const dispatch = useDispatch();
  const {
    subEventEndTime,
    subEventRegistrationEnd,
    subEventRegistrationStart,
    subEventStartTime,
    subEventUpdateEnd,
    subEventCancelEnd,
    subEventMaxRegistrants,
    subEventSupportEmail,
    subEventSupportInstruction,
    subEventSupportPhone,
    subEventName,
    subEventAllowCancel,
    subEventAllowModify,
    subEventAllowedQue,
    subEventMaxGroupSize,
  } = useSelector(eState.selectSubEventBasicInformation);
  const toggleState = useSelector(tState.getToggleState);
  const styles = 'w-full flex flex-wrap';
  const inputContainers = 'w-1/2 items-center';
  const divStyle = 'flex flex-col mb-2';
  const labelStyle =
    'pt-4 mb-2 ml-2 uppercase font-bold text-lg text-brand-dark_blue';
  const conditionalDateInput =
    'flex h-10 bg-white border border-gray-300 rounded-lg relative m-4';
  const thirdRowDiv = 'flex-col w-1/4 relative';
  const modifyToggle = 'modifyToggle';
  const cancelToggle = 'cancelToggle';
  const queueToggle = 'queueToggle';
  const maxGroupToggle = 'maxGroupToggle';
  const cancelDateToggle = 'subEventAllowCancel';
  const modifyDateToggle = 'subEventAllowModify';
  const { formatMessage } = useIntl();
  const optionUnlimited = formatMessage({ ...messages.optionunlimited });
  const optionRestricted = formatMessage({ ...messages.optionrestricted });
  const triOptionDictionary = {
    '': '0',
    [optionRestricted]: '1',
    [optionUnlimited]: '-1',
  };

  const handleDateChange = (date: Date, id: string) => {
    dispatch(allActions.eventActions.changeRegistrationInfoDate(id, date));
  };

  const resetDate = (id: string) => {
    dispatch(allActions.eventActions.changeRegistrationInfoDate(id, null));
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    id: string
  ) => {
    dispatch(
      allActions.eventActions.updateRegistrationInfo(id, event.target.value)
    );
    if (
      (id === cancelDateToggle || id === modifyDateToggle) &&
      event.target.value === '1'
    ) {
      dispatch(allActions.toggleActions.showToggle(id));
    }
    if (id === cancelDateToggle || id === modifyDateToggle) {
      dispatch(allActions.toggleActions.hideToggle(id));
    }
  };

  const toggleElement = (id: string) => {
    if (toggleState[id]) {
      resetDateValues(id);
    }
    dispatch(allActions.toggleActions.toggle(id));
  };

  const showElement = (id: string) => {
    dispatch(allActions.toggleActions.showToggle(id));
  };

  const hideElement = (id: string) => {
    dispatch(allActions.toggleActions.hideToggle(id));
  };

  const toggleHelp = (id: string) => {
    dispatch(allActions.toggleActions.toggle(id));
  };

  const resetDateValues = (id: string) => {
    if (id === cancelDateToggle) {
      dispatch(
        allActions.eventActions.updateRegistrationInfo(
          'subEventCancelEnd',
          null
        )
      );
      dispatch(
        allActions.eventActions.updateRegistrationInfo(cancelDateToggle, '0')
      );
    }
    if (id === modifyDateToggle) {
      dispatch(
        allActions.eventActions.updateRegistrationInfo(
          'subEventUpdateEnd',
          null
        )
      );
      dispatch(
        allActions.eventActions.updateRegistrationInfo(modifyDateToggle, '0')
      );
    }
    if (id === queueToggle) {
      dispatch(
        allActions.eventActions.updateRegistrationInfo(
          'subEventAllowedQue',
          '0'
        )
      );
    }
    if (id === maxGroupToggle) {
      dispatch(
        allActions.eventActions.updateRegistrationInfo(
          'subEventMaxGroupSize',
          '1'
        )
      );
    }
  };

  useEffect(
    () => {
      if (Number(subEventAllowModify) !== 0) {
        showElement(modifyToggle);
      }
      if (Number(subEventAllowedQue) !== 0) {
        showElement(queueToggle);
      }
      if (Number(subEventMaxGroupSize) !== 1) {
        showElement(maxGroupToggle);
      }
      if (Number(subEventAllowCancel) !== 0) {
        showElement(cancelToggle);
      }
      if (Number(subEventAllowCancel) === 1) {
        showElement(cancelDateToggle);
      }
      if (Number(subEventAllowCancel) === 0) {
        hideElement(cancelDateToggle);
        hideElement(cancelToggle);
      }
      if (Number(subEventAllowedQue) === 0) {
        hideElement(queueToggle);
      }
      if (Number(subEventAllowModify) === 0) {
        hideElement(modifyDateToggle);
        hideElement(modifyToggle);
      }
      if (Number(subEventAllowModify) === 1) {
        showElement(modifyDateToggle);
      }
      if (Number(subEventMaxGroupSize) === 1) {
        hideElement(maxGroupToggle);
      }
    },
    /* eslint-disable */ [
      subEventAllowCancel,
      subEventAllowModify,
      subEventMaxGroupSize,
      subEventAllowedQue,
    ] /* eslint-disable */
  );

  return (
    <div className="w-full flex flex-col justify-evenly divide-y">
      <div className="flex justify-evenly divide-x">
        <FormInput
          value={subEventName || ''}
          labelStyle={labelStyle}
          question={formatMessage({ ...messages.subeventname })}
          handleInputChange={handleInputChange}
          id="subEventName"
          type="text"
        />
      </div>
      <div className={styles}>
        <FormDate
          value={subEventStartTime ? new Date(subEventStartTime) : null}
          handleInputChange={handleDateChange}
          labelStyle={labelStyle}
          id="subEventStartTime"
          question={formatMessage({ ...messages.subeventstart })}
          register={register}
          errors={errors}
          control={control}
          reset={resetDate}
          containerStyle={inputContainers}
        />
        <FormDate
          value={subEventEndTime ? new Date(subEventEndTime) : null}
          handleInputChange={handleDateChange}
          labelStyle={labelStyle}
          id="subEventEndTime"
          question={formatMessage({ ...messages.subeventend })}
          register={register}
          errors={errors}
          control={control}
          reset={resetDate}
          containerStyle={inputContainers}
        />
        <FormDate
          value={
            subEventRegistrationStart
              ? new Date(subEventRegistrationStart)
              : null
          }
          handleInputChange={handleDateChange}
          labelStyle={labelStyle}
          id="subEventRegistrationStart"
          question={formatMessage({ ...messages.subeventregistrationstart })}
          register={register}
          errors={errors}
          control={control}
          reset={resetDate}
          containerStyle={inputContainers}
        />
        <FormDate
          value={
            subEventRegistrationEnd ? new Date(subEventRegistrationEnd) : null
          }
          handleInputChange={handleDateChange}
          labelStyle={labelStyle}
          id="subEventRegistrationEnd"
          question={formatMessage({ ...messages.subeventregistrationend })}
          register={register}
          errors={errors}
          control={control}
          reset={resetDate}
          containerStyle={inputContainers}
        />
      </div>
      <div className="flex justify-evenly divide-x divide-gray-200">
        <div className={thirdRowDiv}>
          <HoverMessage
            messageHeader={messages.allowcancelhelpheader}
            message={messages.allowcancelhelpparagaph}
            overlayProps={{
              ...overlays.cancellation,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id={cancelToggle}
              header={formatMessage({ ...messages.allowcancel })}
              show={toggleState[cancelToggle]}
              switchAction={toggleElement}
              overlayProps={{
                overlayId: overlays.cancellation.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
          <FormSelect
            value={subEventAllowCancel ? subEventAllowCancel.toString() : ''}
            dictionaryOptions={triOptionDictionary}
            divStyle={toggleState[cancelToggle] ? divStyle : 'invisible'}
            handleInputChange={handleInputChange}
            id="subEventAllowCancel"
          />
          <FormDate
            value={subEventCancelEnd ? new Date(subEventCancelEnd) : null}
            handleInputChange={handleDateChange}
            id="subEventCancelEnd"
            register={register}
            inputStyle={conditionalDateInput}
            divStyle={toggleState[cancelDateToggle] ? divStyle : 'invisible'}
            errors={errors}
            reset={resetDate}
            control={control}
          />
        </div>
        <div className={thirdRowDiv}>
          <HoverMessage
            messageHeader={messages.allowmodifyhelpheader}
            message={messages.allowmodifyhelpparagaph}
            overlayProps={{
              ...overlays.modifying,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id={modifyToggle}
              header={formatMessage({ ...messages.allowmodify })}
              show={toggleState[modifyToggle]}
              switchAction={toggleElement}
              overlayProps={{
                overlayId: overlays.modifying.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
          <FormSelect
            value={subEventAllowModify ? subEventAllowModify.toString() : ''}
            dictionaryOptions={triOptionDictionary}
            divStyle={toggleState[modifyToggle] ? divStyle : 'invisible'}
            handleInputChange={handleInputChange}
            id="subEventAllowModify"
          />
          <FormDate
            value={subEventUpdateEnd ? new Date(subEventUpdateEnd) : null}
            handleInputChange={handleDateChange}
            id="subEventUpdateEnd"
            register={register}
            inputStyle={conditionalDateInput}
            divStyle={toggleState[modifyDateToggle] ? divStyle : 'invisible'}
            errors={errors}
            reset={resetDate}
            control={control}
          />
        </div>
        <div className={thirdRowDiv}>
          <HoverMessage
            messageHeader={messages.allowqueuehelpheader}
            message={messages.allowqueuehelpparagaph}
            overlayProps={{
              ...overlays.queueing,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id={queueToggle}
              header={formatMessage({ ...messages.allowqueue })}
              show={toggleState[queueToggle]}
              switchAction={toggleElement}
              overlayProps={{
                overlayId: overlays.queueing.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
          <FormSelect
            value={
              subEventAllowedQue
                ? Number(subEventAllowedQue) > 0
                  ? '1'
                  : subEventAllowedQue.toString()
                : ''
            }
            dictionaryOptions={triOptionDictionary}
            divStyle={toggleState[queueToggle] ? divStyle : 'invisible'}
            handleInputChange={handleInputChange}
            id="subEventAllowedQue"
          />
          <FormInput
            value={subEventAllowedQue || ''}
            handleInputChange={handleInputChange}
            labelStyle={labelStyle}
            divStyle={subEventAllowedQue > 0 ? divStyle : 'invisible'}
            id="subEventAllowedQue"
            type="number"
          />
        </div>
        <div className={thirdRowDiv}>
          <HoverMessage
            messageHeader={messages.maxgrouplhelpheader}
            message={messages.maxgrouphelpparagaph}
            overlayProps={{
              ...overlays.grouping,
              overlayToggle: toggleHelp,
            }}
          >
            <Switch
              id={maxGroupToggle}
              header={formatMessage({ ...messages.maxgroup })}
              show={toggleState[maxGroupToggle]}
              switchAction={toggleElement}
              overlayProps={{
                overlayId: overlays.grouping.overlayId,
                overlayToggle: toggleHelp,
              }}
            />
          </HoverMessage>
          <FormSelect
            value={
              subEventMaxGroupSize
                ? Number(subEventMaxGroupSize) > 1
                  ? '2'
                  : subEventMaxGroupSize.toString()
                : ''
            }
            dictionaryOptions={{
              ...triOptionDictionary,
              '': '1',
              [optionRestricted]: '2',
            }}
            divStyle={toggleState[maxGroupToggle] ? divStyle : 'invisible'}
            handleInputChange={handleInputChange}
            id="subEventMaxGroupSize"
          />
          <FormInput
            value={subEventMaxGroupSize || ''}
            handleInputChange={handleInputChange}
            divStyle={
              toggleState[maxGroupToggle] && subEventMaxGroupSize > 1
                ? divStyle
                : 'invisible'
            }
            id="subEventMaxGroupSize"
            type="number"
          />
        </div>
      </div>
      <div className="flex justify-evenly divide-x divide-gray-200">
        <HoverMessage
          messageHeader={messages.maxregistrantshelpheader}
          message={messages.maxregistrantshelpparagaph}
          overlayProps={{
            ...overlays.maxparticipants,
            overlayToggle: toggleHelp,
          }}
        >
          <FormInput
            value={subEventMaxRegistrants || ''}
            question={formatMessage({ ...messages.maxregistrants })}
            labelStyle={labelStyle}
            handleInputChange={handleInputChange}
            id="subEventMaxRegistrants"
            type="number"
            overlayProps={{
              overlayId: overlays.maxparticipants.overlayId,
              overlayToggle: toggleHelp,
            }}
          />
        </HoverMessage>
        <FormInput
          value={subEventSupportEmail || ''}
          question={formatMessage({ ...messages.supportemail })}
          labelStyle={labelStyle}
          handleInputChange={handleInputChange}
          id="subEventSupportEmail"
          type="text"
        />
        <FormInput
          value={subEventSupportPhone || ''}
          question={formatMessage({ ...messages.supportphone })}
          labelStyle={labelStyle}
          handleInputChange={handleInputChange}
          id="subEventSupportPhone"
          type="text"
        />
        <FormInput
          value={subEventSupportInstruction || ''}
          question={formatMessage({ ...messages.instructions })}
          handleInputChange={handleInputChange}
          id="subEventSupportInstruction"
          type="text"
        />
      </div>
    </div>
  );
};

export default EventInformationForm;
