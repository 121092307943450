import React from 'react';
import { FormattedMessage } from 'react-intl';

export const NoData: React.FC = () => {
  const styles = 'm-auto text-gray-400 text-6xl';

  return (
    <div className={styles}>
      <FormattedMessage
        id="event.details.participantdata.noparticipants"
        defaultMessage="No registrations"
      />
    </div>
  );
};
