import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { BreadCrumb } from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import { Card } from '../components/utils/Card';
import { EventParamTypes } from '../types/types';
import { selectHasSubEventForm } from './eventReducer';
import { messages } from './eventDetails/translations/subEventDetailsCard';
import * as imgs from '../img/images';
import { AppState } from '../reducers/combineReducer';
import { selectLoadingMultiple } from '../reducers/loadingReducer';
import Loading from '../components/utils/Loading';

export const SubEventContainer: React.FC = () => {
  const { id, subEventId } = useParams<EventParamTypes>();
  const { hasForm } = useSelector((state: AppState) =>
    selectHasSubEventForm(state, id, subEventId)
  );
  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'getEvent',
      'getEventDetails',
      'getSubEventDetails',
      'getParticipants',
      'getDefaultForm',
    ])
  );
  let { url } = useRouteMatch();

  const linkParagraph = hasForm ? messages.linktext : messages.linktextalt;
  const registrationParagraph = hasForm
    ? messages.registrationtext
    : messages.registrationtextalt;

  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} />
        <BreadCrumb label={`event ${id} `} link={`/events/${id}`} />
        <BreadCrumb label={`subevents`} link={`/events/${id}/subevents/`} />
        <BreadCrumb
          label={`subevent ${subEventId}`}
          link={`${url}`}
          lastChild={true}
        />
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center">
        {isLoading ? (
          <Loading styles="items-center" />
        ) : (
          <>
            <Card
              header={{ ...messages.registrationheader }}
              paragraph={registrationParagraph}
              link={`${url}/registration`}
              linkMessage={{ ...messages.registrationlink }}
              image={imgs.SUBEVENT_CARD_REGISTRATION}
              canClick={true}
            />
            <Card
              header={{ ...messages.linkheader }}
              paragraph={linkParagraph}
              link={`${url}/link`}
              linkMessage={{ ...messages.linklink }}
              image={imgs.SUBEVENT_CARD_LINK}
              canClick={hasForm}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SubEventContainer;
