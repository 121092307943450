import React, { useCallback, useEffect } from 'react';
import { EventTable } from '../eventTable/EventTable';
import history from '../../../history/history';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import * as leState from '../listEventsReducer';
import { BreadRoute } from '../../../components/breadcrumbs/BreadRoute';
import BreadCrumb from '../../../components/breadcrumbs/BreadCrumb';
import * as uState from '../../../reducers/userReducer';
import storeService from '../../../services/storage';
import { selectLoading } from '../../../reducers/loadingReducer';
import { AppState } from '../../../reducers/combineReducer';

export const ListEventsPage: React.FC = () => {
  const { list, headerRow } = useSelector(leState.selectListEventsState);
  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'getEvents')
  );
  const { userId } = useSelector(uState.selectUserData);
  const dispatch = useDispatch();
  const tableName = `events_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;

  const openEvent = (eventId: string, subEventId?: string) => {
    if (subEventId) {
      return history.push(`/events/${eventId}/subevents/${subEventId}`);
    }
    return history.push(`/events/${eventId}`);
  };

  const getAllEvents = useCallback(() => {
    dispatch(allActions.eventActions.getAllEvents({ date: '0', limit: '100' }));
  }, [dispatch]);

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  return (
    <div className="w-11/12 mx-auto h-screen flex-col">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} />
        <BreadCrumb label={'events'} link={'/events'} lastChild={true} />
      </BreadRoute>
      <EventTable
        tableName={tableName}
        list={list}
        openEvent={openEvent}
        loading={isLoading}
        headerRow={headers}
      />
    </div>
  );
};

export default ListEventsPage;
