import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as t from '../requiredTranslations';

interface Props {
  errors?: Record<string, Object>;
  id: string;
}

export const ErrorDiv: React.FC<Props> = ({ errors, id }) => {
  if (!errors || (errors && !errors[id])) {
    return null;
  }

  return (
    <p className="text-red-400 ml-4">
      <FormattedMessage {...t.messages[errors[id]['message']]} />
    </p>
  );
};
