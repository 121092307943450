import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInput } from '../../../components/form/FormInput';
import * as imgs from '../../../img/images';
import { messages } from '../translations/linkTranslations';

interface Props {
  linkSuggestion: string;
  handleNestedItemChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  errors: number;
  publishForm: (publish: boolean) => void;
}

export const LinkForm: React.FC<Props> = ({
  linkSuggestion,
  handleNestedItemChange,
  errors,
  publishForm,
}) => {
  const { formatMessage } = useIntl();

  const buttonStyles =
    errors > 0 || !/(^[A-z0-9])\w+$/.test(linkSuggestion)
      ? 'bg-gray-500 cursor-default'
      : 'bg-blue-400 hover:bg-brand-blue';

  return (
    <div className="flex flex-col justify-around text-center w-1/2 border shadow-lg container m-2 relative">
      <div className="h-1/2">
        <img
          className="h-full mx-auto"
          src={`${process.env.PUBLIC_URL}/${imgs.SUBEVENT_CARD_LINK}`}
          alt={`link_image`}
        />
      </div>
      <FormInput
        divStyle="mx-auto"
        value={linkSuggestion || ''}
        labelStyle="p-2 text-xl text-brand-dark_blue"
        inputStyle="focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-1 px-2 m-2 appearance-none leading-normal"
        question={formatMessage({ ...messages.linksuggestionquestion })}
        handleInputChange={handleNestedItemChange}
        id="linkSuggestion:link"
        type="text"
      />
      <div className="flex flex-col">
        {errors > 0 && (
          <div className="text-red-400">
            <FormattedMessage
              {...messages.linkerrorparagaph}
              values={{
                errors: errors,
              }}
            />
          </div>
        )}
        <div className="flex justify-around w-1/4 mx-auto">
          <button
            className={`font-thin text-brand-white py-2 px-4 rounded m-5 ${buttonStyles}`}
            onClick={() => publishForm(true)}
            disabled={
              errors > 0 || !/(^[A-z])\w+$/.test(linkSuggestion) ? true : false
            }
          >
            <FormattedMessage {...messages.publish} />
          </button>
        </div>
      </div>
    </div>
  );
};
