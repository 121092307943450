import React from 'react';

interface Props {
  headers: string[];
  sort: (header: string) => void;
}

export const InvoicesTableHeader: React.FC<Props> = ({ headers, sort }) => {
  const styles =
    'bg-blue-100 text-left text-brand-dark_blue px-8 py-4 h-12 cursor-pointer hover:text-brand-blue truncate';
  return (
    <thead>
      <tr className="flex flex-col flex-no wrap md:table-row rounded-l-lg md:rounded-none mb-2 md:mb-0">
        {headers.map((header) => (
          <th key={header} className={styles} onClick={() => sort(header)}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};
