import React, { useCallback, useEffect } from 'react';
import { EventObject } from '../../../types/types';
import { EventTableRow } from './EventTableRow';
import { EventTableHeader } from './EventTableHeader';
import { Pagination } from '../../../components/utils/Pagination';
import { SearchBar } from '../../../components/tableSearchBar/SearchBar';
import { mapFromKeys, getUniqueKeysForTable } from '../../../utils/functions';
import { ErrorFetching } from '../../../components/modals/ErrorFetching';
import { useSelector } from 'react-redux';
import * as erState from '../../../reducers/errorReducer';
import { useNotify } from '../../../hooks/useNotify';
import { Loading } from '../../../components/utils/Loading';
import * as h from './headerTranslations';
import * as t from '../../../components/tableSearchBar/translations';
import { useTable } from '../../../hooks/useTable';
import { BackDrop } from '../../../components/tableSearchBar/BackDrop';
import { useIntl } from 'react-intl';

interface EventTableProps {
  tableName: string;
  list: EventObject[];
  openEvent: (eventId: string, subEventId?: string) => void;
  loading: boolean;
  headerRow: string[];
}

export const EventTable: React.FC<EventTableProps> = ({
  list,
  openEvent,
  loading,
  tableName,
  headerRow,
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const { formatMessage } = useIntl();
  const errorMsg = useNotify(message);
  const filterDivToggleId = 'LIST_EVENTS_TABLE_FILTER';
  const tableSettingsDivId = 'LIST_EVENTS_SETTINGS';
  const exportSettingsDivId = 'LIST_EVENTS_EXPORT_SETTINGS';
  const pageLimit = 7;
  const initialState = {
    tableName: tableName,
    tableList: list,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(list.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: list.length,
    pageNeighbours: 0,
    filteredList: list,
    currentList: list.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: headerRow[0],
    ascending: false,
    possibleSearchKeys: list.length ? getUniqueKeysForTable(list) : [],
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: getUniqueKeysForTable(list).filter(
        (item) => !headerRow.includes(item)
      ),
    },
    export: {
      exportAll: {
        data: mapFromKeys(list, getUniqueKeysForTable(list)),
        filename: 'events_all',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: [],
        filename: 'events_view',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);
  const currentList = table.state.currentList as EventObject[];

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(list, headerRow);
  }, [list, headerRow]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[filterDivToggleId, tableSettingsDivId, exportSettingsDivId]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:shadow-lg my-5 md:w-full h-11/12 min-h-11/12 overflow-y-auto overflow-x-hidden relative scroller">
        <ErrorFetching
          styles={'absolute z-10 border shadow-xl bg-brand-white'}
          message={errorMsg}
        />
        <SearchBar
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          activeFilters={table.state.filterKeys}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          exportSettings={table.state.export}
          onSearchWordChange={table.onSearchWordChange}
          headerTranslations={h.translations}
        />
        {loading ? (
          <Loading styles="my-auto" />
        ) : (
          <table className="md:bg-white rounded-lg md:table-fixed w-full">
            <EventTableHeader
              headers={table.state.headers.droppableColumnsSelected}
              sort={table.sort}
            />
            <tbody>
              {currentList.map((e) => (
                <EventTableRow
                  headerKeys={table.state.headers.droppableColumnsSelected}
                  event={e}
                  key={e.eventId}
                  name={e.eventName}
                  open={openEvent}
                  id={e.eventId}
                />
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};
