import {LoginObject, LoginResponse} from "../types/types";
import * as config from "./config";
import {axiosInstance} from "./interceptors";

const login = async (
  data: LoginObject
): Promise<{data: LoginResponse; status: number}> => {
  const url = `${config.baseUrl}/auth/login`;
  return await axiosInstance({method: "POST", url, data});
};

const loginService = {
  login,
};

export default loginService;
