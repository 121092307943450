import React from 'react';
import { messages } from '../../translations/questionTranslations';
import { FormInputInfo } from '../../../../types/types';
import { FormattedMessage } from 'react-intl';

interface Props {
  modalAction: (objectI: FormInputInfo, basket: string) => void;
  modalCancel: (id: string) => void;
  message: string;
  item: FormInputInfo;
  modalId: string;
  basket: string;
  errors: Record<string, Object>;
}

export const QuestionModalButtons: React.FC<Props> = ({
  modalAction,
  modalCancel,
  message,
  item,
  basket,
  modalId,
  errors,
}) => {
  const basic =
    'bg-brand-blue hover:bg-brand-dark_blue text-brand-white m-4 rounded w-20 h-10 border border-gray-200 shadow-lg';

  const amountOfErrors = Object.keys(errors).length;
  const confirmationButton =
    !message && amountOfErrors === 0
      ? basic
      : 'bg-red-300 text-brand-white m-4 rounded w-20 h-10';

  return (
    <div>
      <button
        onClick={() => modalAction(item, basket)}
        className={confirmationButton}
        disabled={message.length > 0 || amountOfErrors > 0}
      >
        <FormattedMessage {...messages.save} />
      </button>
      <button onClick={() => modalCancel(modalId)} className={basic}>
        <FormattedMessage {...messages.cancel} />
      </button>
    </div>
  );
};
