import React from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {FormattedMessageProps} from '../../types/types'

interface Props {
  linkStyles: string
  linkto: string
  message: FormattedMessageProps
  emoji: {emoji: string, arialabel: string}
}

export const WelcomeCardLink: React.FC<Props> = ({message, emoji, linkStyles, linkto}) => {

  return (
    <p className={linkStyles}>
      <Link to={linkto}>
        <FormattedMessage {...message} />
      </Link>
      <span className="text-4xl" role="img" aria-label={emoji.arialabel}>
        {emoji.emoji}
      </span>
    </p>
  )
}
