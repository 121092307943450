import React from 'react';
import { BreadCrumb } from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import { Card } from '../components/utils/Card';
import { messages } from './translations/menu';
import * as nt from '../navigation/navigationTranslations';
import * as imgs from '../img/images';

export const EventMenu: React.FC = () => {
  return (
    <div className="w-11/12 h-screen mx-auto">
      <BreadRoute>
        <BreadCrumb label={'menu'} link={'/eventmenu'} lastChild={true} />
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center">
        <Card
          header={{ ...messages.createheader }}
          paragraph={{ ...messages.createtext }}
          link={`/createevent`}
          linkMessage={{ ...nt.messages.createevent }}
          image={imgs.EVENT_CARD_CREATE}
          canClick={true}
        />
        <Card
          header={{ ...messages.alleventsheader }}
          paragraph={{ ...messages.alleventstext }}
          link={`/events`}
          linkMessage={{ ...nt.messages.allevents }}
          image={imgs.EVENT_CARD_ALL}
          canClick={true}
        />
      </div>
    </div>
  );
};

export default EventMenu;
