import React from 'react';
import {IconWrapper} from '../../utils/IconWrapper';
import {IoIosHelpCircleOutline} from 'react-icons/io';

interface Props {
  onClick: (e: React.MouseEvent) => void;
  overlayId?: string;
}

export const HelpIcon: React.FC<Props> = ({onClick, overlayId}) => {
  return (
    overlayId && (
      <IconWrapper styles={'self-center cursor-pointer hover:text-yellow-500'}>
        <IoIosHelpCircleOutline
          onClick={(e) => onClick(e)}
          size={30}
        ></IoIosHelpCircleOutline>
      </IconWrapper>
    )
  );
};
