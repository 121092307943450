export const LOGO = 'img/CM EVENTS logo horisontal black.png';
export const LOGOSQUARE = 'img/APP_harmaapixeli_1.jpg';
export const CERT_CARD_CREATE = 'img/8.jpg';
export const CERT_CARD_ALL = 'img/8594.jpg';
export const EVENT_CARD_CREATE = 'img/213Z_24.jpg';
export const EVENT_CARD_ALL = 'img/20943642.jpg';
export const EVENT_CARD_DETAILS = 'img/3556940.jpg';
export const EVENT_CARD_PARTICIPANTS = 'img/2867065.jpg';
export const EVENT_CARD_SUBEVENTS = 'img/4957161.jpg';
export const SUBEVENT_CARD_REGISTRATION = 'img/22.jpg';
export const SUBEVENT_CARD_LINK = 'img/5169140.jpg';
export const SUBEVENT_REGISTRATION_SAVE = 'img/1149.jpg';
export const SUBEVENT_SELECTREGISTRATION = 'img/4750850.jpg';
export const NOTFOUND = 'img/10307.jpg';
export const SCANQRCODE = 'img/4123694.jpg';
export const INVOICE_CARD_CREATE = 'img/3711934.jpg';
export const INVOICE_CARD_ALL = 'img/4023504.jpg';
