import React from "react";
import {HelpIcon} from "../form/labels/HelpIcon";
import {HoverAttributes} from "./HoverMessage";

interface SwitchProps {
  id: string;
  header: string;
  show: boolean;
  switchAction: (id: string) => void;
  overlayProps?: HoverAttributes
  divStyle?: string;
  labelStyle?: string;
}

export const Switch: React.FC<SwitchProps> = ({
  id,
  header,
  switchAction,
  show,
  divStyle,
  labelStyle,
  overlayProps
}) => {
  const toggleActivation = show ? "active" : "";


  const toggleOverlay = () => {
    overlayProps.overlayToggle(overlayProps.overlayId);
  };

  const labelStyles = labelStyle
    ? labelStyle
    : "uppercase font-bold text-brand-dark_blue";
  const divStyles = divStyle ? divStyle : "px-5 m-2";

  return overlayProps ? (
    <div className={divStyles}>
      <div className='flex justify-evenly'>
        <div>
          <span className={labelStyles}>{header}</span>
        </div>
        <HelpIcon onClick={toggleOverlay} overlayId={overlayProps.overlayId} />
      </div>
      <div className={`toggle-component ${toggleActivation}`}>
        <div className='toggle-button' onClick={() => switchAction(id)} />
      </div>
    </div>
  ) : (
      <div className={divStyles}>
        <span className={labelStyles}>{header}</span>
        <div className={`toggle-component ${toggleActivation}`}>
          <div className='toggle-button' onClick={() => switchAction(id)} />
        </div>
      </div>
    );
};
