import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  registrationheader: {
    id: 'subevent.card.registration',
    defaultMessage: 'Registration',
  },
  linkheader: {
    id: 'subevent.card.link',
    defaultMessage: 'link',
  },
  registrationtext: {
    id: 'subevent.card.registration.description',
    defaultMessage: 'Continue working with your registration-form.',
  },
  registrationtextalt: {
    id: 'subevent.card.registration.description.noregistration',
    defaultMessage:
      'Here you can create the registration-form for your subevent.',
  },
  linktext: {
    id: 'subevent.card.link.description',
    defaultMessage:
      'Here you can create a link for your events registration-form, so people can register to your subevent.',
  },
  linktextalt: {
    id: 'subevent.card.link.description.noregistration',
    defaultMessage:
      'Here you can create a link for your subevents registration-form, so people can register to your subevent.{lineBreak}Before you can create a link, you must create the registration form first!',
  },
  registrationlink: {
    id: 'navigation.subevent.registration',
    defaultMessage: 'Registration form',
  },
  linklink: {
    id: 'navigation.subevent.link',
    defaultMessage: 'Create link',
  },
}
