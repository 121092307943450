import React from "react";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {IntlMessageID} from "../..";
import {DroppableSettingsBasket} from "./draggable/DroppableSettingsBasket";
import {messages} from './translations'

interface TableSettingsProps {
  columns: any[];
  selectedColumns: any[];
  tableSettingsDiv: string;
  translations?: {[key: string]: IntlMessageID}
  onDragEnd: (dragObject: DropResult) => void;
}

export const TableSettings: React.FC<TableSettingsProps> = ({
  columns,
  tableSettingsDiv,
  selectedColumns,
  translations,
  onDragEnd,
}) => {

  const allColumnsDiv = "flex flex-wrap px-1 text-center min-h-11/12";

  return (
    <div className={tableSettingsDiv}>
      <DragDropContext onDragEnd={onDragEnd}>
        <DroppableSettingsBasket
          title={messages.selectedcolumns}
          id='droppableColumnsSelected'
          wrap={false}
          columns={selectedColumns}
          translations={translations}
        />
        <DroppableSettingsBasket
          title={messages.restofcolumns}
          id='droppableColumnsAll'
          divStyle={allColumnsDiv}
          wrap={true}
          columns={columns}
          translations={translations}
        />
      </DragDropContext>
    </div >
  );
};

export default TableSettings;
