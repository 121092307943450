import React from "react";
import { BreadCrumb, BreadProps } from "./BreadCrumb";

interface Props {}

export const BreadRoute: React.FC<Props> = ({ children }) => {
  const breadcrumbs = React.Children.map(
    children,
    (child: React.ReactElement<BreadProps>, i) => {
      const { label, link, lastChild } = child.props;
      return (
        <BreadCrumb
          key={label}
          label={label}
          link={link}
          lastChild={lastChild}
        />
      );
    }
  );

  return (
    <div>
      <ol className='flex'>{breadcrumbs}</ol>
    </div>
  );
};
