import React from 'react';
import { useSelector } from 'react-redux';
import { FormContainer } from '../../../components/form/FormContainer';
import { ButtonInfo } from '../../../types/types';
import ConsoleHelper from '../../../utils/logger';
import * as fState from '../../../reducers/formReducer';
import * as eState from '../../eventReducer';

interface PreviewFormProps {
  formName: string;
}

export const PreviewForm: React.FC<PreviewFormProps> = ({ formName }) => {
  const previewItems = useSelector(fState.getPreviewFormItems);
  const { backgroundColor, pageHeader } = useSelector(
    eState.getRegistrationStylingData
  );
  const previewFormStyle =
    'bg-brand-white border border-gray-200 content-center flex flex-col m-auto shadow-xl xl:w-11/12';
  const previewButtonInfo: ButtonInfo = {
    style:
      'bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-5 w-auto',
    format_id: 'subevent.registration.preview.confirm.button',
  };

  const confirmPreview = () => {
    ConsoleHelper('hello hello hello');
  };

  return (
    <div
      className="w-full divide-y-2 divide-gray-300 h-full overflow-auto"
      style={{ backgroundColor }}
    >
      {previewItems.length ? (
        <FormContainer
          title={pageHeader}
          formStyle={previewFormStyle}
          buttonInfo={previewButtonInfo}
          formAction={confirmPreview}
          formName={formName}
        />
      ) : null}
    </div>
  );
};

export default PreviewForm;
