import React from 'react';
import {LinkListObject} from '../../eventReducer';

interface Props {
  item: LinkListObject;
}

export const LinkListItem: React.FC<Props> = ({item}) => {
  return (
    <div className="text-brand-white text-lg ml-2 px-4 py-2 m-2 hover:text-brand-dark_blue bg-blue-300 rounded-full">
      <li>
        <a href={item.link}
          target="_blank"
          rel="noreferrer"
        >{item.linkStr}</a>
      </li>
    </div>
  );
}
