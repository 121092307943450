import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface Props {
  message: MessageDescriptor;
  header: boolean;
  values?: { [key: string]: any };
}

export const Paragraph: React.FC<Props> = ({ message, header, values }) => {
  const styles = header
    ? `text-brand-dark_blue font-semibold text-center p-4`
    : `text-gray-600 italic p-4`;

  return (
    <div className={styles}>
      <FormattedMessage {...message} values={{ ...values }} />
    </div>
  );
};

export default Paragraph;
