import React from "react";
import {ExportObject} from "../../types/types";
import {ExportCSV} from "./ExportCSV";

interface ExportSettingsProps {
  exportSettingsDiv: string;
  exportAll: ExportObject;
  exportView: ExportObject;
  toggleId: string;
}

export const ExportSettings: React.FC<ExportSettingsProps> = ({
  exportSettingsDiv,
  exportAll,
  exportView,
  toggleId
}) => {
  const button = "bg-green-400 text-white font-thin h-full w-11/12 rounded";
  const buttonDiv = "w-1/2 h-12";
  const allButtonsDiv = "flex";

  return (
    <div className={exportSettingsDiv}>
      <div className={allButtonsDiv}>
        <ExportCSV
          toggleId={toggleId}
          label={exportAll.label}
          divStyles={buttonDiv}
          buttonStyles={button}
          data={exportAll.data}
          filename={exportAll.filename}
        />
        <ExportCSV
          toggleId={toggleId}
          label={exportView.label}
          divStyles={buttonDiv}
          buttonStyles={button}
          data={exportView.data}
          filename={exportView.filename}
        />
      </div>
    </div>
  );
};

export default ExportSettings;
