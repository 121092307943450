import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as t from '../../navigation/navigationTranslations';
import { Services } from '../../types/types';
import { WelcomeCardLink } from './WelcomeCardLink';

interface WelcomeProps {
  userName: string;
  services: Services;
}

export const WelcomeCard: React.FC<WelcomeProps> = ({ userName, services }) => {
  const linkStyles = 'text-brand-blue hover:text-brand-black';

  return (
    <div className="flex flex-col justify-center">
      <div className="flex justify-center w-full h-full">
        <div className="text-6xl">
          <span className="text-6xl" role="img" aria-label="Waving Hand Sign">
            👋
          </span>
        </div>
        <div className="text-6xl">
          <h2 className="text-6xl">
            <FormattedMessage
              id="welcome.header"
              values={{ user: userName }}
              defaultMessage="Welcome {user}"
            />
          </h2>
        </div>
      </div>
      <div className="text-4xl text-center mt-8">
        {services['events'] && (
          <>
            <WelcomeCardLink
              linkStyles={linkStyles}
              message={t.messages.createevent}
              emoji={{ emoji: '🎉', arialabel: 'Party popper' }}
              linkto="/createevent"
            />
            <WelcomeCardLink
              linkStyles={linkStyles}
              message={t.messages.allevents}
              emoji={{ emoji: '📦', arialabel: 'Package' }}
              linkto="/events"
            />
          </>
        )}
        {services['certificates'] && (
          <>
            <WelcomeCardLink
              linkStyles={linkStyles}
              message={t.messages.createcert}
              emoji={{ emoji: '🎓', arialabel: 'Graduation Cap' }}
              linkto="/createcert"
            />
            <WelcomeCardLink
              linkStyles={linkStyles}
              message={t.messages.allcerts}
              emoji={{ emoji: '📜', arialabel: 'Scroll' }}
              linkto="/certs"
            />
          </>
        )}
        <WelcomeCardLink
          linkStyles={linkStyles}
          message={t.messages.createinvoice}
          emoji={{ emoji: '💸', arialabel: 'Money with wings' }}
          linkto="/createinvoice"
        />
        <WelcomeCardLink
          linkStyles={linkStyles}
          message={t.messages.allinvoices}
          emoji={{ emoji: '🧾', arialabel: 'Receipt' }}
          linkto="/invoices"
        />
        <WelcomeCardLink
          linkStyles={linkStyles}
          message={t.messages.account}
          emoji={{ emoji: '🛠️ ', arialabel: 'Hammer and Wrench' }}
          linkto="/account"
        />
      </div>
    </div>
  );
};

export default WelcomeCard;
