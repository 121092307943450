import React from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {useDispatch} from "react-redux";
import allActions from "../../actions/allActions";

interface ExportCSVProps {
  data: any[];
  label: string;
  filename: string;
  divStyles: string;
  buttonStyles: string;
  toggleId: string
}

export const ExportCSV: React.FC<ExportCSVProps> = ({
  data,
  filename,
  divStyles,
  buttonStyles,
  toggleId,
  label,
}) => {
  const file = `${filename}.xlsx`;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const dispatch = useDispatch()
  const exportData = (
    e: React.FormEvent,
    jsondata: any[],
    filename: string
  ) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(jsondata);
    const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, filename);
    dispatch(allActions.toggleActions.toggle(toggleId))
  };

  return (
    <div className={divStyles}>
      <button
        onClick={(e) => exportData(e, data, file)}
        className={buttonStyles}
      >
        <span>{label}</span>
      </button>
    </div>
  );
};

export default ExportCSV;
