import React, { useCallback, useEffect, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import allActions from '../../actions/allActions';
import FormSelect from '../../components/form/FormSelect';
import Tab from '../../components/tabs/Tab';
import { Tabs } from '../../components/tabs/Tabs';
import { Switch } from '../../components/utils/Switch';
import { AppState } from '../../reducers/combineReducer';
import {
  InvoiceOperator,
  selectInvoicePageData,
  SourceOption,
} from '../../reducers/invoiceReducer';
import storeService from '../../services/storage';
import { destinationMaps } from '../invoiceMappings';
import { InvoiceMappingForm } from './InvoiceMappingForm';
import { InvoiceSendForm } from './InvoiceSendForm';
import InvoiceReceiverTable from '../invoiceReceiverTable/InvoiceReceiverTable';
import {
  selectLoading,
  selectLoadingMultiple,
} from '../../reducers/loadingReducer';
import Loading from '../../components/utils/Loading';
import { selectUserData } from '../../reducers/userReducer';
import { messages } from '../translations';
import { FormattedMessage, useIntl } from 'react-intl';
import ConsoleHelper from '../../utils/logger';
import { ImportCSVData } from '../../types/types';
import { csvReaderStyles } from '../../components/utils/csvReaderStyles';

export const InvoicingPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(null);

  const location = useLocation();

  const { path, url } = useRouteMatch();
  const { userId } = useSelector((state: AppState) => selectUserData(state));
  const { formatMessage } = useIntl();

  const receiversTab = formatMessage({ ...messages.receiverstab });
  const mappingTab = formatMessage({ ...messages.mappingtab });
  const sendTab = formatMessage({ ...messages.sendingtab });

  const sources: SourceOption[] = ['', 'ContactMate', 'CSV-import'];

  const destinations: InvoiceOperator[] = ['Fennoa'];

  const dispatch = useDispatch();
  const {
    selectedSource,
    selectedEvent,
    destination,
    events,
    multiSend,
    destinationMapping,
    eventParticipants,
    participantHeaders,
  } = useSelector((state: AppState) => selectInvoicePageData(state));

  const [importHeaders, setImportHeaders] = useState(true);

  const tableName = `${userId}_invoice_${selectedEvent}_receivers`;

  const savedHeaders = storeService.getHeaders(tableName);

  const headers = savedHeaders.length > 0 ? savedHeaders : participantHeaders;

  const eventOptions = [''].concat(events.map((e) => e.name));

  const isLoading = useSelector((state: AppState) =>
    selectLoadingMultiple(state, [
      'getEvents',
      'getParticipants',
      'sendInvoice',
      'getFennoaProducts',
    ])
  );

  const tableLoading = useSelector((state: AppState) =>
    selectLoading(state, 'getParticipants')
  );

  const changeEventSource = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const source = event.target.value as SourceOption;
    dispatch(allActions.invoiceActions.changeEventsource(source));
  };

  const changeEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.length > 0) {
      const event = events.find((event) => event.name === e.target.value);
      dispatch(
        allActions.invoiceActions.changeEvent(
          event.id,
          selectedSource,
          event.name
        )
      );
    }
  };

  const handleOnDrop = (data: ImportCSVData[]) => {
    dispatch(allActions.invoiceActions.importReceivers(data, importHeaders));
  };

  const handleOnError = (err: Error) => {
    ConsoleHelper(err);
  };

  const changeMapping = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = e.target.value as InvoiceOperator;
    const mapping = destinationMaps[key];
    dispatch(allActions.invoiceActions.setMapping(mapping, key));
  };

  const toggleMultisend = () => {
    dispatch(allActions.invoiceActions.toggleMultisend());
  };

  const getActiveTab = useCallback(() => {
    const pathArray = location.pathname.split('/');
    return pathArray.length === 2
      ? setActiveTab(mappingTab)
      : setActiveTab(pathArray[pathArray.length - 1]);
  }, [location, mappingTab]);

  const getAllEvents = useCallback(
    (source: SourceOption) => {
      if (source === 'ContactMate')
        dispatch(
          allActions.eventActions.getAllEvents({ date: '0', limit: '100' })
        );
    },
    [dispatch]
  );

  const getAllFennoaProducts = useCallback(() => {
    dispatch(allActions.invoiceActions.startGetFennoaProducts());
  }, [dispatch]);

  useEffect(() => {
    getAllEvents(selectedSource);
  }, [selectedSource, getAllEvents]);

  useEffect(() => {
    getActiveTab();
  }, [getActiveTab]);

  useEffect(() => {
    getAllFennoaProducts();
  }, [getAllFennoaProducts]);

  return (
    <div className="w-full">
      <div className="flex">
        <FormSelect
          id="eventSource"
          question={formatMessage({ ...messages.eventsource })}
          handleInputChange={changeEventSource}
          options={sources}
          value={selectedSource}
        />
        {selectedSource === 'CSV-import' ? (
          <>
            <Switch
              id="dataHasHeaders"
              header={formatMessage({ ...messages.csvheaders })}
              show={importHeaders}
              labelStyle={'text-sm italic text-brand-dark_blue'}
              divStyle={'mt-8'}
              switchAction={() => setImportHeaders(!importHeaders)}
            />
            <div>
              <CSVReader
                style={csvReaderStyles}
                onDrop={handleOnDrop}
                onError={handleOnError}
              >
                <span className="italic text-sm text-brand-dark_blue">
                  <FormattedMessage {...messages.csvupload} />
                </span>
              </CSVReader>
            </div>
          </>
        ) : selectedSource !== '' ? (
          <>
            <FormSelect
              id="invoicingEvent"
              question={formatMessage({ ...messages.event })}
              handleInputChange={changeEvent}
              options={eventOptions}
              value={selectedEvent}
            />
            <Switch
              id="certSendType"
              header={formatMessage({ ...messages.multiple })}
              show={multiSend}
              switchAction={toggleMultisend}
            />
          </>
        ) : null}
        <FormSelect
          id="destination"
          question={formatMessage({ ...messages.destination })}
          handleInputChange={changeMapping}
          options={destinations}
          value={destination}
        />
      </div>
      <Tabs>
        <Tab
          activeLabel={activeTab}
          label={receiversTab}
          link={`${url}/${receiversTab}`}
        />
        <Tab activeLabel={activeTab} label={mappingTab} link={`${url}`} />
        <Tab
          activeLabel={activeTab}
          label={sendTab}
          link={`${url}/${sendTab}`}
        />
      </Tabs>
      <div
        className={`h-full max-h-5/6 w-full flex justify-center items-center border-r border-l border-b border-gray-400 ${
          activeTab === mappingTab ? 'overflow-auto scroller' : ''
        }`}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Route
              path={`${path}/${receiversTab}`}
              render={() => (
                <InvoiceReceiverTable
                  loading={tableLoading}
                  list={eventParticipants}
                  headerRow={headers}
                  tableName={tableName}
                />
              )}
            />
            <Route
              exact
              path={`${path}`}
              render={() => (
                <InvoiceMappingForm
                  mapping={destinationMapping}
                  multiSend={multiSend}
                />
              )}
            />
            <Route
              path={`${path}/${sendTab}`}
              render={() => <InvoiceSendForm />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InvoicingPage;
