import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CertTable from './allCertsTable/CertTable';
import * as cState from './certReducer';
import history from '../history/history';
import BreadCrumb from '../components/breadcrumbs/BreadCrumb';
import { BreadRoute } from '../components/breadcrumbs/BreadRoute';
import * as uState from '../reducers/userReducer';
import storeService from '../services/storage';
import allActions from '../actions/allActions';
import { AppState } from '../reducers/combineReducer';
import { selectLoading } from '../reducers/loadingReducer';

export const AllCerts: React.FC = () => {
  const { data, headerRow } = useSelector(cState.selectAllCustomerCerts);
  const isLoading = useSelector((state: AppState) =>
    selectLoading(state, 'getAllCerts')
  );
  const { userId } = useSelector(uState.selectUserData);
  const crumbs = [
    { label: 'certmenu', link: '/certmenu' },
    { label: 'certs', link: '/certs' },
  ];
  const dispatch = useDispatch();
  const tableName = `certs_${userId}`;
  const savedHeaders = storeService.getHeaders(tableName);
  const headers = savedHeaders.length > 0 ? savedHeaders : headerRow;

  const gotoCert = (certOperationId: string) => {
    history.push(`/certs/${certOperationId}`);
  };

  const getAllCerts = useCallback(() => {
    dispatch(allActions.certActions.getAllCerts());
  }, [dispatch]);

  useEffect(() => {
    getAllCerts();
  }, [getAllCerts]);

  return (
    <div className="w-11/12 h-full mx-auto">
      <BreadRoute>
        {crumbs.map((c, i) => {
          const last = i + 1 === crumbs.length;
          return (
            <BreadCrumb
              key={c.link}
              label={c.label}
              link={c.link}
              lastChild={last}
            />
          );
        })}
      </BreadRoute>
      <div className="h-full max-h-11/12 w-full flex justify-center items-center overflow-auto scroller">
        <CertTable
          list={data}
          gotoCert={gotoCert}
          headerRow={headers}
          tableName={tableName}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default AllCerts;
