import { FormInputInfo } from '../types/types';

export const defaultQuestions: FormInputInfo[] = [
  {
    id: 'RegistrationFirstName',
    question: 'Etunimi',
    type: 'text',
    show: true,
    group: 1,
    page: 1,
    required: { type: 'any' },
    person: true,
    dbKeys: {
      key: 'first_name',
      entryValue: 'partypantsFirstName',
      leadKey: true,
      dbKey: true,
    },
  },
  {
    id: 'RegistrationLastName',
    question: 'Sukunimi',
    type: 'text',
    show: true,
    group: 1,
    page: 1,
    required: { type: 'any' },
    person: true,
    dbKeys: {
      key: 'last_name',
      entryValue: 'partypantsLastName',
      leadKey: true,
      dbKey: true,
    },
  },
  {
    id: 'RegistrationEmail',
    question: 'Sahkoposti',
    type: 'text',
    show: true,
    group: 1,
    page: 1,
    required: { type: 'email' },
    person: true,
    dbKeys: {
      key: 'email',
      entryValue: 'partypantsEmail',
      leadKey: true,
      dbKey: true,
    },
  },
];
