import React from "react";
import {useSelector} from "react-redux";
import {WelcomeCard} from "../components/utils/WelcomeCard";
import * as uState from "../reducers/userReducer";

export const MainPage: React.FC = () => {
  const user = useSelector(uState.getUser);
  const services = useSelector(uState.selectServices)

  return (
    <div className='container h-screen max-h-5/6 w-screen mx-auto flex justify-center items-center'>
      <WelcomeCard userName={user} services={services} />
    </div>
  );
};

export default MainPage;
