import { DefinedMessages } from '../../../types/types';

export const messages: DefinedMessages = {
  delete: {
    id: 'registration.subevent.dialog.delete.message',
    defaultMessage: 'Are you sure you want to delete the question?',
  },
  addbasket: {
    id: 'registration.subevent.form.addbasket.button',
    defaultMessage: 'Add',
  },
  removebasket: {
    id: 'registration.subevent.form.removebasket.button',
    defaultMessage: 'Remove',
  },
  addquestion: {
    id: 'registration.subevent.form.addquestion.button',
    defaultMessage: 'Add',
  },
  lastbasket: {
    id: 'registration.subevent.form.lastbasket.title',
    defaultMessage: 'Trash',
  },
  basketunit: {
    id: 'registration.subevent.form.lastbasket.unit',
    defaultMessage: 'Page',
  },
};
