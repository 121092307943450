import React from 'react';
import ErrorFetching from '../../../components/modals/ErrorFetching';
import { FormInputInfo } from '../../../types/types';
import ParticipantFormInput from './ParticipantFormInput';

interface Props {
  items: FormInputInfo[];
  error: string;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    array: boolean
  ) => void;
  values: { [key: string]: string | string[] };
}

export const ParticipantForm: React.FC<Props> = ({
  items,
  error,
  values,
  handleInputChange,
}) => {
  return (
    <div className="flex-1 w-full h-full mx-auto rounded shadow-xl border border-gray-200 overflow-y-auto overflow-x-hidden scroller">
      <table className="table-fixed w-full relative">
        <tbody>
          {items.map((item) => {
            const key =
              item.question.trim().length === 0 ? item.id : item.question;
            return (
              <tr key={item.id} className="table-row">
                <td className="w-1/3 my-2 py-4 pl-8 uppercase border border-gray-200 text-brand-dark_blue">
                  {key}
                </td>
                <td className="w-2/3 h-full border border-gray-200 ">
                  <ParticipantFormInput
                    styles="box-border py-4 pl-6 h-full w-full truncate relative"
                    value={values[item.id] || ''}
                    item={item}
                    handleInputChange={handleInputChange}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <ErrorFetching
            message={error}
            styles={
              'absolute z-10 top-1/2 right-1/2 px-10 p-6 bg-brand-white border shadow-lg rounded'
            }
          />
        </tfoot>
      </table>
    </div>
  );
};

export default ParticipantForm;
