import {
  Cert,
  CertNewResponse,
  NewMultiCertRequest,
  NewSingleCertRequest,
  RequestHeader,
} from '../types/types';
import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

const getCertNew = async (
  template?: string
): Promise<{
  data: CertNewResponse;
  status: number;
  template: string | null;
}> => {
  const url = `${config.baseUrl}/cert/new`;
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const result = await axiosInstance({ url, headers });
  return {
    data: result.data,
    status: result.status,
    template: template ? template : null,
  };
};

const newCertSend = async (
  data: NewSingleCertRequest | NewMultiCertRequest
) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/cert/new`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const getAllCerts = async (): Promise<{ data: Cert[]; status: number }> => {
  const url = `${config.baseUrl}/cert/customercerts`;
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  return await axiosInstance({ url, headers });
};

const resend = async (mailId: string) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/cert/resend`;
  const data = { mailId };
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const fileUpload = async (imageBlob: Blob) => {
  const Jwt = storeService.getString('jwt');
  const headers = { Jwt, 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();
  const file = new File([imageBlob], 'croppedPicture.jpg');
  formData.append('picture', file);
  return await axiosInstance.post(`${config.baseUrl}/file/test`, formData, {
    headers,
  });
};

const certService = {
  getCertNew,
  newCertSend,
  getAllCerts,
  resend,
  fileUpload,
};

export default certService;
