import {DefinedMessages} from '../../../types/types';

export const messages: DefinedMessages = {
  pageheader: {
    id: 'registration.subevent.form.pageheader',
    defaultMessage: 'Header',
  },
  pagedescription: {
    id: 'registration.subevent.form.pagedescription',
    defaultMessage: 'Page description',
  },
  pageregistrationfullheader: {
    id: 'registration.subevent.form.pageregistrationfullheader',
    defaultMessage: 'Registration full (header)',
  },
  PageRegistrationFullText: {
    id: 'registration.subevent.form.pageregistrationfulltext',
    defaultMessage: 'Registration full (message)',
  },
  PageThankYouHeader: {
    id: 'registration.subevent.form.pageregistrationthankyouheader',
    defaultMessage: 'Thank you (header)',
  },
  pagethankyoutext: {
    id: 'registration.subevent.form.pageregistrationthankyoutext',
    defaultMessage: 'Thank you (message)',
  },
  PageRegistrationNotYetOpenHeader: {
    id: 'registration.subevent.form.pageregistrationnotyetopenheader',
    defaultMessage: 'Registration not yet open (header)',
  },
  PageRegistrationNotYetOpenText: {
    id: 'registration.subevent.form.pageregistrationnotyetopentext',
    defaultMessage: 'Registration not yet open (message)',
  },
  PageRegistrationEndedHeader: {
    id: 'registration.subevent.form.pageregistrationendedheader',
    defaultMessage: 'Registration ended (header)',
  },
  PageRegistrationEndedText: {
    id: 'registration.subevent.form.pageregistrationendedtext',
    defaultMessage: 'Registration ended (message)',
  },
  backgroundcolor: {
    id: 'registration.subevent.form.backgroundcolor',
    defaultMessage: 'Backgroundcolor',
  }
}
