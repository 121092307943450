import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import * as rState from '../../eventReducer';
import { ErrorList } from './ErrorList';
import * as t from '../translations/validationTranslations';

interface Props {}

export const ValidationList: React.FC<Props> = () => {
  const {
    missingRequiredDatabaseFields,
    missingRequiredEmailFields,
    missingReservationQuestion,
    hideValidationErrors,
    repeatableValidationErrors,
    dbFieldValidationErrors,
    mailKeysValidationErrors,
    missingInformation,
    errors,
  } = useSelector(rState.selectRegistrationValidationData);

  const errorColor = errors > 0 ? 'text-red-400' : 'text-green-400';

  return (
    <div className="w-full divide-y-2 divide-gray-300 h-full overflow-auto scroller">
      <div>
        <span className="semibold font-sm uppercase text-lg text-brand-dark_blue ml-1">
          <FormattedMessage {...t.messages.count} />
        </span>
        <ul>
          <li className={`${errorColor} text-lg ml-2`}>
            <span>{errors}</span>
          </li>
        </ul>
      </div>
      <div>
        <span className="semibold font-sm uppercase text-lg text-brand-dark_blue ml-1">
          <FormattedMessage {...t.messages.group} />
        </span>
        <ul>
          {missingReservationQuestion ? (
            <li className="text-red-400 text-lg ml-2">
              <FormattedMessage {...t.messages.reservation} />
            </li>
          ) : null}
        </ul>
      </div>
      <div>
        <span className="semibold font-sm uppercase text-lg text-brand-dark_blue ml-1">
          <FormattedMessage {...t.messages.info} />
        </span>
        <ul>
          {missingInformation.map((mis) => (
            <li className="text-red-400 text-lg ml-2">
              <FormattedMessage
                id={mis.message}
                defaultMessage={mis.defaultMessage}
              />
            </li>
          ))}
        </ul>
      </div>
      <div>
        <span className="semibold font-sm uppercase text-lg text-brand-dark_blue ml-1">
          <FormattedMessage {...t.messages.database} />
        </span>
        <ul>
          {missingRequiredDatabaseFields.map((missing) => (
            <li key={missing.key} className="text-red-400 text-lg ml-2">
              {missing.name}
              <ul>
                {missing.additionalMessages.map((additionalMessage, i) => (
                  <li
                    key={`${additionalMessage}_${i}`}
                    className="italic font-thin ml-6"
                  >
                    <FormattedMessage
                      id={additionalMessage}
                      defaultMessage="This rule has additional errors!"
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <span className="semibold font-sm uppercase text-lg text-brand-dark_blue ml-1">
          <FormattedMessage {...t.messages.email} />
        </span>
        <ul>
          {missingRequiredEmailFields.map((missing) => (
            <li
              key={missing.internalDbValue}
              className="text-red-400 text-lg ml-2"
            >
              {missing.fieldName}
            </li>
          ))}
        </ul>
      </div>
      <ErrorList
        header={{ ...t.messages.hide }}
        errors={hideValidationErrors}
      />
      <ErrorList
        header={{ ...t.messages.repeatable }}
        errors={repeatableValidationErrors}
      />
      <ErrorList
        header={{ ...t.messages.validationdatabase }}
        errors={dbFieldValidationErrors}
      />
      <ErrorList
        header={{ ...t.messages.mailkey }}
        errors={mailKeysValidationErrors}
      />
    </div>
  );
};

export default ValidationList;
