import React from 'react';
import {HoverAttributes} from '../../utils/HoverMessage';
import {HelpIcon} from './HelpIcon';
import {RequiredAsterisk} from './RequiredAsterisk';

interface Props {
  labelStyle?: string;
  register?: any;
  question: string;
  overlayProps: HoverAttributes
}

export const LabelWithHelpIcon: React.FC<Props> = ({
  labelStyle,
  question,
  overlayProps: {overlayToggle, overlayId},
  register,
}) => {
  const labelStyles = labelStyle
    ? labelStyle
    : 'mb-2 ml-2 uppercase font-bold text-lg text-brand-dark_blue';

  const toggleOverlay = () => {
    overlayToggle(overlayId);
  };

  return (
    <div className={`flex justify-start ${labelStyles}`}>
      <div>
        <span>
          {question}
          <RequiredAsterisk register={register} />
        </span>
      </div>
      <HelpIcon onClick={toggleOverlay} overlayId={overlayId} />
    </div>
  );
};
