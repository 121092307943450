import React  from 'react';
import { EventObject } from '../../../types/types';
import { TableData } from '../../../components/utils/TableData';

interface EventRowProps {
  name: string;
  id: string;
  headerKeys: string[];
  event: EventObject;
  open: (eventId: string, subEventId?: string) => void;
}

export const EventTableRow: React.FC<EventRowProps> = ({
  event,
  headerKeys,
  name,
  id,
  open,
}) => {

  const rowStyles =
    'hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';

  return (
    <>
      <tr className={rowStyles}>
        {headerKeys.map((key, i) => {
          const value = event[key]
          return (
            <TableData key={i} value={value} open={open} id={id} />
          );
        })}
      </tr>
    </>
  );
};

export default EventTableRow;
