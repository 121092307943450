import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { LabelContainer } from './labels/LabelContainer';
import { RepeatableHeader } from './divs/RepatableHeader';
import { ErrorDiv } from './divs/ErrorDiv';
import { RepeatableButtons } from './buttons/RepeatableButtons';
import { HoverAttributes } from '../utils/HoverMessage';

interface FormCheckBoxProps {
  id: string;
  options?: string[];
  question: string;
  repeatable?: boolean;
  divider?: string;
  inputStyle?: string;
  labelStyle?: string;
  divStyle?: string;
  dividerHeaderStyle?: string;
  optionLabelStyle?: string;
  containerStyle?: string;
  register?: any;
  errors?: Record<string, Object>;
  link?: string | boolean;
  cursiveLabel?: string;
  repeatableHeader?: string;
  repeatableKey?: { key: string; last: boolean; amount: number };
  checked: string[];
  index?: number;
  centerCheckBox?: boolean;
  overlayProps?: HoverAttributes;
  validate?: (id: string) => void;
  changeAmount?: (key: string, direction: number) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLElement>,
    id: string,
    type?: string
  ) => void;
}

export const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  id,
  options,
  handleInputChange,
  question,
  divider,
  inputStyle,
  labelStyle,
  divStyle,
  cursiveLabel,
  checked,
  repeatableKey,
  index,
  centerCheckBox,
  containerStyle,
  dividerHeaderStyle,
  repeatable,
  optionLabelStyle,
  register,
  errors,
  link,
  overlayProps,
  repeatableHeader,
  changeAmount,
  validate,
}) => {
  const styles = inputStyle
    ? inputStyle
    : 'text-gray-700 transition transform duration-500 ease-in-out hover:scale-105';

  const divStyles = divStyle
    ? divStyle
    : 'flex flex-col mb-4 xl:w-11/12 xl:m-auto xl:pt-2';

  const participantId = repeatable ? id.split('_')[1] : ''; //expected id is 'BLABLABLA_2' => 2

  const dividerHeaderStyles = dividerHeaderStyle
    ? dividerHeaderStyle
    : 'm-2 uppercase font-bold text-lg text-brand-blue';

  const optionLabelStyles = optionLabelStyle
    ? optionLabelStyle
    : 'text-lg text-brand-dark_blue relative cursor-pointer select-none';

  const dividerStyles = divider ? divider : '';
  const containerStyles = containerStyle ? containerStyle : '';

  const optionsDiv = 'xl:flex xl:flex-wrap xl:pb-5 xl:pt-2 pl-8 xl:pl-6';

  const repeatIndex = participantId ? participantId : 1;

  const isChecked = (option: string, checked: string[]) => {
    return (
      checked.find((checkedOption) => checkedOption === option) !== undefined
    );
  };

  return (
    <div className={`${divStyles} ${dividerStyles} ${containerStyles}`}>
      <RepeatableHeader
        divider={divider}
        repeatable={repeatable}
        label={`${repeatableHeader} ${repeatIndex}`}
        style={dividerHeaderStyles}
      />
      <LabelContainer
        link={link}
        question={question}
        labelStyle={labelStyle}
        register={register}
        cursiveLabel={cursiveLabel}
        overlayProps={overlayProps}
      />
      <fieldset tabIndex={index}>
        <div className={optionsDiv}>
          {options?.map((option, i) => (
            <div
              key={option}
              className={`w-full ${
                centerCheckBox ? '' : 'xl:w-1/3'
              } pl-4 pt-3 xl:pt-0 ${styles}`}
            >
              <label
                htmlFor={`${id}:${option}`}
                className={`${optionLabelStyles} multiSelect`}
                tabIndex={i}
              >
                <input
                  name={id}
                  value={option || ''}
                  type="checkbox"
                  id={`${id}:${option}`}
                  ref={register}
                  onChange={(e) => handleInputChange(e, id, 'array')}
                  checked={isChecked(option, checked)}
                  onBlur={validate ? () => validate(id) : null}
                />
                <span
                  className={`absolute top-0 -left-6 h-4 w-4 ${
                    isChecked(option, checked)
                      ? 'bg-blue-400'
                      : 'bg-blue-100 checkmark'
                  }`}
                >
                  {isChecked(option, checked) ? (
                    <AiOutlineCheck
                      style={{
                        paddingBottom: '3px',
                        paddingRight: '3px',
                        color: '#00255f',
                      }}
                    />
                  ) : null}
                </span>
                {option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <ErrorDiv errors={errors} id={id} />
      <RepeatableButtons
        repeatableKey={repeatableKey}
        changeAmount={changeAmount}
        repeatableHeader={repeatableHeader}
      />
    </div>
  );
};

export default FormCheckBox;
