import React from 'react';
import {useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import allActions from '../actions/allActions';
import FormInput from '../components/form/FormInput';
import ErrorFetching from '../components/modals/ErrorFetching';
import * as eState from '../reducers/errorReducer';
import * as fState from '../reducers/formReducer';
import {useNotify} from '../hooks/useNotify';
import * as imgs from '../img/images';
import {DefinedMessages, LoginObject} from '../types/types';

interface LoginFormProps {
  formName: string;
  formAction: (data: LoginObject) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  formName,
  formAction,
}) => {
  const dispatch = useDispatch();
  const {register, handleSubmit, errors} = useForm();
  const formState = useSelector(fState.getFormState);
  const {formatMessage} = useIntl();
  const {username, password} = useSelector(fState.getLoginFormValues);
  const {message} = useSelector(eState.getErrorMessage);
  const errorMsg = useNotify(message);
  const styles =
    'w-1/3 flex-col flex-wrap border border-gray-200 shadow-xl justify-center items-center p-5';
  const inputFieldStyle =
    'font-thin text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal';
  const usernameInputId = 'username';
  const passwordInputId = 'password';
  const buttonInfo = {
    style:
      'bg-blue-400 hover:bg-brand-blue font-thin text-brand-white py-2 px-4 rounded m-5',
    format_id: 'login.button',
  };
  const messages: DefinedMessages = {
    user: {
      id: 'login.username.label',
      defaultMessage: 'Username',
    },
    password: {
      id: 'login.password.label',
      defaultMessage: 'Password',
    },
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: string, type?: string) => {
    if (type === 'array') {
      const currentValue: string[] | string = formState[id];
      const newArray =
        currentValue === undefined
          ? []
          : typeof currentValue === 'string'
            ? [currentValue]
            : currentValue;
      dispatch(
        allActions.formActions.addToValue(
          id,
          newArray,
          event.target.value,
          formName
        )
      );
    } else {
      dispatch(
        allActions.formActions.updateValue(id, event.target.value, formName)
      );
    }
  };

  return (
    <form className={styles} onSubmit={handleSubmit(formAction)}>
      <img
        className="p-4"
        src={`${process.env.PUBLIC_URL}/${imgs.LOGO}`}
        alt="logo"
      />
      <FormInput
        id={usernameInputId}
        value={username || ''}
        placeholder={formatMessage({...messages.user})}
        inputStyle={inputFieldStyle}
        type="text"
        handleInputChange={handleInputChange}
        register={register({required: `any`})}
        errors={errors}
      />
      <FormInput
        id={passwordInputId}
        value={password || ''}
        type={passwordInputId}
        inputStyle={inputFieldStyle}
        handleInputChange={handleInputChange}
        placeholder={formatMessage({...messages.password})}
        register={register({required: `any`})}
        errors={errors}
      />
      <ErrorFetching message={errorMsg} />
      <div className="m-auto w-1/3">
        <button type="submit" className={buttonInfo.style}>
          <FormattedMessage id={buttonInfo.format_id} defaultMessage="Login" />
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
