import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as t from './translations';

interface Props {
  repeatableKey?: { key: string; last: boolean; amount: number };
  changeAmount?: (key: string, direction: number) => void;
  repeatableHeader?: string;
}

export const RepeatableButtons: React.FC<Props> = ({
  repeatableKey,
  repeatableHeader,
  changeAmount,
}) => {
  if (repeatableKey === undefined || (repeatableKey && !repeatableKey.last)) {
    return null;
  }

  const buttonStyle =
    'bg-blue-400 hover:bg-brand-blue text-brand-white py-2 px-4 rounded m-5 w-auto';

  const hideDecreaseRepeatableStyle = repeatableKey
    ? Number(repeatableKey.amount) === 1
      ? 'invisible focus:outline-none outline-none'
      : 'visible'
    : null;

  return (
    <div className="flex flex-row justify-between">
      <button
        onClick={() => changeAmount(repeatableKey.key, 1)}
        type="button"
        className={`${buttonStyle}`}
      >
        <FormattedMessage
          {...t.messages.add}
          values={{ type: repeatableHeader.toLowerCase() }}
        />
      </button>
      <button
        onClick={() => changeAmount(repeatableKey.key, -1)}
        type="button"
        className={`${buttonStyle} ${hideDecreaseRepeatableStyle}`}
      >
        <FormattedMessage
          {...t.messages.remove}
          values={{ type: repeatableHeader.toLowerCase() }}
        />
      </button>
    </div>
  );
};
