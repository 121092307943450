import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../actions/allActions';
import FormInput from '../../components/form/FormInput';
import FormSelect from '../../components/form/FormSelect';
import { Switch } from '../../components/utils/Switch';
import { AppState } from '../../reducers/combineReducer';
import { getInheritance } from '../../reducers/invoiceReducer';
import { InputDetails } from '../invoiceMappings';

type Props = {
  input: InputDetails;
  value: string;
  id: string;
  handleInputChange: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  options: string[];
  last: boolean;
  register: any;
};

export const InvoiceMultiInput: React.FC<Props> = ({
  input,
  id,
  value,
  handleInputChange,
  options,
  last,
  register,
}) => {
  const dispatch = useDispatch();
  const inheritance = useSelector((state: AppState) =>
    getInheritance(state, id)
  );

  const toggleInheritance = () => {
    dispatch(allActions.invoiceActions.changeInheritance(id));
  };

  return (
    <div className={`flex w-1/2 ${last ? 'pb-4' : ''}`}>
      {inheritance === 'Hardcoded' ? (
        <FormInput
          type="text"
          value={value}
          id={id}
          handleInputChange={(e) => handleInputChange(e, id)}
          question={input.name}
          register={input.required ? register({ required: `any` }) : null}
        />
      ) : (
        <FormSelect
          value={value}
          id={input.id}
          handleInputChange={(e) => handleInputChange(e, id)}
          options={options}
          question={input.name}
          register={input.required ? register({ required: `any` }) : null}
        />
      )}

      <Switch
        labelStyle={'text-sm uppercase font-semibold text-brand-dark_blue'}
        switchAction={toggleInheritance}
        header={'hard coded'}
        id={`${id}_type_toggle`}
        show={inheritance === 'Hardcoded'}
      />
    </div>
  );
};
