import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as erState from '../../reducers/errorReducer';
import ErrorFetching from '../../components/modals/ErrorFetching';
import Pagination from '../../components/utils/Pagination';
import SearchBar from '../../components/tableSearchBar/SearchBar';
import { useNotify } from '../../hooks/useNotify';
import { CertMail } from '../../types/types';
import { mapFromKeys, getUniqueKeysForTable } from '../../utils/functions';
import { CertHeader } from './CertHeader';
import { CertTableRow } from './CertTableRow';
import * as h from './headerTranslations';
import * as t from '../../components/tableSearchBar/translations';
import { useTable } from '../../hooks/useTable';
import Loading from '../../components/utils/Loading';
import { BackDrop } from '../../components/tableSearchBar/BackDrop';
import { useIntl } from 'react-intl';

interface TableProps {
  list: any[];
  headerRow: string[];
  tableName: string;
  resend: (id: string) => void;
  goEditCert: (id: string) => void;
  loading: boolean;
}

export const CertTable: React.FC<TableProps> = ({
  list,
  headerRow,
  tableName,
  resend,
  goEditCert,
  loading,
}) => {
  const { message } = useSelector(erState.getErrorMessage);
  const errorMsg = useNotify(message);
  const { formatMessage } = useIntl();
  const filterDivToggleId = 'CERT_DETAILS_TABLE_FILTER';
  const tableSettingsDivId = 'CERT_DETAILS_SETTINGS';
  const exportSettingsDivId = 'CERT_DETAILS_EXPORT_SETTINGS';
  const pageLimit = 9;

  const initialState = {
    tableName,
    tableList: list,
    incomingHeaders: headerRow,
    currentPage: 1,
    totalPages: Math.ceil(list.length / pageLimit),
    pageLimit: pageLimit,
    totalRecords: list.length,
    pageNeighbours: 0,
    filteredList: list,
    currentList: list.slice(0, pageLimit),
    filterKeys: [],
    searchWord: '',
    sortKey: headerRow[0],
    ascending: false,
    possibleSearchKeys: list.length ? Object.keys(list[0]) : [],
    currentHeaders: headerRow,
    headers: {
      droppableColumnsSelected: headerRow,
      droppableColumnsAll: list.length
        ? getUniqueKeysForTable(list).filter(
            (item) => !headerRow.includes(item)
          )
        : [],
    },
    export: {
      exportAll: {
        data: mapFromKeys(list, getUniqueKeysForTable(list)),
        filename: 'cert_details',
        label: formatMessage({ ...t.messages.exportall }),
      },
      exportView: {
        data: mapFromKeys(list, headerRow),
        filename: 'cert_view_details',
        label: formatMessage({ ...t.messages.exportview }),
      },
    },
  };

  const table = useTable(initialState);
  const currentList = table.state.currentList as CertMail[];

  const updateTableData = useCallback(() => {
    table.changeHeadersAndTable(list, headerRow);
  }, [list, headerRow]); //eslint-disable-line

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  return (
    <>
      <BackDrop
        modalIds={[filterDivToggleId, tableSettingsDivId, exportSettingsDivId]}
      />
      <div className="flex flex-col shadow-lg bg-brand-white md:w-full h-full overflow-y-auto overflow-x-hidden scroller">
        <ErrorFetching message={errorMsg} />
        <SearchBar
          sortKeys={table.state.possibleSearchKeys}
          onDragEnd={table.onDragEnd}
          filterDivToggleId={filterDivToggleId}
          tableSettingsDivToggleId={tableSettingsDivId}
          exportSettingsDivToggleId={exportSettingsDivId}
          selectedColumns={table.state.headers.droppableColumnsSelected}
          restColumns={table.state.headers.droppableColumnsAll}
          activeFilters={table.state.filterKeys}
          toggleFilter={table.toggleFilter}
          clearFilters={table.clearFilters}
          exportSettings={table.state.export}
          onSearchWordChange={table.onSearchWordChange}
          headerTranslations={h.translations}
        />
        {loading ? (
          <Loading styles="my-auto" />
        ) : (
          <table className="md:bg-white rounded-lg md:table-fixed w-full">
            <CertHeader
              headers={table.state.headers.droppableColumnsSelected}
              sort={table.sort}
            />
            <tbody>
              {currentList.map((p, i) => (
                <CertTableRow
                  goEditCert={goEditCert}
                  resend={resend}
                  headerKeys={table.state.headers.droppableColumnsSelected}
                  cert={p}
                  key={i}
                />
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          pageLimit={table.state.pageLimit}
          totalPages={table.state.totalPages}
          pageNeighbours={table.state.pageNeighbours}
          currentPage={table.state.currentPage}
          onPageChanged={table.gotoPage}
        />
      </div>
    </>
  );
};

export default CertTable;
