import React from "react";
import { useState } from "react";

interface ImageProps {
  imageUrl: string;
}

export const ImageComponent: React.FC<ImageProps> = ({ imageUrl }) => {
  const [expanded, setExpanded] = useState(false);

  const handleSetExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`min-w-1/4 cursor-pointer ${
        expanded ? "" : "border-gray-200 border shadow-lg"
      }`}
    >
      {expanded ? (
        <dialog
          className='absolute top-0 border-gray-200 border shadow-lg'
          open
          onClick={handleSetExpanded}
        >
          <img
            className='w-full'
            src={imageUrl}
            onClick={handleSetExpanded}
            alt='template_preview'
          />
        </dialog>
      ) : (
        <img
          src={imageUrl}
          onClick={handleSetExpanded}
          alt='template_preview'
        />
      )}
    </div>
  );
};
