import React from 'react';
import TableData from '../../components/utils/TableData';

interface Props {
  receiver: any;
  headerKeys: string[];
}

export const InvoicesTableRow: React.FC<Props> = ({ headerKeys, receiver }) => {
  const rowStyles =
    'hover:text-blue-400 flex flex-col flex-nowrap md:table-row mb-2 md:mb-0';
  const styles = 'border px-8 py-4 h-12 truncate';
  return (
    <tr className={rowStyles}>
      {headerKeys.map((key, i) => {
        const value = receiver[key];
        return <TableData key={i} value={value} styles={styles} />;
      })}
    </tr>
  );
};
