import {DefinedMessages} from '../../../types/types'

export const messages: DefinedMessages = {
  importheader: {
    id: 'registration.import.header',
    defaultMessage: 'Import from existing subevents'
  },
  selectsubevent: {
    id: "registration.import.selectsubevent",
    defaultMessage: "subevent"
  },
  selectsubeventdescription: {
    id: "registration.import.selectsubevent.description",
    defaultMessage: "select form from existing subevent as a template"
  },

  targets: {
    id: "registration.import.targets",
    defaultMessage: 'targets'
  },
  targetsdescription: {
    id: "registration.import.targets.description",
    defaultMessage: 'select what parts you want to overwrite'
  },
  importbutton: {
    id: "registration.import.import.button",
    defaultMessage: 'import'
  },
  targetbasic: {
    id: "registration.import.target.basic",
    defaultMessage: 'basicinformation'
  },
  targetstyling: {
    id: "registration.import.target.styles",
    defaultMessage: "styling"
  },
  targetquestions: {
    id: "registration.import.target.questions",
    defaultMessage: "questions"
  }
}






