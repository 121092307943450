import { InvoiceOperator } from '../reducers/invoiceReducer';

export type Maptype = 'Mappable' | 'Date';
export type Inheritance = 'Hardcoded' | 'Inherited';
export type InputDetails = {
  name: string;
  id: string;
  type: Maptype;
  mapping: Inheritance;
  rowData: boolean;
  extract?: RegExp;
  required: boolean;
  validation?: 'Number' | 'Vatnumber';
  productCode?: true;
};

export type DestinationMaps = {
  [K in InvoiceOperator]: {
    [key: string]: InputDetails;
  };
};

export const destinationMaps: DestinationMaps = {
  Fennoa: {
    name: {
      name: 'Customer name',
      id: 'name',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: true,
    },
    address: {
      name: 'Street address',
      id: 'address',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: true,
    },
    postalcode: {
      name: 'Postal code',
      id: 'postalcode',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: true,
    },
    city: {
      name: 'City',
      id: 'city',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: true,
    },
    business_id: {
      name: 'Business Identifier',
      id: 'business_id',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: false,
    },
    notes_internal: {
      name: 'Notes internal',
      id: 'notes_internal',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: false,
      required: false,
    },
    product_no: {
      name: 'Product code',
      id: 'product_no',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: true,
      required: false,
      productCode: true,
    },
    row_name: {
      name: 'Product name',
      id: 'name',
      type: 'Mappable',
      mapping: 'Hardcoded',
      rowData: true,
      required: false,
    },
    description: {
      name: 'Product description',
      id: 'description',
      type: 'Mappable',
      mapping: 'Hardcoded',
      rowData: true,
      required: false,
    },
    price: {
      name: 'Price',
      id: 'price',
      type: 'Mappable',
      mapping: 'Inherited',
      rowData: true,
      required: false,
      extract: /(\d*\,.\d+)$/, //eslint-disable-line no-useless-escape
    },
    quantity: {
      name: 'Quantity',
      id: 'quantity',
      type: 'Mappable',
      mapping: 'Hardcoded',
      rowData: true,
      required: false,
    },
    vatpercent: {
      name: 'Vat percent',
      type: 'Mappable',
      id: 'vatpercent',
      mapping: 'Hardcoded',
      rowData: true,
      required: false,
    },
    invoice_date: {
      name: 'Invoice date',
      id: 'invoice_date',
      type: 'Date',
      mapping: 'Hardcoded',
      required: true,
      rowData: false,
    },
    due_date: {
      name: 'Due date',
      id: 'due_date',
      type: 'Date',
      mapping: 'Hardcoded',
      required: true,
      rowData: false,
    },
  },
};
