import { InvoiceValues } from '../invoicing/components/InvoicePreviewItem';
import { FennoaProduct, SalesInvoice } from '../reducers/invoiceReducer';
import { RequestHeader } from '../types/types';

import * as config from './config';
import { axiosInstance } from './interceptors';
import storeService from './storage';

const newInvoiceSend = async (data: InvoiceValues[]) => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/fennoa/sales_api/add`;
  return await axiosInstance({ method: 'POST', url, data, headers });
};

const getAllFennoaInvoices = async (): Promise<{
  data: { data: SalesInvoice[]; status: string };
  status: number;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/fennoa/sales_api`;
  return await axiosInstance({ url, headers });
};

const getFennoaProducts = async (): Promise<{
  data: FennoaProduct[];
  status: number;
}> => {
  const Jwt = storeService.getString('jwt');
  const headers: RequestHeader = { Jwt };
  const url = `${config.baseUrl}/fennoa/products`;
  return await axiosInstance({ url, headers });
};

const invoiceService = {
  newInvoiceSend,
  getFennoaProducts,
  getAllFennoaInvoices,
};

export default invoiceService;
