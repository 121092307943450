import React from 'react';
import {FormattedMessage} from 'react-intl';
import {IntlMessageID} from '../..';
import {messages} from './translations'

interface ToggleFilterButtonProps {
  activeFilters: string[];
  buttonContainerStyle: string;
  buttonStyle: string;
  filterDivToggleId: string;
  amount?: number;
  toggle: (id: string) => void;
  translations: {[key: string]: IntlMessageID}
}

export const ToggleFilterButton: React.FC<ToggleFilterButtonProps> = ({
  toggle,
  activeFilters,
  amount,
  buttonContainerStyle,
  buttonStyle,
  filterDivToggleId,
  translations
}) => {

  const firstlabel = amount > 0 ? activeFilters[0].toLowerCase() : null
  const buttonlabel =
    amount === 1 && translations[firstlabel] ? (
      <FormattedMessage id={translations[firstlabel]} defaultMessage={activeFilters[0]} />
    ) : amount === 1 ? (
      <span>{activeFilters[0]}</span>
    ) : amount === 0 ? (
      <FormattedMessage
        {...messages.filterbutton}
      />
    ) : (
            <FormattedMessage
              {...messages.filterbuttonmulti}
              values={{amount: amount}}
            />
          );

  const buttonColor =
    amount > 0 ? `bg-blue-800 text-white` : `bg-blue-400 text-white`;

  return (
    <div className={`${buttonContainerStyle} ${buttonColor}`}>
      <button
        className={`${buttonStyle} truncate`}
        onClick={() => toggle(filterDivToggleId)}
      >
        {buttonlabel}
      </button>
    </div>
  );
};
