import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../actions/allActions';
import { FormColor } from '../../../components/form/FormColor';
import { FormInput } from '../../../components/form/FormInput';
import FormTextArea from '../../../components/form/FormTextArea';
import * as eState from '../../eventReducer';
import * as t from '../translations/stylingTranslations';

interface PageStylingProps {}

export const PageStylingForm: React.FC<PageStylingProps> = () => {
  const {
    pageDescription,
    pageHeader,
    pageRegistrationFullHeader,
    PageRegistrationFullText,
    PageThankYouHeader,
    PageRegistrationNotYetOpenText,
    pageThankYouText,
    PageRegistrationEndedHeader,
    PageRegistrationEndedText,
    PageRegistrationNotYetOpenHeader,
    backgroundColor,
  } = useSelector(eState.selectRegistrationPageStylingData);
  const dispatch = useDispatch();
  const rowDiv = 'flex justify divide-x';
  const inputStyle =
    'text-brand-dark_blue focus:outline-none focus:bg-blue-100 focus:shadow-inner border border-gray-300 rounded-lg py-2 px-4 m-4 appearance-none leading-normal cursor-pointer';

  const { formatMessage } = useIntl();
  const handleNestedItemChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | string,
    id: string
  ) => {
    const value = typeof event === 'string' ? event : event.target.value;
    const itemValues = id.split(':');
    dispatch(
      allActions.eventActions.changePageStyling(
        itemValues[1],
        value,
        itemValues[0]
      )
    );
  };

  return (
    <div className="flex flex-col justify-evenly w-full divide-y">
      <div className="flex justify divide-x">
        <FormInput
          value={pageHeader || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.pageheader })}
          handleInputChange={handleNestedItemChange}
          id="pageHeader:pageMessages"
          type="text"
        />
        <FormTextArea
          value={pageDescription || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.pagedescription })}
          handleInputChange={handleNestedItemChange}
          id="pageDescription:pageMessages"
        />
      </div>
      <div className={rowDiv}>
        <FormInput
          value={PageThankYouHeader || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.PageThankYouHeader })}
          handleInputChange={handleNestedItemChange}
          id="PageThankYouHeader:pageMessages"
          type="text"
        />
        <FormTextArea
          value={pageThankYouText || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.pagethankyoutext })}
          handleInputChange={handleNestedItemChange}
          id="pageThankYouText:pageMessages"
        />
      </div>
      <div className={rowDiv}>
        <FormInput
          value={pageRegistrationFullHeader || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.pageregistrationfullheader })}
          handleInputChange={handleNestedItemChange}
          id="pageRegistrationFullHeader:pageMessages"
          type="text"
        />
        <FormTextArea
          value={PageRegistrationFullText || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.PageRegistrationFullText })}
          handleInputChange={handleNestedItemChange}
          id="PageRegistrationFullText:pageMessages"
        />
      </div>
      <div className={rowDiv}>
        <FormInput
          value={PageRegistrationNotYetOpenHeader || ''}
          inputStyle={inputStyle}
          question={formatMessage({
            ...t.messages.PageRegistrationNotYetOpenHeader,
          })}
          handleInputChange={handleNestedItemChange}
          id="PageRegistrationNotYetOpenHeader:pageMessages"
          type="text"
        />
        <FormTextArea
          value={PageRegistrationNotYetOpenText || ''}
          inputStyle={inputStyle}
          question={formatMessage({
            ...t.messages.PageRegistrationNotYetOpenText,
          })}
          handleInputChange={handleNestedItemChange}
          id="PageRegistrationNotYetOpenText:pageMessages"
        />
      </div>
      <div className={rowDiv}>
        <FormInput
          value={PageRegistrationEndedHeader || ''}
          inputStyle={inputStyle}
          question={formatMessage({
            ...t.messages.PageRegistrationEndedHeader,
          })}
          handleInputChange={handleNestedItemChange}
          id="PageRegistrationEndedHeader:pageMessages"
          type="text"
        />
        <FormTextArea
          value={PageRegistrationEndedText || ''}
          inputStyle={inputStyle}
          question={formatMessage({ ...t.messages.PageRegistrationEndedText })}
          handleInputChange={handleNestedItemChange}
          id="PageRegistrationEndedText:pageMessages"
        />
      </div>
      <div className={rowDiv}>
        <FormColor
          value={backgroundColor || '#ff0000'}
          question={formatMessage({ ...t.messages.backgroundcolor })}
          handleInputChange={handleNestedItemChange}
          id="backgroundColor:pageStyling"
        />
      </div>
    </div>
  );
};

export default PageStylingForm;
