import { LoopReducer } from 'redux-loop';
import { ActionType, getType } from 'typesafe-actions';
import {
  getAllCerts,
  getCertNew,
  getLyytikey,
  newCertSend,
  resendCert,
} from '../certs/certReducer';
import {
  getEventAsync,
  createEventAsync,
  getDefaultForm,
  sendRegistrationAsync,
  postLinkForm,
  getLinkSuggestion,
  saveRegistrationQuestions,
  createSubEventAsync,
  getEventDetails,
  getParticipantDetails,
  getSubEventDetails,
  importRegistrationState,
  getNewParticipantForm,
} from '../events/eventReducer';
import {
  getAllEventsAsync,
  getAllLyytiEventsAsync,
  getAllLyytiParticipantsAsync,
} from '../events/listEvents/listEventsReducer';
import { LoadingState } from '../types/types';
import { AppState } from './combineReducer';
import { uploadFileToForm } from './fileUploadReducer';
import { getFormAsync } from './formReducer';
import {
  getFennoaInvoices,
  getFennoaProducts,
  invoiceSend,
} from './invoiceReducer';
import {
  cancelParticipantAsync,
  deleteParticipantAsync,
  getParticipants,
  moveParticipantAsync,
  newParticipantAsync,
  saveParticipantChanges,
} from './participantReducer';

const initialState: LoadingState = {
  createEvent: false,
  getForm: false,
  postLink: false,
  getDefaultForm: false,
  getEvent: false,
  getEvents: false,
  sendRegistration: false,
  saveRegistration: false,
  getLinkSuggestion: false,
  createCert: false,
  resendCert: false,
  getCertNew: false,
  getAllCerts: false,
  getLyytikey: false,
  fileUpload: false,
  createSubEvent: false,
  getEventDetails: false,
  getSubEventDetails: false,
  getParticipants: false,
  getParticipantDetails: false,
  updateParticipant: false,
  getAllLyytiEvents: false,
  getAllLyytiParticipants: false,
  importRegistrationstate: false,
  getNewParticipantForm: false,
  createNewParticipant: false,
  cancelParticipant: false,
  sendInvoice: false,
  getFennoaProducts: false,
  getFennoaInvoices: false,
};

type Action =
  | ActionType<typeof getFormAsync>
  | ActionType<typeof getEventAsync>
  | ActionType<typeof getAllEventsAsync>
  | ActionType<typeof getDefaultForm>
  | ActionType<typeof createEventAsync>
  | ActionType<typeof sendRegistrationAsync>
  | ActionType<typeof postLinkForm>
  | ActionType<typeof getLinkSuggestion>
  | ActionType<typeof uploadFileToForm>
  | ActionType<typeof saveRegistrationQuestions>
  | ActionType<typeof createSubEventAsync>
  | ActionType<typeof getEventDetails>
  | ActionType<typeof getParticipantDetails>
  | ActionType<typeof getParticipants>
  | ActionType<typeof saveParticipantChanges>
  | ActionType<typeof getSubEventDetails>
  | ActionType<typeof getAllLyytiParticipantsAsync>
  | ActionType<typeof getAllLyytiEventsAsync>
  | ActionType<typeof getCertNew>
  | ActionType<typeof newCertSend>
  | ActionType<typeof getLyytikey>
  | ActionType<typeof getAllCerts>
  | ActionType<typeof resendCert>
  | ActionType<typeof importRegistrationState>
  | ActionType<typeof getNewParticipantForm>
  | ActionType<typeof newParticipantAsync>
  | ActionType<typeof cancelParticipantAsync>
  | ActionType<typeof moveParticipantAsync>
  | ActionType<typeof invoiceSend>
  | ActionType<typeof getFennoaProducts>
  | ActionType<typeof getFennoaInvoices>
  | ActionType<typeof deleteParticipantAsync>;

export const loadingReducer: LoopReducer<LoadingState, Action> = (
  state: LoadingState = initialState,
  action: Action
): LoadingState => {
  switch (action.type) {
    case getType(getFormAsync.request):
      return { ...state, getForm: true };
    case getType(postLinkForm.request):
      return { ...state, postLink: true };
    case getType(getAllEventsAsync.request):
      return { ...state, getEvents: true };
    case getType(getEventAsync.request):
      return { ...state, getEvent: true };
    case getType(getDefaultForm.request):
      return { ...state, getDefaultForm: true };
    case getType(createEventAsync.request):
      return { ...state, createEvent: true };
    case getType(sendRegistrationAsync.request):
      return { ...state, sendRegistration: true };
    case getType(getEventAsync.success):
      return { ...state, getEvent: false };
    case getType(getEventAsync.failure):
      return { ...state, getEvent: false };
    case getType(getAllEventsAsync.success):
      return { ...state, getEvents: false };
    case getType(getAllEventsAsync.failure):
      return { ...state, getEvents: false };
    case getType(getFormAsync.success):
      return { ...state, getForm: false };
    case getType(getFormAsync.failure):
      return { ...state, getForm: false };
    case getType(getDefaultForm.success):
      return { ...state, getDefaultForm: false };
    case getType(getDefaultForm.failure):
      return { ...state, getDefaultForm: false };
    case getType(createEventAsync.success):
      return { ...state, createEvent: false };
    case getType(createEventAsync.failure):
      return { ...state, createEvent: false };
    case getType(sendRegistrationAsync.success):
      return { ...state, sendRegistration: false };
    case getType(sendRegistrationAsync.failure):
      return { ...state, sendRegistration: false };
    case getType(postLinkForm.success):
      return { ...state, postLink: false };
    case getType(postLinkForm.failure):
      return { ...state, postLink: false };
    case getType(getLinkSuggestion.request):
      return { ...state, getLinkSuggestion: true };
    case getType(getLinkSuggestion.success):
      return { ...state, getLinkSuggestion: false };
    case getType(getLinkSuggestion.failure):
      return { ...state, getLinkSuggestion: false };
    case getType(uploadFileToForm.request):
      return { ...state, fileUpload: true };
    case getType(uploadFileToForm.success):
      return { ...state, fileUpload: false };
    case getType(uploadFileToForm.failure):
      return { ...state, fileUpload: false };
    case getType(saveRegistrationQuestions.request):
      return { ...state, saveRegistration: true };
    case getType(saveRegistrationQuestions.success):
      return { ...state, saveRegistration: false };
    case getType(saveRegistrationQuestions.failure):
      return { ...state, saveRegistration: false };
    case getType(createSubEventAsync.request):
      return { ...state, createSubEvent: true };
    case getType(createSubEventAsync.success):
      return { ...state, createSubEvent: false };
    case getType(createSubEventAsync.failure):
      return { ...state, createSubEvent: false };
    case getType(getEventDetails.request):
      return { ...state, getEventDetails: true };
    case getType(getEventDetails.success):
      return { ...state, getEventDetails: false };
    case getType(getEventDetails.failure):
      return { ...state, getEventDetails: false };
    case getType(getParticipantDetails.request):
      return { ...state, getParticipantDetails: true };
    case getType(getParticipantDetails.success):
      return { ...state, getParticipantDetails: false };
    case getType(getParticipantDetails.failure):
      return { ...state, getParticipantDetails: false };
    case getType(getSubEventDetails.request):
      return { ...state, getSubEventDetails: true };
    case getType(getSubEventDetails.success):
      return { ...state, getSubEventDetails: false };
    case getType(getSubEventDetails.failure):
      return { ...state, getSubEventDetails: false };
    case getType(getAllLyytiEventsAsync.request):
      return { ...state, getAllLyytiEvents: true };
    case getType(getAllLyytiEventsAsync.success):
      return { ...state, getAllLyytiEvents: false };
    case getType(getAllLyytiEventsAsync.failure):
      return { ...state, getAllLyytiEvents: false };
    case getType(getAllLyytiParticipantsAsync.request):
      return { ...state, getAllLyytiParticipants: true };
    case getType(getAllLyytiParticipantsAsync.success):
      return { ...state, getAllLyytiParticipants: false };
    case getType(getAllLyytiParticipantsAsync.failure):
      return { ...state, getAllLyytiParticipants: false };
    case getType(getParticipants.request):
      return { ...state, getParticipants: true };
    case getType(getParticipants.success):
      return { ...state, getParticipants: false };
    case getType(getParticipants.failure):
      return { ...state, getParticipants: false };
    case getType(saveParticipantChanges.request):
      return { ...state, updateParticipant: true };
    case getType(saveParticipantChanges.success):
      return { ...state, updateParticipant: false };
    case getType(saveParticipantChanges.failure):
      return { ...state, updateParticipant: false };
    case getType(getCertNew.request):
      return { ...state, getCertNew: true };
    case getType(getCertNew.success):
      return { ...state, getCertNew: false };
    case getType(getCertNew.failure):
      return { ...state, getCertNew: false };
    case getType(newCertSend.request):
      return { ...state, createCert: true };
    case getType(newCertSend.success):
      return { ...state, createCert: false };
    case getType(newCertSend.failure):
      return { ...state, createCert: false };
    case getType(resendCert.request):
      return { ...state, resendCert: true };
    case getType(resendCert.success):
      return { ...state, resendCert: false };
    case getType(resendCert.failure):
      return { ...state, resendCert: false };
    case getType(getAllCerts.request):
      return { ...state, getAllCerts: true };
    case getType(getAllCerts.success):
      return { ...state, getAllCerts: false };
    case getType(getAllCerts.failure):
      return { ...state, getAllCerts: false };
    case getType(getLyytikey.request):
      return { ...state, getLyytikey: true };
    case getType(getLyytikey.success):
      return { ...state, getLyytikey: false };
    case getType(getLyytikey.failure):
      return { ...state, getLyytikey: false };
    case getType(importRegistrationState.request):
      return { ...state, importRegistrationstate: true };
    case getType(importRegistrationState.success):
      return { ...state, importRegistrationstate: false };
    case getType(importRegistrationState.failure):
      return { ...state, importRegistrationstate: false };
    case getType(getNewParticipantForm.request):
      return { ...state, getNewParticipantForm: true };
    case getType(getNewParticipantForm.success):
      return { ...state, getNewParticipantForm: false };
    case getType(getNewParticipantForm.failure):
      return { ...state, getNewParticipantForm: false };
    case getType(newParticipantAsync.request):
      return { ...state, createNewParticipant: true };
    case getType(newParticipantAsync.success):
      return { ...state, createNewParticipant: false };
    case getType(newParticipantAsync.failure):
      return { ...state, createNewParticipant: false };
    case getType(cancelParticipantAsync.request):
      return { ...state, cancelParticipant: true };
    case getType(cancelParticipantAsync.success):
      return { ...state, cancelParticipant: false };
    case getType(cancelParticipantAsync.failure):
      return { ...state, cancelParticipant: false };
    case getType(deleteParticipantAsync.request):
      return { ...state, cancelParticipant: true };
    case getType(deleteParticipantAsync.success):
      return { ...state, cancelParticipant: false };
    case getType(deleteParticipantAsync.failure):
      return { ...state, cancelParticipant: false };
    case getType(moveParticipantAsync.request):
      return { ...state, updateParticipant: true };
    case getType(moveParticipantAsync.success):
      return { ...state, updateParticipant: false };
    case getType(moveParticipantAsync.failure):
      return { ...state, updateParticipant: false };
    case getType(invoiceSend.request):
      return { ...state, sendInvoice: true };
    case getType(invoiceSend.success):
      return { ...state, sendInvoice: false };
    case getType(invoiceSend.failure):
      return { ...state, sendInvoice: false };
    case getType(getFennoaProducts.request):
      return { ...state, getFennoaProducts: true };
    case getType(getFennoaProducts.success):
      return { ...state, getFennoaProducts: false };
    case getType(getFennoaProducts.failure):
      return { ...state, getFennoaProducts: false };
    case getType(getFennoaInvoices.request):
      return { ...state, getFennoaInvoices: true };
    case getType(getFennoaInvoices.success):
      return { ...state, getFennoaInvoices: false };
    case getType(getFennoaInvoices.failure):
      return { ...state, getFennoaInvoices: false };
    default:
      return state;
  }
};

export const selectLoading = (state: AppState, key: keyof LoadingState) =>
  state.loadingState[key];

export const selectLoadingMultiple = (
  state: AppState,
  keys: Array<keyof LoadingState>
) => {
  const loading = keys.filter((key) => state.loadingState[key]).length > 0;
  return loading;
};
